<template>
  <div
    id="map"
    ref="map"
  >
    <div style="display: none;"><Popup ref="popupRef" /></div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import moment from 'moment';
import { TIANDITU_KEY, TENCENT_KEY } from '@/config';
import locateIcon from '@/assets/images/dot.png';
import locationIcon from '@/assets/images/circle.png';
import L from 'leaflet';
import _ from 'lodash';
import Bus from '@/bus/index';
import {
  factorMap, unitMap, geoserverUrl,
} from '@/config/MapConfigT4';
import TyphoonDisplay from './TyphoonDisplay';
import Popup from './components/Popup/index.vue';
import { cityLayerFeature } from '@/plugins/CityLayer';
import { getTyphoonList } from '@/api/typhoon';


export default {
  name: "TyphoonMap",
  components: {
    Popup
  },
  computed: {
    ...mapState('typhoon', ['typhoonObj','tableListData', 'isMorePlay', 'qbTime','index','timeLineLength',
      'ObserveTyphoonList', 'modelLegend', 'typhoonLineObj','observeTyphoonObj','modelLegend',
      'timeLineLengthObj', 'tcvitalName', 'timeLineLengthObj']),
    ...mapState('map', ['layerType', 'city']),
    ...mapGetters('typhoon', ['currentPoint']),
  },
  watch: {
     layerType(value) {
      this.clearLayer('toggleMap');
      if (value === 'graph') {
        this.renderGeoLayer();
      } else {
        this.renderTianDiMapLayer();
      }
    },
    // 监听城市图层控制标识变化
    city(value) {
      if (value === 'none') {
        this.clearLayer('city');
      } else {
        // 判断字体
        this.clearLayer('city');
        this.cityLayer = this.setCityLayer();
        this.map.addLayer(this.cityLayer);
      }
    },
  },
  data() {
    return {
      // 地图实例
      map: null,
      // 弹出框实例
      popup: null,
      // 当前可视区域的范围
      bounds: null,
      // 辅助播放，记录当前时间
      time: Date.now(),
      // 延时器id
      timer: 0,
      // 当需要重新渲染风场时，是否更新的标志
      update: true,
      // 当需要重新渲染海平面气压图层时，是否更新的标志
      slpUpdate: true,

      show: true,
      // 当前位置的维度
      currentLatitude: 35,

      // 当前位置的经度
      currentLogitude: 118,

      // 记录当前位置
      locationMarker: null,

      // 台风对象
      typhoon: null,

      typhoonData: [],

      visible2: false,
      // Popup 组件的 html 文本
      popupHTML: '',
      loadMapLayerFactors: ['wgrd10m', 'w100m', 't2mz', 'rh2m', 'tmp2m'],
    };
  },
  created() {
    Bus.$on('zoomIn', this.zoomIn);
    Bus.$on('zoomOut', this.zoomOut);
    Bus.$on('renderTyphoon', this.renderTyphoon);
    Bus.$on('pauseTyphoon', this.pauseTyphoon);
    Bus.$on('overTyphoon', this.overTyphoon);
    Bus.$on('continuePlay', this.continuePlay);
    Bus.$on('deleteTyphoon', this.deleteTyphoon);
    Bus.$on('locationToTyphoon', this.locationToTyphoon);
    Bus.$on('pointClick', this.pointClick);
    Bus.$on('timeLinePointClick', this.timeLinePointClick);
    Bus.$on('renderObserveTyphoon', this.renderObserveTyphoon);
    Bus.$on('closePop', this.closePop);
  },
  beforeDestroy() {
    Bus.$off('zoomIn', this.zoomIn);
    Bus.$off('zoomOut', this.zoomOut);
    Bus.$off('renderTyphoon', this.renderTyphoon);
    Bus.$off('pauseTyphoon', this.pauseTyphoon);
    Bus.$off('overTyphoon', this.overTyphoon);
    Bus.$off('continuePlay', this.continuePlay);
    Bus.$off('deleteTyphoon', this.deleteTyphoon);
    Bus.$off('locationToTyphoon', this.locationToTyphoon);
    Bus.$off('pointClick', this.pointClick);
    Bus.$off('timeLinePointClick', this.timeLinePointClick);
    Bus.$off('renderObserveTyphoon', this.renderObserveTyphoon);
    Bus.$off('closePop', this.closePop);
  },
  mounted() {
    this.initMap();
    // 定位到用户当前位置
    // this.map.locate();
    this.popupHTML = this.$refs.popupRef.$el.outerHTML;
    this.initTyphoonLine();

    window.changeAppBaseLayer = (e) => {
      this.changeBaseLayer(e);
    }

    window.timeLineDragEnd = (e) => {
      const item = JSON.parse(JSON.stringify(e));
      item.popClose = true;
      this.timeLinePointClick(item);
    }

    window.playTyphoon = () => {
      this.play();
    }

    window.replayTyphoon = () => {
      this.continuePlay();
    }

    window.pauseTyphoon = () => {
      this.pause();
    }

    window.toMapLocation = (e) => {
      this.toLocationPoint(e);
    }
  },

  methods: {
    ...mapMutations('typhoon', ['setTyphoonListData','setTyphoonLayerData', 'setCurrentTyphoonId',
      'setStatus', 'setIsPause', 'setTyphoonObj','setIndex', 'setMoreStatus', 'setIsMorePause',
      'setObserveTyphoonList', 'setObserveTyphoonObj','setTyphoonLineObj', 'setTimeLineDate',
      'setTimeLineLength', 'setTimeLineIndex','setTcvitalName']),
    /**
     * @description 初始化地图
     * @returns {void}
     * @author yujie
     */
    initMap() {
      this.map = L.map('map', {
        tapHold: true,
        tapTolerance: 3,
        crs: L.CRS.EPSG4326, // 坐标系--大地坐标系
        center: [28, 146], // 坐标中心
        zoomAnimationThreshold: 3,
        // zoomSnap: 0,
        fadeAnimation: !1,
        zoom: 4, // 初始缩放倍数
        minZoom: 2,
        maxZoom: 9,
        maxBounds: [
          [-90, -Infinity],
          [90, Infinity]
          // [-90, -7200],
          // [90, 7200]
        ],
        bounceAtZoomLimits: false,
        detectRetina: true,
        worldCopyJump: !1,
        keyboard: !1,
        // maxBounds: this.t3 ? [[this.region.latMin, this.region.lonMin], [this.region.latMax, this.region.lonMax]] : [[-90, -Infinity], [90, Infinity]], // 区域限制范围
        maxBoundsViscosity: 1,
        attributionControl: false, // 是否去除右下角标志
        zoomControl: false, // 是否显示地图缩放图例
      });

      // 创建不同的窗口(由于zIndex无效)
      this.map.createPane('borderLayerPane');
      this.map.createPane('cityLayerPane');
      this.map.createPane('mapLayerPane');

      // 设置窗口对应层级
      this.map.getPane('cityLayerPane').style.zIndex = 100;
      this.map.getPane('borderLayerPane').style.zIndex = 98;
      this.map.getPane('mapLayerPane').style.zIndex = 30;
      this.setCurrentBounds();
      this.addLayer();
      // this.renderCordon();
      // this.addEvent();

      // this.convertMapByZoom();
    },

    async initTyphoonLine() {
      if (this.qbTime === '') {
        // 中科天机
        const typhoonData = await getTyphoonList();
        this.setTyphoonListData(typhoonData);
        this.qbTimeSend(typhoonData);
      } else {
        this.setTcvitalName(null);
        Bus.$emit('initTyphoon', this.qbTime);
      }
    },

    qbTimeSend(typhoonData) {
      const qbTimeData = [];
      if (typhoonData && typhoonData.length > 0) {
        typhoonData.forEach(t => {
          qbTimeData.push(moment(t.baseTime).format('YYYY-MM-DD HH时'))
          // qbTimeData.push(t.baseTime);
        })
      }
      // console.log('qbTimeData==>',JSON.stringify(qbTimeData))
      typhoonQbTime.postMessage(JSON.stringify(qbTimeData))
    },

    /**
     * @description 地图缩放等级放大一级
     * @returns {void}
     * @author yujie
     */
    zoomIn() {
      this.map.zoomIn();
    },

    /**
     * @description 地图缩放等级缩小一级
     * @returns {void}
     * @author yujie
     */
    zoomOut() {
      this.map.zoomOut();
    },

    /**
     * @description 手动定位到用户当前所在位置
     * @returns {void}
     * @author yunpengliu
     */
    manualLocation() {
      this.map.panTo([this.currentLatitude, this.currentLogitude], this.map.getZoom());
    },

    changeMapLayer(value) {
      this.clearLayer('toggleMap');
      if (value === 'graph') {
        this.renderGeoLayer();
      } else {
        this.renderTianDiMapLayer();
      }
    },

    renderCordon() {
      //24小时警戒线
      const poline24 = [[34, 127], [22, 127], [18, 119], [11, 119], [4.5, 113], [0, 105]];

      const cordon24Layer = L.polyline(
        [poline24], {color: '#FF0000', weight: '1'},
      ).addTo(this.map);

      const content = "<div style='color:#FF0000;font-weight: bold'>24小时警戒线</div>";

      let myIcon = L.divIcon({
        html: content,
        className: 'my-div-icon',
        iconSize: 10,
      });

      //中心点位
      L.marker([31, 127.8], {icon: myIcon}).addTo(this.map);


      //48小时警戒线
      const poline48 = [[34, 132], [15, 132], [0, 120], [0, 105]];
      const jj48Layer = L.polyline(
        [poline48], {color: '#FFA500', weight: '1', dashArray: [5, 6]}
      ).addTo(this.map);

      const content1 = "<div style='color:#FFA500;font-weight: bold'>48小时警戒线</div>";

      myIcon = L.divIcon({
        html: content1,
        className: 'my-div-icon',
        iconSize: 10,
      });

      //中心点位
      L.marker([28, 132.8], {icon: myIcon}).addTo(this.map);
    },

    /**
     * @description 设置当前可视区域的范围
     * @returns {void}
     * @author yujie
     */
    setCurrentBounds() {
      this.bounds = this.map.getBounds();
    },

    /**
     * 构建城市图层
     */
    setCityLayer(layerName) {
      const type = layerName === 'satellite';
      const cityLayer = cityLayerFeature('/cities/{z}/{x}/{y}.json', {
        zoomOffset: 1,
        updateInterval: 5000,
        selectColor: type,
      });

      return cityLayer;
    },

    /**
     * @description 添加图层
     * @returns {void}
     * @author yujie
     */
    addLayer() {
      this.borderLayer = this.setCountryBorder();
      this.changeMapLayer(this.layerType);
      if (this.city === 'normal') {
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }

      // 将图层添加到地图上
      this.map
        .addLayer(this.borderLayer);
      // .addLayer(this.mapLayer);
    },

    /**
     * 国界图层
     * @returns {*}
     */
    setCountryBorder() {
      const url = `${geoserverUrl}/line-map/{z}/{x}/{y}.pbf`;
      const borderLayer = L.vectorGrid.protobuf(url, {
        rendererFactory: L.canvas.tile,
        pane: 'borderLayerPane',
        vectorTileLayerStyles: {
          interactive: true,
          world_line(properties) {

            if (properties.contry_sea === 1) {
              return {
                opacity: 0.9,
                fillColor: '#333',
                fillOpacity: 1,
                fill: true,
                color: '#333',
                weight: 1.2,
              };
            }

            return {
              opacity: 0.9,
              fillColor: '#333',
              fillOpacity: 1,
              fill: true,
              color: '#333',
              weight: 0.3,
            };
          },
          world_line_prov(properties) {
            if (properties.country_level === 1) {
              return {
                color: '#1d1d1d',
                weight: 1,
              };
            }
            return {
              color: '#333',
              dashArray: '2, 4',
              weight: 1,
            };
          },
          world_line_city(properties) {
            // 地形图配色
            if (properties.country_level === 3) {
              return {
                color: '#999',
                dashArray: '2, 6',
                weight: 1,
              };
            }
            if (properties.country_level === 4) {
              return {
                color: '#ccc',
                dashArray: '2, 8',
                weight: 1,
              };
            }
            if (properties.country_level === 2) {
              return {
                color: '#333',
                dashArray: '2, 4',
                weight: 1.2,
              };
            }
            return {
              color: '#1d1d1d',
              weight: 1,
            };
          },
        },
      });
      return borderLayer;
    },

    changeBaseLayer(layerName) {
      this.clearLayer('toggleMap');
      if (layerName === 'satellite') {
        this.renderTianDiMapLayer();
      } else {
        this.renderGeoLayer();
      }
      // this.setCityColor(layerName);
      this.changeCityLayer(layerName);
    },

    changeCityLayer(layerName) {
      this.clearLayer('city');
      this.cityLayer = this.setCityLayer(layerName);
      this.map.addLayer(this.cityLayer);
    },

    /**
     * @description 移除地图图层
     * @returns {void}
     * @author yujie
     */
    clearLayer(type) {

      if (type === 'city') {
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
      }

      // change 地形图 还是天地图
      if (type === 'toggleMap') {
        if (this.mapLayer) this.map.removeLayer(this.mapLayer);
        this.mapLayer = null;
      }

      if (!type || type === 'isobar') {
        if (this.isobarLayer) this.map.removeLayer(this.isobarLayer);
        this.isobarLayer = null;
      }
    },

    renderGeoLayer() {
      // 地形图
      if (!this.mapLayer) {
        // 创建GeoServer图层
        this.mapLayer = L.tileLayer(`${geoserverUrl}/hillshade/{z}/{x}/{y}.png`, {
          zoomOffset: 1,
          pane: 'mapLayerPane',
          className:'hillshade-class-img-map',
          updateWhenIdle: true,
          updateWhenZooming: !1,
          keepBuffer: 1,
          detectRetina: !1,
          subdomains: "0123",
          opacity: 1,
        });
        this.map.addLayer(this.mapLayer);
      }
    },

    renderTianDiMapLayer() {
      // 影像图
      if (!this.mapLayer) {
        this.mapLayer = L.tileLayer(`${geoserverUrl}/satellite/{z}/{x}/{y}.png`, { // 链接要改对应的
          zoomOffset: 1,
          pane: 'mapLayerPane',
          updateWhenIdle: true,
          updateWhenZooming: !1,
          keepBuffer: 1,
          detectRetina: !1,
          subdomains: "0123",
          opacity: 1,
          className: 'satellite-class-img-map',
        });
        this.map.addLayer(this.mapLayer);
      }
    },


    closePop() {
      this.map.closePopup();
    },

    renderTyphoon(renderData) {
      if (Object.keys(renderData.center).length > 0) {
        // 移动到地图中心点
        this.map.setView([renderData.center.lat, renderData.center.lon]);
      }

      const data = renderData.data;
      if (data) {
        const typhoon = new TyphoonDisplay(this.map,renderData.key+data.id, renderData.key,this.popupHTML,data.max,
          this.modelLegend[renderData.key],true,data.nameCn,false);
        typhoon.replay(data);
        // this.setTyphoonLayerData(this.typhoon.myLayerGroupPoint);
        // this.setCurrentTyphoonId(v.id);
        this.setTyphoonLineObj({
          id: renderData.key+data.id,
          typhoon: typhoon,
        });
      }
    },

    pointClick(item) {
      this.setTimeLineDate(item.time);
      const currentPoint = this.currentPoint;
      if (currentPoint) {
        currentPoint.ispop = item.ispop;
        currentPoint.key = item.key;
        this.timeLinePointClick(currentPoint)
        // 时间轴到指定的位置
        timeLineToPosition.postMessage(JSON.stringify(currentPoint.index));
      } else {
        if (item.key === 'tcvital' || item.key === 'sd3ens') {
          // 如果观测的点击点不在时间轴上，其他观测有相同时间的，则共同弹出
          const checkObj = this.checkLineChecked(item.key);
          if (checkObj.windChecked) {
            this.findSameTimeByObserve(item.time);
          }
          // 清除掉预报的风圈，因为没有时间与之相对应
          if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
            Object.keys(this.typhoonLineObj).forEach(t => {
              this.typhoonLineObj[t].forecastDeleteWindCircle();
            });
          }
        }

        // 如果点击的是起始点，key为sd3ens，要删除其他风圈
        // console.log('item.key+++++++',item.key)
        // if (item.key === 'sd3ens') {
        //   if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
        //     Object.keys(this.typhoonLineObj).forEach(t => {
        //       this.typhoonLineObj[t].forecastDeleteWindCircle();
        //     });
        //   }
        // }
      }
    },

    timeLinePointClick(item) {
      if (item.popClose) {
        this.map.closePopup();
      }
      this.setTimeLineIndex(item.index);
      // 如果点击的点等于起报，并且key = sd3ens，则只弹出一个框即可
      const selectValue = this.qbTime.split('#');
      const qbTime = selectValue[0];
      if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
        Object.keys(this.typhoonLineObj).forEach(t => {
          // 判断是否展示风圈,如果当前所选时刻部分线路不存在当前时刻，则不展示风圈
          if (item.index  > this.timeLineLengthObj[t] - 1) {
            this.typhoonLineObj[t].deleteTyphoonIcon();
          }

          // 先判断风是否展示，如果风展示然后再判断是否展示风圈
          if (item.date === qbTime && item.key === 'sd3ens'){
            // this.setTimeLineLength(item.index);
            this.typhoonLineObj[t].pointClickCircle(item.index, false);
          } else {
            const checkObj = this.checkLineChecked(t)
            if (checkObj.lineChecked) {
              // this.setTimeLineLength(item.index);
              this.typhoonLineObj[t].pointClickCircle(item.index, checkObj.windChecked);
            }
          }

        });
      }
      this.findSameTimeByObserve(item.date);
    },

    checkLineChecked(t) {
      let windChecked = true;
      let lineChecked = true;
      for (let key of Object.keys(this.modelLegend)) {
        if (t.indexOf(key) > -1) {
          windChecked = this.modelLegend[key].windChecked;
          lineChecked = this.modelLegend[key].checked;
        }
      }
      return {lineChecked,windChecked};
    },

    findSameTimeByObserve(date) {
      // 判断实况的时间是否在当前时刻重叠
      // 判断风圈是否展示
      const checkObj = this.checkLineChecked('tcvital')
      if (checkObj.lineChecked) {
        for(let obj of this.ObserveTyphoonList){
          const data = obj.details;
          let isFind = false;
          if (data && data.length > 0) {
            for (let i = 0; i <= data.length -1; i++) {
              const detail = data[i];
              if (detail.forecastTimeString === date) {
                this.observeTyphoonObj['tcvital'+obj.id].pointClick(i,checkObj.windChecked);
                isFind = true;
                break;
              }
            }
          }
          // 如果没有找到对应的单位，则删除掉这条线的风圈和绘制的icon
          if (!isFind) {
            this.observeTyphoonObj['tcvital'+obj.id].deleteWindCircle();
            this.observeTyphoonObj['tcvital'+obj.id].deleteTyphoonIcon();
          }
        }
      }

    },

    locationToTyphoon(id) {
      this.typhoon = this.typhoonObj[id];
      this.map.fitBounds(this.typhoon.timeLinePointClickpolyline.getBounds());
    },

    replayTyphoon() {
      // 如果没有可执行的
      let runFlag = false;
      if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
        Object.keys(this.typhoonLineObj).forEach(v => {
          let checked = true;
          for (let key of Object.keys(this.modelLegend)) {
            if (v.indexOf(key) > -1) {
              checked = this.modelLegend[key].checked;
              break;
            }
          }
          if (checked) {
            // 播放只播放预报的
            this.typhoonLineObj[v].replayForecastLine(null,-1,true);
            runFlag = true;
          }
        })
      }
      if (!runFlag) {
        this.setStatus('pause');
        this.setIsPause(false);
      }
    },

    play() {
      // if (this.status === 'pause' && this.isPause) {
      //   this.setStatus('play');
      //   this.setIsPause(false);
      //   this.continuePlay();
      // } else {
      //   this.setStatus('play');
      //   this.setIsPause(false);
      //   this.replayTyphoon();
      // }
      this.replayTyphoon();
    },

    /**
     * @description 暂停
     * @returns {void}
     * @author yujie
     */
    pause() {
      this.setStatus('pause');
      this.setIsPause(true);
      this.pauseTyphoon();
    },

    continuePlay() {
      if(this.typhoonLineObj) {
        const typhoonObjKeys = Object.keys(this.typhoonLineObj);
        if (typhoonObjKeys.length > 0) {
          typhoonObjKeys.forEach(v => {
            const checkObj = this.checkLineChecked(v)
            if (checkObj.lineChecked) {
              // this.typhoonLineObj[v].pointClickCircle(item.index, checkObj.windChecked);
              this.typhoonLineObj[v].continueDrawLine(checkObj.windChecked);
            }
          });
        }
      }
    },

    pauseTyphoon() {
      if(this.typhoonLineObj) {
        const typhoonObjKeys = Object.keys(this.typhoonLineObj);
        if (typhoonObjKeys.length > 0) {
          typhoonObjKeys.forEach(v => {
            const checkObj = this.checkLineChecked(v)
            if (checkObj.lineChecked) {
              this.typhoonLineObj[v].pause();
            }
          });
        }
      }
    },

    overTyphoon(length) {
      // 点击时间轴的播放按钮
      if (this.timeLineLength === length) {
        // this.setStatus('pause');
        // this.setIsPause(false);
        // 通知App完成
        finishPlayTyphoon.postMessage('');
      }


      // if (this.isMorePlay) {
      //   // 点击table上面的播放按钮
      //   this.setMoreStatus('pause');
      //   this.setIsMorePause(false);
      // } else {
      //   // 点击时间轴的播放按钮
      //   this.setStatus('pause');
      //   this.setIsPause(false);
      // }
    },

    deleteTyphoon() {
      if (this.typhoon) {
        this.typhoon.deleteTyphoon();
      }
    },

    toLocationPoint(params) {
      const data = JSON.parse(JSON.stringify(params));
      const { lat, lng, to} = data;
      if (!lat || !lng) {
        return;
      }
      this.removeMarker();
      const dotIcon = L.icon({
        iconUrl: locateIcon,
        iconSize: [14, 14],
      });
      this.locationMarker = L.marker([lat, lng], { icon: dotIcon });
      this.locationMarker.addTo(this.map);
      if (to.toString() === '1') {
        this.map.panTo([lat, lng], this.map.getZoom());
      }
    },

    // 移除地图标记
    removeMarker() {
      this.locationMarker?.remove();
    },



    /**
     * @description 使用腾讯地图进行定位
     * @returns {void}
     * @author yunpengliu
     */
    tencentMapLocation(dotIcon) {
      this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
        key: TENCENT_KEY,
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          const locationMarker = L.marker([res.result.location.lat, res.result.location.lng], { icon: dotIcon });
          locationMarker.addTo(this.map);
          this.currentLatitude = res.result.location.lat;
          this.currentLogitude = res.result.location.lng;
        }
      });
    },

    // 处理地图popup的更多点击事件
    handlerOmap(e) {
      const { className } = e.target;
      // console.log(className)
      if (className.includes('el-icon-close')) {
        this.visible2 = true;
      }
    },

    renderObserveTyphoon(data) {
      console.log('observer');
      // const data = this.ObserveTyphoonList;
      console.log('data===>',data)
      if (data) {
        const typhoon = new TyphoonDisplay(this.map,'tcvital'+data.id,'tcvital',this.popupHTML,
          data.max,this.modelLegend['tcvital'],false,data.nameCn,false);
        typhoon.replay(data);
        // this.setTyphoonLayerData(this.typhoon.myLayerGroupPoint);
        // this.setCurrentTyphoonId(v.id);
        this.setObserveTyphoonObj({
          id: 'tcvital'+data.id,
          obj: typhoon,
        });
      }

    }
  },
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

#factor-tooltip {
  display: none;
  background: #c8c8c8;
  color: #333;
  opacity: 0.7;
  border-radius: 5px;
  font-family: "微软雅黑", sans-serif;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 20px;
}

::v-deep {
  .leaflet-left {
    bottom: 45px;

    .leaflet-control-scale {
      margin-left: 5px;
    }
  }

  .leaflet-right {
    .leaflet-control-scale {
      margin-right: 22px;
    }
  }

  .leaflet-popup-close-button {
    right: 12px !important;
    line-height: 38px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    color: white !important;
    padding: 0px !important;
    margin: 0px;
  }
  .leaflet-popup-content-wrapper {
    padding: 0px 0px 2px 0px;
    border-radius: 4px;
  }
  .leaflet-popup-content {
    padding: 0px;
    margin: 0px;
  }

  //.leaflet-popup-content-wrapper {
  //  position: absolute;
  //  left: 60%;
  //  top: -90px;
  //  margin-left: -1px;
  //  border-radius: 0 40px 40px 0;
  //  background-color: rgba($text-operate, 0.92);
  //  color: $text-menu;
  //
  //  .leaflet-popup-content {
  //    margin: 3px 10px;
  //
  //    .line {
  //      border-left: 2px solid rgba($text-operate, 1);
  //      height: 90px;
  //      position: absolute;
  //      left: 0;
  //      top: 0;
  //    }
  //
  //    .close {
  //      position: absolute;
  //      font-size: 20px;
  //      right: -20px;
  //      top: 0;
  //      color: $text-operate;
  //      cursor: pointer;
  //    }
  //
  //    .custom-popup-content {
  //      .custom-popup-text {
  //        display: flex;
  //        align-items: center;
  //        white-space: nowrap;
  //        justify-content: space-between;
  //
  //        .current-value {
  //          font-size: 14px;
  //        }
  //
  //        .more-factor-data {
  //          width: 30px;
  //          height: 30px;
  //          margin-left: 6px;
  //          text-align: center;
  //          line-height: 30px;
  //          border-radius: 50%;
  //          background-color: $theme-color;
  //          color: #fff;
  //          font-size: 16px;
  //          font-weight: 700;
  //        }
  //      }
  //    }
  //  }
  //}

  .custom-popup-sepcial > .leaflet-popup-content-wrapper {
    top: 64px;

    .leaflet-popup-content {
      .line {
        top: -64px;
      }
    }
  }

  //.leaflet-popup-tip-container {
  //  top: -2px;
  //
  //  .leaflet-popup-tip {
  //    border-radius: 50%;
  //    width: 12px;
  //    height: 12px;
  //    background: $theme-color;
  //    box-sizing: border-box;
  //    border: 2px solid $text-operate;
  //    margin: 0 auto;
  //  }
  //}

  .more-factor-data {
    color: #0078a8;
    cursor: pointer;
  }
}

.locatedIcon {
  margin-top: -10px !important;
}

</style>

</style>
