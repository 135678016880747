<template>
  <div class="basetime_select">
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { getTyphoonList, getTyphoonDetailList ,getTyphoonLatestList} from '@/api/typhoon';
import Bus from '@/bus/index';

export default {
  filters: {
    formatTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  computed: {
    ...mapState('typhoon', ['qbDataList','nameDataList','typhoonId', 'typhoonObj','qbTime',
      'forecastModel','forecastTyphoonObj','typhoonListData','typhoonLineObj', 'modelLegend',
      'tcvitalName','observeTyphoonObj', 'ObserveTyphoonList']),
    qbDataInfoList() {
      return this.qbDataList;
    },

    qbDate: {
      get() {
        return this.qbTime;
      },
      set(value) {
        this.setQbTimeInfo(value);
      },
    },
  },

  data(){
    return {
      typhoonNameList: [],
      value: '',
      typhoonName: '',
      qbObserveObj: {}
    }
  },

  watch: {
    baseTime(value) {
      this.setCurrentBaseTime(value[0]);
    },
    qbTime(value) {
      this.selectTyphoon(value);
    }
  },
  mounted() {
    window.changeQbTime = (e) => {
      this.changeQbTime(e);
    }
  },
  methods: {
    ...mapMutations('typhoon', ['setTimeLineData', 'setTimeSet', 'setCurrentDateTime',
      'setTimeLineLength', 'setTyphoonObj', 'setModelLegend', 'setStatus', 'setIndex', 'setTimeLineLength',
      'setTableListData', 'setIsMorePlay','setIsMorePause', 'setIsPause', 'setQbTime','setInitTyphoonLineObj',
      'setBeginDateTime', 'setForecastTyphoonObj', 'setInitForecastTyphoonObj','setForecastTyphoonListData',
      'setInitModeLegend', 'setTimeLineLengthObj','setTimeLineMaxLength', 'setTimeLineDate','setModelLegendAll',
      'setTimeLineDate','setTcvitalName','setObserveTyphoonList','setInitObserveTyphoonLineObj',
      'setInitObserveTyphoonList', 'setQbDataList']),
    changeQbTime(qbTime) {
      let selectValue = '';
      for (let qb of this.qbDataList) {
        if (qb.value.indexOf(qbTime) > -1) {
          selectValue = qb.value;
          break;
        }
      }
      if (selectValue !== '') {
        this.selectTyphoon(selectValue);
      }
    },
    async selectTyphoon(value) {
      const selectValue = value.split('#');
      const qbTime = selectValue[0];
      const nameEns = selectValue[1];
      await this.initTime();
      await this.initObserve(nameEns,qbTime);
      await this.getForecastLineInfo(qbTime);
    },
    initTime() {
      this.initTyphoon();
      Bus.$emit('closePop');
      if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
        Object.keys(this.typhoonLineObj).forEach(t => {
          this.typhoonLineObj[t].deleteTyphoon();
          // delete this.typhoonLineObj[t];
        });
      }

      // 也需要删除实况的风圈和弹框
      if (this.observeTyphoonObj && Object.keys(this.observeTyphoonObj).length > 0) {
        Object.keys(this.observeTyphoonObj).forEach(t => {
          this.observeTyphoonObj[t].forecastDeleteWindCircle();
        });
      }

      this.setInitTyphoonLineObj();
    },
    async initObserve(nameEn, qbTime) {
      // 是否更新观测数据
      if (this.tcvitalName !== nameEn) {
        // 先删除原来的对象
        if (this.observeTyphoonObj && Object.keys(this.observeTyphoonObj).length > 0) {
          Object.keys(this.observeTyphoonObj).forEach(t => {
            this.observeTyphoonObj[t].deleteTyphoon();
            // delete this.observeTyphoonObj[t];
          });
        }
        this.setInitObserveTyphoonLineObj();
        this.setInitObserveTyphoonList([]);

        // 重新获取观测数据
        console.log('重新绘制观测数据');

        this.setTcvitalName(nameEn);
        // 查询观测,如果是多条，要循环
        const tcvitals = nameEn.split('&');
        console.log('tcvitals==>',tcvitals)
        if (tcvitals.length > 0) {
          for (let name of tcvitals) {
            const observeTyphoonData = await getTyphoonLatestList('name='+name);
            await this.initObserveTyphoonData(observeTyphoonData, qbTime);
          }
        }
        // 找到起报时间的点
      } else {
        this.findQbCurrentTime(qbTime);
      }
    },
    findQbCurrentTime(qbTime) {
      if (this.ObserveTyphoonList.length > 0) {
        this.qbObserveObj = {};
        this.ObserveTyphoonList.forEach(v => {
          const qbData =v.details.filter(v => v.forecastTimeString === qbTime);
          if (qbData && qbData.length > 0){
            this.qbObserveObj[v.nameEn] = qbData[0];
          }
        })
      }
    },
    initTyphoon() {
      this.setCurrentDateTime('');
      this.setTimeLineDate('');
      this.setTimeSet(new Set());
      this.setStatus('pause');
      this.setIsPause(false);
      this.setIndex(-1);
      this.setTableListData([]);
      this.setIsMorePause('pause');
      this.setIsMorePlay(false);
      this.setInitForecastTyphoonObj();
      this.setInitModeLegend();
      this.typhoonName = '';
    },
    setQbTimeInfo(value) {
      this.setQbTime(value);
    },
    async getForecastLineInfo(qbTime) {
      // 查询ifs
      const ifsTyphoonData = await getTyphoonList('model=ecens&specific='+qbTime);
      // this.setForecastTyphoonListData({data:ifsTyphoonData,key: 'ecens'});
      // 查询eps
      const epsTyphoonData = await getTyphoonList('model=ec52&specific='+qbTime);
      // this.setForecastTyphoonListData({data:epsTyphoonData,key: 'ec52'});
      // 查询gfs
      const gfsTyphoonData = await getTyphoonList('model=gfs&specific='+qbTime);
      // this.setForecastTyphoonListData({data:gfsTyphoonData,key: 'gfs'});

      const data = {
        ecens: ifsTyphoonData,
        ec52: epsTyphoonData,
        gfs: gfsTyphoonData,
        sd3ens: this.typhoonListData
      }
      const modelLegend = this.modelLegend;
      Object.keys(data).forEach(v => {
        if(data[v].length === 0) {
          modelLegend[v].checked =false;
          modelLegend[v].windChecked =false;
          modelLegend[v].disabled = true;
        } else {
          modelLegend[v].checked = true;
          modelLegend[v].windChecked = true;
          modelLegend[v].disabled = false;
        }
      })
      this.setModelLegendAll(modelLegend);
      this.setForecastTyphoonListData(data);
      await this.selectTyphoonAllList(qbTime, this.forecastTyphoonObj);
      modelLegendChange.postMessage(JSON.stringify(modelLegend));
    },
    async selectTyphoonAllList(value, forecastTyphoonObj) {
      // 遍历四个预报模式
      const timeSet = new Set();
      const timeLineList = {};
      // let lineLength = 0;
      let currentDateTime = '';
      let currentTime = '';
      const center = {};
      const lineLengthObj = {};
      const fObj = forecastTyphoonObj;
      // console.log('fObj=>',fObj);
      for(let k of Object.keys(fObj)) {
        const fData = fObj[k];
        if (fData) {
          const qbDataList = fData.qbData;
          if (qbDataList && qbDataList.length > 0) {
            const qbObj = qbDataList.filter(v => v.key === value);
            if (qbObj && qbObj.length > 0) {
              const ids = qbObj[0].ids;
              const data = await getTyphoonDetailList(ids);

              if (data && data.length > 0) {
                if (data[0].details.length > 0) {

                  currentDateTime = data[0].details[0].forecastTime;
                  currentTime = data[0].details[0].forecastTimeString;

                  if (k === 'sd3ens'){
                    // 获取第一个坐标点为地图中心点
                    center.lon = data[0].details[0].lon;
                    center.lat = data[0].details[0].lat
                  }
                }
                for (let v of data){
                  // pointLineLengthObj[k+v.id] = v.details.length;
                  if (v.details.length > 0) {
                    if (Object.keys(this.qbObserveObj).length > 0) {
                      if (this.qbObserveObj[v.nameEn]) {
                        v.details.unshift(this.qbObserveObj[v.nameEn]);
                      }
                    }
                    v.details.forEach((v,i) => {
                      const time = moment(v.forecastTime).format('YYYYMMDD');
                      const hour = moment(v.forecastTime).format('HH');
                      timeSet.add(time);
                      const timeData = timeLineList[time];
                      if (timeData) {
                        if (timeData.findIndex(v => v.key === hour) === -1) {
                          // lineLength ++;
                          timeLineList[time].push({
                            key: hour,
                            value: v.forecastTimeString,
                            index: i,
                            lineIndex: 0,
                          });
                        }
                      } else {
                        // lineLength ++;
                        timeLineList[time] = [{
                          key: hour,
                          value: v.forecastTimeString,
                          index: i,
                          lineIndex: 0,
                        }];
                      }
                    });
                    // 绘制每条线，打开不带动画效果
                    // console.log('this.qbObserveObj===>',this.qbObserveObj,'k==>',k,'v.nameEn==>',v.nameEn)
                    Bus.$emit('renderTyphoon', {data: v, key:k , center: center});

                  }
                  lineLengthObj[k+v.id] = v.details.length;
                }
                // this.setForecastTyphoonObj({key:k,obj:data});
              }
            }
          }
        }
      }

      this.setCurrentDateTime(currentDateTime);
      this.setBeginDateTime(currentDateTime);
      const timeLineData = [];
      Object.keys(timeLineList).forEach(v => {
        timeLineList[v].forEach((k,index) => {
          k.lineIndex = index;
          const d = {date: v};
          timeLineData.push({...k,...d});
        })
      });
      this.setTimeLineData(timeLineList);
      console.log('timeLineList==>',timeLineList)
      typhoonLayerAbstract.postMessage(JSON.stringify(timeLineData))
      this.setTimeLineDate(currentTime);
      this.setTimeSet(timeSet);
      // console.log('lineLength==>',lineLength,'lineLengthObj==',lineLengthObj);
      let maxLength = 0;
      if (Object.keys(lineLengthObj).length > 0) {
        maxLength = Math.max(...Object.values(lineLengthObj));
      }
      // this.setLinePointMaxLength(maxLength);
      this.setTimeLineLength(maxLength);
      this.setTimeLineMaxLength(maxLength);
      this.setTimeLineLengthObj(lineLengthObj);

      // if (Object.keys(c_point).length > 0) {
      //   Bus.$emit('pointClick',{index: c_point.index, time: c_point.value });
      // }
    },

    async initObserveTyphoonData(observeTyphoonData,qbTime) {
      if (observeTyphoonData && observeTyphoonData.length > 0) {
        // 获取观测的详细信息
        let ids = '';
        const details = observeTyphoonData[0].details;
        if (details.length > 0) {
          details.forEach(v => {
            ids = ids + v.id + ',';
          });
        }
        if (ids !== '') {
          const detailsInfo = await getTyphoonDetailList(ids);
          if (detailsInfo && detailsInfo.length > 0 && detailsInfo[0].details.length > 0) {
            // 对日期进行排序
            // detailsInfo[0].details.sort((a,b) => parseInt(a.forecastTimeString) - parseInt(b.forecastTimeString));
            // detailsInfo.forEach(info => {
            //   this.setObserveTyphoonList(info);
            // })
            // this.findQbCurrentTime(qbTime);
            // Bus.$emit('renderObserveTyphoon');

            for (let info of detailsInfo) {
              this.setObserveTyphoonList(info);
              Bus.$emit('renderObserveTyphoon',info);
            }
            this.findQbCurrentTime(qbTime);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.basetime_select {
  height: 30px;
  //padding: 0 5px 0 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .label {
    font-size: 12px;
    color: #fff;
    width: 30px;
    //margin-right: 10px;
    cursor: move;
  }

  .select_wrap {
    width: 164px;

    ::v-deep {
      .el-input__icon {
        line-height: 26px;
      }
      .el-input__inner {
        color: #ffffff;
        border-radius: 4px;
        border-color: #ffffff;
      }
    }
  }
}
</style>
