import Vue from 'vue';
import { VueJsonp } from 'vue-jsonp';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss';
import '@/styles/fonts.scss';

import '@/plugins/ContourLayer/ContourLayer'
import '@/plugins/FormatLayer'
// import '@/plugins/HeatmapLayer'
// import '@/plugins/WebGLTexture'
// import '@/plugins/HeatMapLayerInterploatByWebGL02';
import '@/plugins/HeatMapLayerByWebGL';

import VConsole from 'vconsole';
if (process.env.NODE_ENV === 'development'){
  new VConsole()
}
localStorage.setItem('vConsole_switch_y', '100');

Vue.use(VueJsonp);
Vue.config.productionTip = false;

// import axios from '@/plugins/axios'

// const app = createApp(App)

// svg-icon
// import { registerSvgIconComp } from '@/plugins/registerSvgIcon'
// registerSvgIconComp(app)

// import { jsonp } from 'vue-jsonp'
// app.config.globalProperties.$jsonp = jsonp

// import eventBus from './utils/eventBus'

// app.use(store).use(router).mount('#app')
// app.use(eventBus)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// import '@/views/index'

