const colorArray = {};

colorArray.color_arrays = {
  temp: {
    ident: "temp",
    steps: 2048,
    prepare: !0,
    default: [
      [-30, [58, 23, 62, 255]],
      [-20, [119, 48, 130, 255]],
      [-10, [40, 30, 97, 255]],
      [-5, [29, 84, 144, 255]],
      [0, [47, 150, 187, 255]],
      [5, [137, 206, 173, 255]],
      [10, [231, 248, 212, 255]],
      [15, [247, 218, 136, 255]],
      [20, [247, 146, 75, 255]],
      [25, [232, 76, 41, 255]],
      [30, [159, 42, 21, 255]],
      [35, [90, 20, 5, 255]],
      [40, [13, 3, 1, 255]]

      // [-30, [115, 70, 105, 255]],
      // [-20, [202, 172, 195, 255]],
      // [-10, [162, 70, 145, 255]],
      // [-5, [143, 89, 169, 255]],
      // [0, [157, 219, 217, 255]],
      // [5, [106, 191, 181, 255]],
      // [10, [100, 166, 189, 255]],
      // [15, [93, 133, 198, 255]],
      // [20, [68, 125, 99, 255]],
      // [25, [128, 147, 24, 255]],
      // [30, [243, 183, 4, 255]],
      // [35, [232, 83, 25, 255]],
      // [40, [71, 14, 0, 255]]
      //
      // [-30, [58, 23, 62, 255]],
      // [-20, [119, 48, 130, 255]],
      // [-10, [40, 30, 97, 255]],
      // [-5, [29, 84, 144, 255]],
      // [0, [76,160,198, 255]],
      // [5, [99, 201, 198, 255]],
      // [10, [140, 232, 145, 255]],
      // [15, [214, 243, 150, 255]],
      // [20, [249, 232, 150, 255]],
      // [25, [248, 196, 75, 255]],
      // [30, [245, 135, 39, 255]],
      // [35, [221, 38, 43, 255]],
      // [40, [96, 10, 48, 255]]


    ]
  },


  wind: {
    ident: "wind",
    steps: 2048,
    prepare: !0,
    default: [
      // [0,  [109, 158, 173, 255]],
      // [1,  [88, 152, 171,255]],
      // [2,  [74, 148, 170, 255]],
      // [5,  [74, 146, 148, 255]],
      // [8,  [77, 142, 124, 255]],
      // [10, [76, 164, 76, 255]],
      // [11, [103, 164, 54, 255]],
      // [12, [162, 135, 64, 255]],
      // [15, [162, 109, 92, 255]],
      // [17, [141, 63, 92, 255]],
      // [20, [151, 75, 145, 255]],
      // [25, [95, 100, 160, 255]],
      // [30, [50, 59, 162, 255]]




      [0,  [98, 113, 184, 255]],
      [1,  [61, 110, 163, 255]],
      [2,  [74, 148, 170, 255]],
      [5,  [74, 146, 148, 255]],
      [8,  [77, 142, 124, 255]],
      [10, [76, 164, 76, 255]],
      [11, [103, 164, 54, 255]],
      [12, [162, 135, 64, 255]],
      [15, [162, 109, 92, 255]],
      [17, [141, 63, 92, 255]],
      [20, [151, 75, 145, 255]],
      [25, [95, 100, 160, 255]],
      [30, [91, 136, 161, 255]]



    ]
  },


  rain: {
    ident: "rain",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122,255]],
      [0.1, [7, 146, 221, 255]],
      [0.5, [9, 181, 241, 255]],
      [1, [10, 206, 255, 255]],
      [2, [10, 250, 156, 255]],
      [3, [63, 224, 5, 255]],
      [4, [141, 255, 19, 255]],
      [5, [241, 255, 11, 255]],
      [6, [255, 200, 7, 255]],
      [10, [255, 61, 10, 255]],
      [15, [255, 10, 194, 255]],
      [20, [188, 80, 247, 255]],
      [30, [245, 192, 246, 255]]
    ]
  },


  snow: {
    ident: "snow",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122,255]],
      [0.05, [7, 146, 221, 255]],
      [0.1, [9, 181, 241, 255]],
      [0.2, [10, 206, 255, 255]],
      [0.5, [10, 250, 156, 255]],
      [0.7, [63, 224, 0, 255]],
      [1, [141, 255, 19, 255]],
      [1.5, [241, 255, 11, 255]],
      [2, [255, 200, 7, 255]],
      [3, [255, 61, 5, 255]],
      [4, [255, 10, 194, 255]],
      [5, [188, 80, 247, 255]],
      [27.5, [245, 192, 246, 255]]
    ]
  },

  ice: {
    ident: "ice",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122,255]],
      [0.05, [7, 146, 221, 255]],
      [0.1, [9, 181, 241, 255]],
      [0.2, [10, 206, 255, 255]],
      [0.3, [10, 250, 156, 255]],
      [0.4, [63, 224, 0, 255]],
      [0.5, [141, 255, 19, 255]],
      [0.6, [241, 255, 11, 255]],
      [0.7, [255, 200, 7, 255]],
      [0.8, [255, 61, 0, 255]],
      [1, [255, 0, 194, 255]],
      [1.5, [188, 80, 247, 255]],
      [2, [245, 192, 246, 255]]
    ]
  },

  graupel: {
    ident: "graupel",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122,255]],
      [0.05, [7, 146, 221, 255]],
      [0.1, [9, 181, 241, 255]],
      [0.2, [10, 206, 255, 255]],
      [0.4, [10, 250, 156, 255]],
      [0.6, [63, 224, 10, 255]],
      [0.8, [141, 255, 19, 255]],
      [1, [241, 255, 11, 255]],
      [1.2, [255, 200, 7, 255]],
      [1.6, [255, 61, 5, 255]],
      [2, [255, 10, 194, 255]],
      [3.5, [188, 80, 247, 255]],
      [4, [245, 192, 246, 255]]
    ]
  },



  pres: {
    ident: "pres",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [1, 22, 101, 0]],
      [0.1, [5, 33, 155, 255]],
      [0.2, [10, 206, 255, 255]],
      [0.5, [10, 250, 156, 255]],
      [0.7, [63, 224, 10, 255]],
      [1, [141, 255, 19, 255]],
      [2, [241, 255, 11, 255]],
      [5, [255, 200, 7, 255]],
      [10, [255, 61, 0, 255]],
      [20, [255, 5, 194, 255]],
      [30, [188, 80, 247, 255]],
      [50, [245, 192, 246, 255]]
    ]
  },

  //pre_total  pres_alls

  pre_total: {
    ident: "pre_total",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122, 255]],
      [0.25, [161, 241, 141, 255]],
      [1, [61, 186, 61, 255]],
      [10, [96, 184, 255, 255]],
      [25, [10, 15, 255, 255]],
      [50, [250, 10, 250, 255]],
      [100, [128, 15, 64, 255]],
      [250, [255, 170, 10, 255]],
      [400, [255, 102, 5, 255]],
      [600, [230, 15, 5, 255]],
      [800, [80, 45, 10, 255]]
    ]
  },


  prer: {
    ident: "prer",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122, 255]],
      [0.25, [161, 241, 141, 255]],
      [1, [61, 186, 61, 255]],
      [10, [96, 184, 255, 255]],
      [25, [10, 15, 255, 255]],
      [50, [250, 10, 250, 255]],
      [100, [128, 15, 64, 255]],
      [250, [255, 170, 10, 255]],
      [400, [255, 102, 5, 255]],
      [600, [230, 15, 5, 255]],
      [800, [80, 45, 10, 255]]
    ]
  },

  pres_alls: {
    ident: "pres_alls",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122, 255]],
      [0.25, [161, 241, 141, 255]],
      [1, [61, 186, 61, 255]],
      [10, [96, 184, 255, 255]],
      [25, [10, 15, 255, 255]],
      [50, [250, 10, 250, 255]],
      [100, [128, 15, 64, 255]],
      [250, [255, 170, 10, 255]],
      [400, [255, 102, 5, 255]],
      [600, [230, 15, 5, 255]],
      [800, [80, 45, 10, 255]]
    ]
  },



  pres_all: {
    ident: "pres_all",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122, 255]],
      [0.25, [161, 241, 141, 255]],
      [1, [61, 186, 61, 255]],
      [10, [96, 184, 255, 255]],
      [25, [10, 15, 255, 255]],
      [50, [250, 10, 250, 255]],
      [100, [128, 15, 64, 255]],
      [250, [255, 170, 10, 255]],
      [400, [255, 102, 5, 255]],
      [600, [230, 15, 5, 255]],
      [800, [80, 45, 10, 255]]
    ]
  },

  prei: {
    ident: "prei",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [1, 22, 101, 0]],
      [0.1, [5, 33, 155, 255]],
      [0.2, [10, 206, 255, 255]],
      [0.3, [10, 250, 156, 255]],
      [0.5, [63, 224, 10, 255]],
      [1, [141, 255, 19, 255]],
      [2, [241, 255, 11, 255]],
      [5, [255, 200, 7, 255]],
      [7.5, [255, 61, 0, 255]],
      [10, [255, 5, 194, 255]],
      [15, [188, 80, 247, 255]],
      [20, [245, 192, 246, 255]]
    ]
  },

  preg: {
    ident: "preg",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [1, 22, 101, 0]],
      [0.1, [5, 33, 155, 255]],
      [0.2, [10, 206, 255, 255]],
      [0.4, [10, 250, 156, 255]],
      [0.6, [63, 224, 0, 255]],
      [0.8, [141, 255, 19, 255]],
      [1, [241, 255, 11, 255]],
      [2, [255, 200, 7, 255]],
      [4.5, [255, 61, 0, 255]],
      [8, [255, 0, 194, 255]],
      [12, [188, 80, 247, 255]],
      [16, [245, 192, 246, 255]]
    ]
  },

  slp: [
    [191, 255, 63, 255],
    [255, 255, 63, 255],
    [255, 223, 63, 255],
    [255, 160, 63, 255],
    [255, 96, 63, 255],
    [255, 31, 63, 255],
    [255, 96, 191, 255],
    [255, 160, 255, 255],
    [255, 223, 255, 255],
  ],

  ri_min: {
    ident: 'ri_min',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [163, 1, 5, 255]],
      [0.5, [209, 10, 5, 255]],
      [1, [245, 27, 1, 255]],
      [1.5, [255, 88, 5, 255]],
      [2, [255, 175, 63, 255]],
      [2.5, [217, 222, 92, 255]],
      [3, [62, 191, 83, 255]],
      [4, [0, 165, 134, 255]],
      [5, [34, 152, 207, 255]],
      [6, [107, 199, 236, 255]],
      [7.5, [107, 199, 236, 0]],
      // [111, 111, 111, 255],
    ],

  },


  dust_conc: {
    ident: 'dust_conc',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [252, 247, 234, 255]],
      [300, [253, 240, 208, 255]],
      [500, [252, 227, 163, 255]],
      [800, [252, 215, 117, 255]],
      [1200, [240, 181, 94, 255]],
      [1600, [229, 148, 71, 255]],
      [2000, [218, 114, 48, 255]],
      [2500, [202, 107, 45, 255]],
      [3000, [185, 101, 41, 255]],
      [3500, [157, 98, 38, 255]],
      [4000, [142, 90, 36, 255]],
      [10000, [128, 81, 35, 255]],
      [14000, [113, 73, 33, 255]],
      // [111, 111, 111, 255],
    ],
  },



  dust_ddep: {
    ident: 'dust_ddep',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [133, 133, 133, 255]],
      [5, [178, 235, 229, 255]],
      [20, [129, 223, 190, 255]],
      [30, [205, 217, 149, 255]],
      [40, [246, 215, 128, 255]],
      [50, [246, 215, 128, 255]],
      [200, [205, 118, 62, 255]],
      [300, [178, 109, 55, 255]],
      [500, [151, 101, 48, 255]],
      [2000, [108, 74, 40, 255]],
      [5000, [52, 39, 20, 255]],
      [10000, [38, 28, 17, 255]],
      [20000, [24, 18, 14, 255]],
      // [111, 111, 111, 255],
    ],
  },

  dod: {
    ident: 'dod',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [252, 250, 236, 255]],
      [0.1, [255, 250, 209, 255]],
      [0.2, [255, 230, 112, 255]],
      [0.3, [255, 235, 69, 255]],
      [0.4, [255, 204, 51, 255]],
      [0.5, [255, 175, 51, 255]],
      [0.6, [255, 153, 51, 255]],
      [0.7, [255, 111, 51, 255]],
      [0.8, [255, 85, 0, 255]],
      [0.9, [230, 40, 30, 255]],
      [1, [200, 30, 20, 255]],
      // [111, 111, 111, 255],
    ],
  },


  dust_emis: {
    ident: 'dust_emis',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [133, 133, 133, 255]],
      [5, [178, 235, 229, 255]],
      [20, [129, 223, 190, 255]],
      [30, [167, 220, 159, 255]],
      [40, [205, 217, 149, 255]],
      [50, [246, 215, 128, 255]],
      [200, [205, 118, 62, 255]],
      [300, [178, 109, 55, 255]],
      [500, [151, 101, 48, 255]],
      [2000, [108, 74, 40, 255]],
      [5000, [52, 39, 20, 255]],
      [10000, [38, 28, 17, 255]],
      [20000, [24, 18, 14, 255]],
      // [111, 111, 111, 255],
    ],
  },



  pratesfc: {
    ident: "pratesfc",
    steps: 1024,
    prepare: !0,
    default: [
      [0, [122, 122, 122,255]],
      [0.25, [7, 146, 221, 255]],
      [0.65, [9, 181, 241, 255]],
      [1, [10, 206, 255, 255]],
      [2, [10, 250, 156, 255]],
      [3, [63, 224, 10, 255]],
      [5, [141, 255, 19, 255]],
      [6, [241, 255, 11, 255]],
      [8, [255, 200, 7, 255]],
      [10, [255, 61, 10, 255]],
      [15, [255, 15, 194, 255]],
      [20, [188, 80, 247, 255]],
      [30, [245, 192, 246, 255]]


      //     [0, [111, 111, 111, 255]],
      // [.6, [60, 116, 160, 255]],
      // [6, [59, 161, 161, 255]],
      // [8, [59, 161, 61, 255]],
      // [10, [130, 161, 59, 255]],
      // [15, [161, 161, 59, 255]],
      // [20, [161, 59, 59, 255]],
      // [31, [161, 59, 161, 255]],
    ]
  },

  max_reflectivity: {
    ident: 'max_reflectivity',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [130, 203, 245,255]],
      [10, [10, 160, 246, 255]],
      [15, [15, 198, 241, 255]],
      [20, [20, 226, 118, 255]],
      [25, [10, 180, 5, 255]],
      [30, [128, 200, 10, 255]],
      [35, [240, 220, 10, 255]],
      [40, [250, 170, 30, 255]],
      [45, [128, 72, 15, 255]],
      [50, [135, 10, 15, 255]],
      [55, [203, 10, 15, 255]],
      [60, [224, 15, 120, 255]],
      [65, [203, 15, 210, 255]],
      [70, [162, 72, 210, 255]],
    ],
  },

  base_reflectivity: {
    ident: 'base_reflectivity',
    steps: 1024,
    prepare: !0,
    default: [
      [0, [130, 203, 245,255]],
      [10, [10, 160, 246, 255]],
      [15, [15, 198, 241, 255]],
      [20, [20, 226, 118, 255]],
      [25, [10, 180, 5, 255]],
      [30, [128, 200, 10, 255]],
      [35, [240, 220, 10, 255]],
      [40, [250, 170, 30, 255]],
      [45, [128, 72, 15, 255]],
      [50, [135, 10, 15, 255]],
      [55, [203, 10, 15, 255]],
      [60, [224, 15, 120, 255]],
      [65, [203, 15, 210, 255]],
      [70, [162, 72, 210, 255]],
    ],
  },

  rh2m: {
    ident: "rh2m",
    steps: 2048,
    prepare: !0,
    default: [
      [0, [150, 50, 1, 255]],
      [10, [150, 50, 1, 255]],
      [20, [234, 113, 21, 255]],
      [30, [255, 154, 43, 255]],
      [40, [255, 195, 80, 255]],
      [50, [254, 228, 143, 255]],
      [60, [189, 222, 240, 255]],
      [70, [127, 186, 217, 255]],
      [80, [67, 148, 195, 255]],
      [90, [31, 98, 172, 255]],
      [100, [6, 48, 96, 255]],
    ]
  },
  dswrfsfc: {
    ident: "dswrfsfc",
    steps: 2048,
    prepare: !0,
    default: [
      [0, [122, 122, 122, 255]],
      [50, [254, 246, 206, 255]],
      [100, [254, 241, 183, 255]],
      [150, [252, 168, 53, 255]],
      [300, [247, 150, 47, 255]],
      [350, [248, 121, 43, 255]],
      [500, [244, 109, 40, 255]],
      [600, [229, 83, 35, 255]],
      // [700, [214, 69, 74, 255]],
      // [800, [215, 50, 45, 255]],
      // [900, [216, 43, 41, 255]],
      // [1000, [190, 24, 33, 255]],
      [700, [232, 76, 41, 255]],
      [800, [159, 42, 21, 255]],
      [900, [90, 20, 5, 255]],
      [1000, [13, 3, 1, 255]],
    ]
  }
};

export default colorArray;
