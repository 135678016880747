import _ from 'lodash';
import moment from 'moment';
import {getViewPortHeight} from '@/utils';

export default {
  namespaced: true,
  state: () => ({
    viewHeight: getViewPortHeight(),
    // 当前播放状态
    status: 'pause',
    // 多个线路播放状态
    moreStatus: 'pause',
    // 是否点击暂停
    isPause: false,
    //
    isMorePause: false,
    // 是否压缩 默认是
    hd: localStorage.getItem('compress-layer') || 'normal',
    cityLayer: localStorage.getItem('city-layer') || 'none',
    // 地形图还是影像图
    layerType: 'graph',
    // 当前起报时间
    currentBaseTime: '',
    // 当前日期时间
    currentDateTime: '',
    // 开始时间
    beginDateTime: '',
    // 当前台风编码
    currentTyphoonId: '',
    // 当前台风列表
    typhoonListData: [],
    // 当前起报时间列表
    qbDataList: [],
    // 台风名称列表
    nameDataList: {},
    //
    typhoonIds: '',
    //
    tableListData: [],
    // 日期列表
    timeLineData: {},
    // typhoonLayerData
    typhoonLayerData: null,
    // 当前图层时间列表
    timeList: [],
    // 日期去重
    timeSet: new Set(),
    // 当前时间表对应的索引
    index: 0,
    // 时间轴点击点的时间
    timeLineDate: '',
    // 时间轴总数
    timeLineLength: 0,
    // 当前时间轴点击的索引
    timeLineIndex: -1,
    // 每个预报线路的时间轴的长度
    timeLineLengthObj: {},
    // 时间轴最大值
    timeLineMaxLength: 0,
    // typhoonObj对象
    typhoonObj: {},
    // 点击line上点的时间
    pointClickTime: '',
    // 当前台风编码
    typhoonId: null,
    /**
     * @description 地图当前可视区域
     * @type {LatLngBounds}
     */
    bounds: null,
    // 风场数据
    windData: [],
    // 当前的模式
    model: 'early',
    // 城市图层是否展示
    city: localStorage.getItem('city-layer') || 'normal',
    // t3区域列表
    regionList: [],
    // t3当前区域
    region: {},
    // 高程
    elevation: '',
    // 是否是多个播放
    isMorePlay: false,
    // 起报时间
    qbTime: '',
    // 台风名称
    tcvitalName: '',
    // 观测的台风列表
    ObserveTyphoonList: [],
    // 观测的台风对象
    observeTyphoonObj: {},
    // IFS
    ifsTyphoonList: [],
    ifsTyphoonObj: {},
    // eps
    epsTyphoonList: [],
    epsTyphoonObj: {},
    // gfs
    gfsTyphoonList: [],
    gfsTyphoonObj: {},
    // 预报的所有数据
    forecastTyphoonObj: {},
    // 绘制的线对象
    typhoonLineObj: {},
    // 起报观测数据
    //
    // 模式的图例
    modelLegend: {
      'sd3ens': {
        color: '#FF0000',
        name: '中科天机',
        checked: true,
        windChecked: true,
        disabled: false,
        lineWeight: 3,
        dasharray: false
      },
      'tcvital': {
        color: '#000000',
        name: '观测',
        checked: true,
        windChecked: true,
        disabled: false,
        lineWeight: 4,
        dasharray: false
      },
      'ec52': {
        color: '#FF3EFF',
        name: 'IFS',
        checked: true,
        windChecked: true,
        disabled: false,
        lineWeight: 2,
        dasharray: false
      },
      'ecens': {
        color: '#FFA500',
        name: 'EC-EPS',
        checked: true,
        windChecked: true,
        disabled: false,
        lineWeight: 2,
        dasharray: false
      },
      'gfs': {
        color: '#800080',
        name: 'GFS',
        checked: true,
        windChecked: true,
        disabled: false,
        lineWeight: 2,
        dasharray: false
      }
    },
    forecastModel: ['sd3ens', 'ecens', 'ec52', 'gfs']
  }),
  getters: {
    // 起报时间列表
    baseTime(state) {
      return state.factorData.map((item) => item.baseTimeModel);
    },
    // 要素数据根据baseTime生成hashmap
    factorDataMap(state) {
      return state.factorData.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },

    windDataMap(state) {
      return state.windData.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },

    // 当前起报时间对应的详情数据
    currentData(state, getters) {
      return getters.factorDataMap[state.currentBaseTime] ?? {};
    },

    currentWindData(state, getters) {
      return getters.windDataMap[state.currentBaseTime] ?? {};
    },

    // 当前图层详情
    // TODO：根据图层优先级选择优先级最高的图层
    currentLayerDetails(state, getters) {
      // const { layers = [] } = getters.currentData;
      // const filter = layers.filter((item) => isContains(state.bounds, item));
      // return filter[0] ?? {};
      return getters.currentData;
    },

    currentWindyId(state, getters) {
      const {ingestions = []} = getters.currentLayerDetails;
      const filter = ingestions.filter((item) => item.forecastTime === getters.currentTime && item.elevation === state.elevation);
      return filter[0]?.id;
    },

    currentWindLayerDetails(state, getters) {
      // const { layers = [] } = getters.currentWindData;
      // const filter = layers.filter((item) => isContains(state.bounds, item));
      return getters.currentWindData;
    },

    dateSetInfo(state) {
      const dateSet = new Set();
      let dateList = [];
      let length = 0;
      state.tableListData.forEach(v => {
        if (v.details.length > length) {
          dateList = v.details;
          length = v.details.length;
        }
      });
      if (dateList.length > 0) {
        dateList.forEach(v => {
          dateSet.add(moment(v.forecastTime).format('YYYYMMDD'));
        });
      }
      return dateSet;
    },

    // 当前图层对应的时间列表
    timeList(state) {

      // let { forecast = [] } = getters.currentLayerDetails;
      // forecast = forecast.reduce((target, item) => {
      //   for (let i = 1; i <= 24; i++) {
      //     target.push({
      //       forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
      //       forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
      //       randomPath: item.randomPath,
      //       // factorType: factorType
      //     });
      //   }
      //   return target;
      // }, []);
      // return _.uniqBy(forecast, 'forecastTime');
    },

    windTimeList(state, getters) {
      let {forecast = []} = getters.currentWindLayerDetails;
      forecast = forecast.reduce((target, item) => {
        for (let i = 1; i <= 24; i++) {
          if (item.umin || item.umax || item.vmin || item.vmax) {
            target.push({
              forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
              forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
              randomPath: item.randomPath,
              umin: item.umin[i - 1] * 16,
              umax: item.umax[i - 1] * 16,
              vmin: item.vmin[i - 1] * 16,
              vmax: item.vmax[i - 1] * 16,
            });
          }
        }
        return target;
      }, []);
      return _.uniqBy(forecast, 'forecastTime');
    },

    windTimeMap(state, getters) {
      return getters.windTimeList.reduce((target, item) => {
        target[item.forecastTime] = item;
        return target;
      }, {});
    },
    // 当前图层对应时间详情
    current(state) {
      return state.currentDateTime;
    },

    windMeta(state, getters) {
      return getters.windTimeMap[getters.currentTime] ?? null;
    },
    // 当前选择时间
    currentTime(state) {
      return state.currentDateTime;
    },
    timeLineList(state, getters) {
      return state.timeLineData[moment(getters.currentTime).format('YYYYMMDD')];
    },
    currentPoint(state, getters) {
      const keys = Object.keys(state.timeLineData);
      let _index = 0;
      for (let i = 0; i <= keys.length - 1; i++) {
        const timeArry = state.timeLineData[keys[i]];
        if (timeArry) {
          for (let j = 0; j <= timeArry.length - 1; j++) {
            if (timeArry[j].value === state.timeLineDate) {
              state.currentDateTime = moment(timeArry[j].value, 'YYYYMMDDHH');
              return {
                index: timeArry[j].index,
                value: timeArry[j].value,
                itemIndex: _index,
                lineIndex: timeArry[j].lineIndex
              };
            }
            _index++;
          }
        }
      }
      return null;
    },

    elevationList(state, getters) {
      return getters.currentLayerDetails.elevation ?? [];
    },
  },
  mutations: {
    setViewHeight(state, value) {
      state.viewHeight = value;
    },
    // 设置当前要素
    setFactor(state, value) {
      state.factor = value || 'pratesfc';
    },

    setForecastTyphoonListData(state, value) {
      const forecastTyphoonObj = {};
      let qbTime = '';
      Object.keys(value).forEach(key => {
        const data = value[key];
        const qbData = [];
        if (data && data.length > 0) {
          data.forEach((t, index) => {
            let ids = '';
            if (index === 0) {
              qbTime = t.baseTimeString;
            }
            if (t.details.length > 0) {
              let name = '';
              for (let i =0; i <= t.details.length - 1; i++) {
                const detail = t.details[i];
                if (i === t.details.length - 1) {
                  name += detail.nameEn;
                  ids = ids + detail.id;
                }else {
                  name += detail.nameEn + '&';
                  ids = ids + detail.id + ',';
                }
              }
              qbData.push({
                key: t.baseTimeString,
                name: moment(t.baseTime).format('YYYY.MM.DD HH时'),
                ids,
                value: t.baseTimeString + '#' + name
              });
            }
          });
        }
        forecastTyphoonObj[key] = {
          qbData,
        }
      })

      state.forecastTyphoonObj = forecastTyphoonObj;

      if (state.qbDataList.length === 0) {
        state.qbDataList = forecastTyphoonObj['sd3ens'].qbData;
      }
    },
    // 设置当前要素数据列表
    setTyphoonListData(state, value) {
      console.log('value===>',value);
      if (state.qbTime === '') {
        if (value && value.length > 0) {
          state.qbTime = value[0].baseTimeString + '#'
          if (value[0].details.length > 0) {
            for (let i=0; i <= value[0].details.length - 1; i++) {
              const details = value[0].details[i];
              if (i === value[0].details.length - 1) {
                state.qbTime += details.nameEn;
              } else {
                state.qbTime += details.nameEn + '&';
              }
            }
          }
        }
      }
      state.typhoonListData = value;
      // if (state.typhoonListData && state.typhoonListData.length > 0){
      //   const qbData = [];
      //   const nameData = {};
      //   let qbTime = '';
      //   state.typhoonListData.forEach((t, index) => {
      //     let ids = '';
      //     const nameList = [];
      //     if (index === 0) {
      //       qbTime = t.baseTimeString;
      //     }
      //     if (t.details.length > 0) {
      //       t.details.forEach(v => {
      //         nameList.push({
      //           id: v.id, name: v.nameCn,
      //         });
      //         ids = ids + v.id + ',';
      //       });
      //       nameData[t.baseTimeString] = nameList;
      //       qbData.push({
      //         key: t.baseTimeString,
      //         name: moment(t.baseTime).format('YYYY.MM.DD HH时'),
      //         ids,
      //       });
      //     }
      //   });
      //   state.qbDataList = qbData;
      //   state.nameDataList = nameData;
      //   state.qbTime = qbTime;
      // }
    },
    // 设置当前播放状态
    setStatus(state, value) {
      state.status = value;
    },
    // 设置当前起报时间
    setCurrentBaseTime(state, value) {
      state.currentBaseTime = value;

      const elevationList = this.getters['map/elevationList'];

      if (!elevationList.length) {
        state.elevation = '';
      } else if (!elevationList.includes(state.elevation)) {
        state.elevation = elevationList[0];
      }
    },
    // 设置是否高精度
    setHD(state, value) {
      state.hd = value;
    },
    // 设置当前风场是否展示
    setWindy(state, value) {
      state.windy = value;
    },
    // 设置当前slp图层是否展示
    setIsobar(state, value) {
      state.isobar = value;
    },
    // 设置当前的地形图
    setMapLayer(state, value) {
      state.worldLayer = value;
      state.shadowLayer = value === 'normal' ? 'none' : 'normal';
    },
    // 设置当前的地形图
    setLayerType(state, value) {
      state.layerType = value;
    },
    setShadowLayer(state, value) {
      state.shadowLayer = value;
      state.worldLayer = value === 'normal' ? 'none' : 'normal';
    },
    // 设置当前起报时间对应的预报时间的索引
    setIndex(state, value) {
      state.index = value;
    },
    setTimeLineDate(state, value) {
      state.timeLineDate = value;
    },
    // 设置当前可视区域
    setBounds(state, value) {
      state.bounds = value;
    },
    // 设置风场数据
    setWindData(state, value) {
      state.windData = value.map((item) => {
        const str = item.mode === 'early' ? 'DA' : 'ND';
        item.baseTimeModel = `${item.baseTime} ${str}`;
        return item;
      });
    },
    // 设置城市图层是否展示值
    setCity(state, value) {
      state.city = value;
    },

    setRegionList(state, value) {
      state.regionList = value;
    },

    setRegion(state, value) {
      state.region = value;
    },

    setElevation(state, value) {
      state.elevation = value;
    },
    setTimeLineData(state, value) {
      state.timeLineData = value;
    },
    setTimeSet(state, value) {
      state.timeSet = value;
    },
    setCurrentDateTime(state, value) {
      state.currentDateTime = value;
    },
    setTyphoonLayerData(state, value) {
      state.typhoonLayerData = value;
    },
    setCurrentTyphoonId(state, value) {
      state.currentTyphoonId = value;
    },
    setIsPause(state, value) {
      state.isPause = value;
    },
    setIsMorePause(state, value) {
      state.isMorePause = value;
    },
    setTimeLineLength(state, value) {
      state.timeLineLength = value;
    },
    setTyphoonObj(state, value) {
      state.typhoonObj[value.id] = value.obj;
    },
    setTyphoonId(state, value) {
      state.typhoonId = value;
    },
    setTableListData(state, value) {
      state.tableListData = value;
    },
    setMoreStatus(state, value) {
      state.moreStatus = value;
    },
    setIsMorePlay(state, value) {
      state.isMorePlay = value;
    },
    setQbTime(state, value) {
      state.qbTime = value;
    },
    setBeginDateTime(state, value) {
      state.beginDateTime = value;
    },
    setObserveTyphoonList(state, value) {
      state.ObserveTyphoonList.push(value);
    },
    setInitObserveTyphoonList(state, value) {
      state.ObserveTyphoonList = value;
    },
    setObserveTyphoonObj(state, value) {
      state.observeTyphoonObj[value.id] = value.obj;
    },
    setModelLegend(state, value) {
      state.modelLegend[value.key].checked = value.checked;
    },
    setModelLegendWind(state, value) {
      state.modelLegend[value.key].windChecked = value.checked;
    },
    setModelLegendAll(state, value) {
      state.modelLegend = value;
    },
    setIfsTyphoonList(state, value) {
      state.ifsTyphoonList = value;
    },
    setIfsTyphoonObj(state, value) {
      state.ifsTyphoonObj[value.id] = value.obj;
    },
    setEpsTyphoonList(state, value) {
      state.epsTyphoonList = value;
    },
    setEpsTyphoonObj(state, value) {
      state.epsTyphoonObj[value.id] = value.obj;
    },
    setGfsTyphoonList(state, value) {
      state.gfsTyphoonList = value;
    },
    setGfsTyphoonObj(state, value) {
      state.gfsTyphoonObj[value.id] = value.obj;
    },
    setForecastTyphoonObj(state, value) {
      state.forecastTyphoonObj[value.key].fData = value.obj;
    },
    setInitForecastTyphoonObj(state) {
      state.forecastTyphoonObj = {};
    },
    setTyphoonLineObj(state, value) {
      if (!state.typhoonLineObj[value.id]) {
        state.typhoonLineObj[value.id] = value.typhoon;
      }
    },
    setInitTyphoonLineObj(state) {
      state.typhoonLineObj = {};
    },
    setInitObserveTyphoonLineObj(state) {
      state.observeTyphoonObj = {};
    },
    setInitModeLegend(state) {
      Object.keys(state.modelLegend).forEach(v => {
        state.modelLegend[v].checked = true;
        state.modelLegend[v].windChecked = true;
      });
    },
    setTimeLineLengthObj(state, value) {
      state.timeLineLengthObj = value;
    },
    setTimeLineMaxLength(state, value) {
      state.timeLineMaxLength = value;
    },
    setTimeLineIndex(state, value) {
      state.timeLineIndex = value;
    },
    setTcvitalName(state, value) {
      state.tcvitalName = value;
    },
    setLinePointMaxLength(state, value) {
      state.linePointMaxLength = value;
    }
  },
}
