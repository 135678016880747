import _ from 'lodash';
import moment from 'moment';
import { getViewPortHeight } from '@/utils';

function isMobileFn() {
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return !!mobile;
}

const mobile = isMobileFn();

export default {
  namespaced: true,
  state: () => ({
    viewHeight: getViewPortHeight(),
    // 当前要素
    factor: 'pratesfc',
    // 当前播放状态
    status: 'pause',
    // 是否压缩 默认是
    hd: 'none',
    // 当前风场是否展示
    windy: mobile ? 'none' : 'none',
    // 当前slp图层是否展示
    isobar: mobile ? 'none' : 'none',
    // 当前 天地图（world）图层是否展示、
    worldLayer: mobile ? 'normal' : 'none',
    // 当前 影像底图（shadow）图层是否展示、
    shadowLayer: mobile ? 'none' : 'normal',
    // 地形图还是影像图
    layerType: 'graph',
    // 当前起报时间
    currentBaseTime: '',
    // 当前要素对应的数据
    factorData: [],
    // 当前图层时间列表
    timeList: [],
    // 当前时间表对应的索引
    index: -1,
    /**
     * @description 地图当前可视区域
     * @type {LatLngBounds}
     */
    bounds: null,
    // 风场数据
    windData: [],
    // 当前的模式
    model: 'early',
    // 城市图层是否展示
    city: 'normal',
    // t3区域列表
    regionList: [],
    // t3当前区域
    region: {},
    // 高程
    elevation: '',
    // 是否播放或者拖拽
    drag: 'normal',
    //
    timeLineReStart: true,
    //
    moving: false,
    //
    heatmapLoaded: false,
    // 是否初次加载
    isCurrent: false,
  }),
  getters: {
    // 起报时间列表
    baseTime(state) {
      return state.factorData.map((item) => item.baseTimeModel);
    },
    // 要素数据根据baseTime生成hashmap
    factorDataMap(state) {
      return state.factorData.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },

    windDataMap(state) {
      return state.windData.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },

    // 当前起报时间对应的详情数据
    currentData(state, getters) {
      const data =  getters.factorDataMap[state.currentBaseTime] ?? {};
      console.log('state.factorData',data,'state.currentBaseTime',state.currentBaseTime)
      return data;
    },

    currentWindData(state, getters) {
      return getters.windDataMap[state.currentBaseTime] ?? {};
    },

    // 当前图层详情
    // TODO：根据图层优先级选择优先级最高的图层
    currentLayerDetails(state, getters) {
      // const { layers = [] } = getters.currentData;
      // const filter = layers.filter((item) => isContains(state.bounds, item));
      // return filter[0] ?? {};
      return getters.currentData;
    },

    currentWindyId(state, getters) {
      const { ingestions = [] } = getters.currentLayerDetails;
      const filter = ingestions.filter((item) => item.forecastTime === getters.currentTime && item.elevation === state.elevation);
      return filter[0]?.id;
    },

    currentWindLayerDetails(state, getters) {
      // const { layers = [] } = getters.currentWindData;
      // const filter = layers.filter((item) => isContains(state.bounds, item));
      return getters.currentWindData;
    },

    // 当前图层对应的时间列表
    timeList(state, getters) {
      let { forecast = [] } = getters.currentLayerDetails;
      forecast = forecast.reduce((target, item) => {
        for (let i = 1; i <= 24; i++) {
          target.push({
            forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
            forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
            randomPath: item.randomPath,
            // factorType: factorType
          });
        }
        return target;
      }, []);
      return _.uniqBy(forecast, 'forecastTime');
    },

    windTimeList(state, getters) {
      let { forecast = [] } = getters.currentWindLayerDetails;
      forecast = forecast.reduce((target, item) => {
          if (item.umax && item.umax.length > 0) {
            for (let i = 1; i <= 24; i++) {
              target.push({
                forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
                forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
                randomPath: item.randomPath,
                umin: item.umin[i - 1] * 16,
                umax: item.umax[i - 1] * 16,
                vmin: item.vmin[i - 1] * 16,
                vmax: item.vmax[i - 1] * 16,
              });
            }
          }
        return target;
      }, []);
      return _.uniqBy(forecast, 'forecastTime');
    },

    windTimeMap(state, getters) {
      return getters.windTimeList.reduce((target, item) => {
        target[item.forecastTime] = item;
        return target;
      }, {});
    },
    // 当前图层对应时间详情
    current(state, getters) {
      return getters.timeList[state.index] ?? {};
    },

    windMeta(state, getters) {
      return getters.windTimeMap[getters.currentTime] ?? null;
    },
    // 当前选择时间
    currentTime(state, getters) {
      return getters.current.forecastTime;
    },
    elevationList(state, getters) {
      return getters.currentLayerDetails.elevation ?? [];
    },
  },
  mutations: {
    setViewHeight(state, value) {
      state.viewHeight = value;
    },
    // 设置当前要素
    setFactor(state, value) {
      state.factor = value || 'pratesfc';
    },
    // 设置当前要素数据列表
    setFactorData(state, value) {
      state.factorData = value.map((item) => {
        // const str = item.mode === 'early' ? 'DA' : 'ND';
        // item.baseTimeModel = `${item.baseTime} ${str}`;
        item.baseTimeModel = `${item.baseTime} DA`;
        return item;
      });
      const elevationList = this.getters['map/elevationList'];

      if (!elevationList.length) {
        state.elevation = '';
      } else if (!elevationList.includes(state.elevation)) {
        state.elevation = elevationList[elevationList.length - 1];
      }
    },
    // 设置当前播放状态
    setStatus(state, value) {
      state.status = value;
    },
    // 设置当前起报时间
    setCurrentBaseTime(state, value) {
      state.currentBaseTime = value;

      const elevationList = this.getters['map/elevationList'];

      if (!elevationList.length) {
        state.elevation = '';
      } else if (!elevationList.includes(state.elevation)) {
        state.elevation = elevationList[0];
      }
    },
    // 设置是否高精度
    setHD(state, value) {
      state.hd = value;
    },
    // 设置当前风场是否展示
    setWindy(state, value) {
      state.windy = value;
    },
    // 设置当前slp图层是否展示
    setIsobar(state, value) {
      state.isobar = value;
    },
    // 设置当前的地形图
    setMapLayer(state, value) {
      state.worldLayer = value;
      state.shadowLayer = value === 'normal' ? 'none' : 'normal';
    },
    // 设置当前的地形图
    setLayerType(state, value) {
      state.layerType = value;
    },
    setShadowLayer(state, value) {
      state.shadowLayer = value;
      state.worldLayer = value === 'normal' ? 'none' : 'normal';
    },
    // 设置当前起报时间对应的预报时间的索引
    setIndex(state, value) {
      state.index = value;
    },
    // 设置当前可视区域
    setBounds(state, value) {
      state.bounds = value;
    },
    // 设置风场数据
    setWindData(state, value) {
      state.windData = value.map((item) => {
        const str = item.mode === 'early' ? 'DA' : 'ND';
        item.baseTimeModel = `${item.baseTime} ${str}`;
        return item;
      });
    },
    // 设置城市图层是否展示值
    setCity(state, value) {
      state.city = value;
    },

    setRegionList(state, value) {
      state.regionList = value;
    },

    setRegion(state, value) {
      state.region = value;
    },

    setElevation(state, value) {
      state.elevation = value;
    },
    // 设置拖拽状态
    setDrag(state, value) {
      state.drag = value;
    },
    setTimeLineRestart(state, value) {
      state.timeLineReStart = value;
    },
    setMoving(state, value) {
      state.moving = value;
    },
    setHeatMapLoaded(state, value) {
      state.heatmapLoaded = value;
    },
    setIsCurrent(state, value) {
      state.isCurrent = value;
    },
  },
};
