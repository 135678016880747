<template>
  <div class="popup">
    <div class="head">{{ Head.content }}</div>
    <div class="row" v-for="row in Table" :key="row.content">
      <span class="left">{{ row.name }}</span>
      <span class="right">{{ row.content }}</span>
    </div>
  </div>
</template>
<script>
import { Reflect } from './util';

export default {
  name: 'Popup',
  data() {
    return {
      Head: Reflect.Head,
      Table: Reflect.Table
    }
  }
}
</script>
<style lang="scss" scoped>
.popup {
  .head {
    background-color: rgb(0, 153, 203);
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .row {
    display: flex;
    font-size: 14px;
    padding: 2px 10px;
    margin: 6px 14px;
    .left {
      color: rgb(120, 120, 120);
      flex-shrink: 0;
    }
    .right {
      color: black;
      flex-shrink: 0;
      padding-left: 10px;
    }
    &:nth-child(n) {
      border-bottom: 1px solid rgb(200, 200, 200);
    }
    &:last-child {
      border: none;
    }
  }
}
</style>