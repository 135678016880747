export const metrics = [
  // {
  //   id: 'wgrd10m',
  //   factor: 'wind',
  //   icon: 'wind-field',
  //   text: '10米风场',
  // },

  {
    id: 'pratesfc',
    // factor: 'wind',
    icon: 'pratesfc',
    text: '总降水率',
  },
  {
    id: 'radar',
    icon: 'radar',
    private: 't2',
    text: '雷达',
    subId: ['max_reflectivity', 'base_reflectivity'],
    children: [
      {
        id: 'max_reflectivity',
        // factor: 'wind',
        icon: 'radar',
        text: '明日雷达',
        private: 't2',
      },

      {
        id: 'base_reflectivity',
        // factor: 'wind',
        icon: 'base_ref',
        text: '基本反射率',
        private: 't2',
      },
    ],
  },
  {
    id: 'windy',
    icon: 'wind-wheel',
    private: 't2',
    text: '风速',
    subId: ['wgrd10m', 'w100m'],
    children: [
      {
        id: 'wgrd10m',
        icon: 'wgrd10m',
        text: '10米风场',
      },
      {
        id: 'w100m',
        icon: 'wind-wheel',
        text: '100米风场',
      },
    ],
  },
  {
    id: 't2mz',
    factor: 'temp',
    icon: 'temperature',
    text: '温度',
    private: 't2',
  },
  {
    id: 'tmp2m',
    private: 't1',
    factor: 'temp',
    icon: 'temperature',
    text: '温度',
  },
  {
    id: 'ri_min',
    icon: 'airport',
    text: '晴空颠簸指数',
  },
  {
    id: 'rh2m',
    icon: 'humidity',
    text: '相对湿度',
    private: 't2',
  },

  {
    id: 'flow_water',
    text: '降水',
    icon: 'prerrt',
    subId: ['prerrt', 'pregrt', 'preirt', 'presrt'],
    children: [
      {
        id: 'prerrt',
        icon: 'prerrt',
        text: '降雨',
      },
      {
        id: 'pregrt',
        icon: 'pregrt',
        text: '霰降水',
      },
      {
        id: 'preirt',
        icon: 'preirt',
        text: '冰降水',
      },
      {
        id: 'presrt',
        icon: 'presrt',
        text: '降雪',
      },
    ],
  },
  {
    id: 'add_water',
    text: '累计降水',
    icon: 'rain',
    subId: ['prer', 'prei', 'pres', 'preg'],
    children: [
      {
        id: 'prer',
        icon: 'rain',
        text: '累计降雨',
      },
      {
        id: 'prei',
        icon: 'ice',
        text: '累计冰降水',
      },

      {
        id: 'pres',
        icon: 'snow',
        text: '累计降雪',
      },

      {
        id: 'preg',
        icon: 'graupel',
        text: '累计霰降水',
      },
    ],
  },
  // {
  //   id: 'pratesfc',
  //   icon: 'pratesfc',
  //   text: '总降水率',
  // },

  // {
  //   id: 'w200m',
  //   icon: '200pa',
  //   text: '200米风场',
  // },

  // {
  //   id: 'slp',
  //   icon: 'slp',
  //   text: '海平面气压',
  // },

  // {
  //   id: 'prert',
  //   icon: 'prert',
  //   text: '实时降水',
  // },

  // {
  //   id: 'preall',
  //   icon: 'preall',
  //   text: '累计降水',
  // },
];

export const t3Metrics = [
  {
    id: 'pratesfc',
    // factor: 'wind',
    icon: 'pratesfc',
    text: '总降水率',
  },
  {
    id: 'radar',
    text: '雷达',
    icon: 'radar',
    subId: ['max_reflectivity', 'base_reflectivity'],
    children: [
      {
        id: 'max_reflectivity',
        // factor: 'wind',
        icon: 'radar',
        text: '明日雷达',
        private: 't2',
      },

      {
        id: 'base_reflectivity',
        // factor: 'wind',
        icon: 'base_ref',
        text: '基本反射率',
        private: 't2',
      },
    ],
  },
  {
    id: 'windySpeed',
    text: '风速',
    icon: 'wind',
    subId: ['wind', 'wgrd10m', 'w100m'],
    children: [
      // {
      //   id: 'wind',
      //   icon: 'wind',
      //   text: '气压面风速',
      // },
      {
        id: 'wgrd10m',
        icon: 'wgrd10m',
        text: '10米风场',
      },

      {
        id: 'w100m',
        icon: 'wind-wheel',
        text: '100米风场',
      },
    ],
  },
  {
    id: 'tempt',
    icon: 'temp',
    text: '温度',
    subId: ['tmp', 't2mz', 'tmpsfc'],
    children: [
      // {
      //   id: 'tmp',
      //   icon: 'temp',
      //   text: '气压面温度',
      // },
      {
        id: 't2mz',
        icon: 'temperature',
        text: '2米温度',
      },
      {
        id: 'tmpsfc',
        icon: 'tmpsfc',
        text: '地表温度',
      },
    ],
  },
  {
    id: 'rainWater',
    icon: 'prerrt',
    text: '降水',
    subId: ['prerrt', 'pregrt', 'preirt', 'presrt'],
    children: [
      {
        id: 'prerrt',
        icon: 'prerrt',
        text: '降雨',
      },
      {
        id: 'pregrt',
        icon: 'pregrt',
        text: '霰降水',
      },
      {
        id: 'preirt',
        icon: 'preirt',
        text: '冰降水',
      },
      {
        id: 'presrt',
        icon: 'presrt',
        text: '降雪',
      },
    ],
  },
  {
    id: 'addRainWater',
    icon: 'rain',
    text: '累计降水',
    subId: ['prer', 'prei', 'pres', 'preg'],
    children: [
      {
        id: 'prer',
        icon: 'rain',
        text: '累计降雨',
      },
      {
        id: 'prei',
        icon: 'ice',
        text: '累计冰降水',
      },

      {
        id: 'pres',
        icon: 'snow',
        text: '累计降雪',
      },

      {
        id: 'preg',
        icon: 'graupel',
        text: '累计霰降水',
      },
    ],
  },

];

export const factorMap = {
  tmp: 'temp',
  wind: 'wind',
  t2mz: 'temp',
  tmp2m: 'tmp2m',
  tmpsfc: 'temp',
  max_reflectivity: 'max_reflectivity',
  base_reflectivity: 'base_reflectivity',
  // wgrd10m: 'wind',
  wgrd10m: 'wgrd10m',
  w100m: 'w100m',
  w200m: 'wind',
  prer: 'prer',
  pres: 'pres',
  prei: 'prei',
  preg: 'preg',
  slp: 'slp',
  ri_min: 'ri_min',
  pratesfc: 'pratesfc',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: 'snow',
  rh2m: 'rh2m'
};

export const factorAppMap = {
  tmp: 't2mz',
  wind: 'wgrd10m',
  t2mz: 'temp',
  tmp2m: 'tmp2m',
  tmpsfc: 'temp',
  max_reflectivity: 'max_reflectivity',
  base_reflectivity: 'base_reflectivity',
  // wgrd10m: 'wind',
  wgrd10m: 'wgrd10m',
  w100m: 'w100m',
  w200m: 'wind',
  slp: 'slp',
  ri: 'ri_min',
  prate: 'pratesfc',
  preall: 'preall',
  rh2m: 'rh2m',
  presrt: 'presrt',
  prerrt: 'prerrt',
  preirt: 'preirt',
  pregrt: 'pregrt',
  prer: 'prer',
  pres: 'pres',
  preg: 'preg',
  prei: 'prei',
  // preirt: 'ice',
  // pregrt: 'graupel',
  // prer: 'prer',
  // pres: 'pres',
  // preg: 'preg',
  // prei: 'prei',
};

export const factorFour = {
  preall: 'preall',
  prert: 'prert',
};

export const productMap = {
  t1_da: 't1',
  t2_da: 't2'
}

export const factorPressure = {
  // prer: 'rain',
  // pres: 'snow',
  // prei: 'ice',
  // preg: 'graupel',
  // preall: 'preall',
  // prert: 'prert',
  // prerrt: 'rain',
  // pregrt: 'graupel',
  // preirt: 'ice',
  // presrt: 'snow',
  // pratesfc: 'pratesfc',
};

export const factorWind = {
  pratesfc: 'wgrd10m',
};

export const HDMap = {
  // wgrd10m: 'wind',
  prer: 'prer',
  pres: 'pres',
  prei: 'prei',
  preg: 'preg',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: 'snow',
  pratesfc: 'pratesfc',
};

export const factorNameMap = {
  temp : '温度',
  tmp2m: '温度',
  wind : `风速`,
  wgrd10m: '风速',
  rain : '降雨',
  snow : '降雪',
  ice : '降冰',
  graupel : '霰降水',
  prer : '降雨',
  pres : '降雪',
  prei : '降冰',
  preg : '霰降水',
  ri_min : '指数',
  slp : '气压',
  pratesfc : '总降水率',
  max_reflectivity: '雷达',
  base_reflectivity: '雷达',
  rh2m: '相对湿度',
}

export const factorAppNameMap = {
  temp : '温度',
  tmp2m: '温度',
  rain : '降雨',
  snow : '降雪',
  ice : '冰降水',
  graupel : '霰降水',
  prer : '累计降雨',
  pres : '累计降雪',
  prei : '累计冰降水',
  preirt : '冰降水',
  preg : '累计霰降水',
  ri_min : '晴空颠簸指数',
  slp : '气压',
  wgrd10m: '10米风场',
  w100m: '100米风场',
  wind: '10米风场',
  pratesfc : '总降水率',
  max_reflectivity: '明日雷达',
  base_reflectivity: '基本反射率',
  rh2m: '相对湿度',
}

export const factorUnitMap = {
  temp : '°C',
  tmp2m: '°C',
  wind : `m/s`,
  wgrd10m: 'm/s',
  w100m : `m/s`,
  rain : 'mm/hr',
  snow : 'mm/hr',
  ice : 'mm/hr',
  preirt : 'mm/hr',
  graupel : 'mm/hr',
  prer : 'mm',
  pres : 'mm',
  prei : 'mm',
  preg : 'mm',
  ri_min : '',
  slp : 'mb',
  pratesfc : 'mm/hr',
  max_reflectivity: 'dBz',
  base_reflectivity: 'dBz',
  rh2m: '%'
}

export const unitMap = {
  temp(value) {
    return `温度：<br/><span class="current-value">${value}</span>`;
  },
  wind(value) {
    return `风速：<br/><span class="current-value">${value}</span>`;
  },
  rain(value) {
    return `降雨：<br/><span class="current-value">${value}</span>`;
  },
  snow(value) {
    return `降雪：<br/><span class="current-value">${value}</span>`;
  },
  ice(value) {
    return `降冰：<br/><span class="current-value">${value}</span>`;
  },
  graupel(value) {
    return `霰降水：<br/><span class="current-value">${value}</span>`;
  },

  prer(value) {
    return `降雨：<br/><span class="current-value">${value}</span>`;
  },
  pres(value) {
    return `降雪：<br/><span class="current-value">${value}</span>`;
  },
  prei(value) {
    return `降冰：<br/><span class="current-value">${value}</span>`;
  },
  preg(value) {
    return `霰降水：<br/><span class="current-value">${value}</span>`;
  },
  ri_min(value) {
    return `指数：<br/><span class="current-value">${value}</span>`;
  },
  slp(value) {
    return `气压：<br/><span class="current-value">${value}</span>`;
  },
  pratesfc(value) {
    return `总降水率：<br/><span class="current-value">${value}</span>`;
  },
  preall(value) {
    return `
      降雨：<br/><span class="current-value">${value.rain}<br /></span>
      降雪：<br/><span class="current-value">${value.snow}<br /></span>
      降冰：<br/><span class="current-value">${value.ice}<br /></span>
      霰降水：<br/><span class="current-value">${value.graupel}<br />
    </span>`;
  },
  prert(value) {
    return `
      降雨：<br/><span class="current-value">${value.rain}<br /></span>
      降雪：<br/><span class="current-value">${value.snow}<br /></span>
      降冰：<br/><span class="current-value">${value.ice}<br /></span>
      霰降水：<br/><span class="current-value">${value.graupel}<br />
    </span>`;
  },

  max_reflectivity(value) {
    return `雷达:<br/><span class="current-value"> ${(value < 0 ? 0 : value)}</span>`;
  },

  base_reflectivity(value) {
    return `雷达:<br/><span class="current-value"> ${(value < 0 ? 0 : value)}</span>`;
  },
};

export const unit = {
  temp: '℃',
  wind: 'm/s',
  rain: 'mm/hr',
  snow: 'mm/hr',
  ice: 'mm/hr',
  graupel: 'mm/hr',
  prer: 'mm',
  pres: 'mm',
  prei: 'mm',
  preg: 'mm',
  slp: 'mb',
  ri_min: 'n/a',
  pratesfc: 'mm/hr',
  max_reflectivity: 'dBz',
  base_reflectivity: 'dBz',
  rh2m: '%',
};

// export const geoserverUrl = 'https://app.tjweather.com:8000';
export const geoserverUrl = '';

export default metrics;
