const parsedataconfig = {};

parsedataconfig.color_arrays = {
  temp: [
    [58, 23, 62, 255],
    [119, 48, 130, 255],
    [40, 30, 97, 255],
    [29, 84, 144, 255],
    [47, 150, 187, 255],
    [137, 206, 173, 255],
    [231, 248, 212, 255],
    [247, 218, 136, 255],
    [247, 146, 75, 255],
    [232, 76, 41, 255],
    [159, 42, 21, 255],
    [90, 20, 5, 255],
    [13, 3, 1, 255],

    // [115, 70, 105, 255],
    // [202, 172, 195, 255],
    // [162, 70, 145, 255],
    // [143, 89, 169, 255],
    // [157, 219, 217, 255],
    // [106, 191, 181, 255],
    // [100, 166, 189, 255],
    // [93, 133, 198, 255],
    // [68, 125, 99, 255],
    // [128, 147, 24, 255],
    // [243, 183, 4, 255],
    // [232, 83, 25, 255],
    // [71, 14, 0, 255],

    // [58, 23, 62, 255],
    // [119, 48, 130, 255],
    // [40, 30, 97, 255],
    // [29, 84, 144, 255],
    // [76,160,198, 255, 255],
    // [99, 201, 198, 255],
    // [140, 232, 145, 255],
    // [214, 243, 150, 255],
    // [249, 232, 150, 255],
    // [248, 196, 75, 255],
    // [245, 135, 39, 255],
    // [221, 38, 43, 255],
    // [96, 10, 48, 255],



  ],

  dust_conc: [
    [122, 122, 122, 0],
    [253, 240, 208, 255],
    [252, 227, 163, 255],
    [252, 215, 117, 255],
    [240, 181, 94, 255],
    [229, 148, 71, 255],
    [218, 114, 48, 255],
    [202, 107, 45, 194],
    [185, 101, 41, 133],
    [157, 98, 38, 72],
    [142, 90, 36, 133],
    [128, 81, 35, 194],
    [113, 73, 33, 255],
  ],


  dust_ddep: [
    [122, 122, 122, 0],
    [178, 235, 229, 255],
    [129, 223, 190, 255],
    [167, 220, 159, 255],
    [205, 217, 149, 255],
    [246, 215, 128, 255],
    [205, 118, 62, 255],
    [178, 109, 55, 255],
    [151, 101, 48, 255],
    [108, 74, 40, 255],
    [52, 39, 20, 255],
    [38, 28, 17, 255],
    [24, 18, 14, 255],
    // [111, 111, 111, 255],
  ],

  dod: [
    [255, 255, 255, 0],
    [255, 250, 209, 255],
    [255, 230, 112, 255],
    [255, 235, 69, 255],
    [255, 204, 51, 255],
    [255, 175, 51, 255],
    [255, 153, 51, 255],
    [255, 111, 51, 255],
    [255, 85, 0, 255],
    [230, 40, 30, 255],
    [200, 30, 20, 255]
  ],


  dust_emis: [
    [122, 122, 122, 0],
    [178, 235, 229, 255],
    [129, 223, 190, 255],
    [167, 220, 159, 255],
    [205, 217, 149, 255],
    [246, 215, 128, 255],
    [205, 118, 62, 255],
    [178, 109, 55, 255],
    [151, 101, 48, 255],
    [108, 74, 40, 255],
    [52, 39, 20, 255],
    [38, 28, 17, 255],
    [24, 18, 14, 255],
    // [111, 111, 111, 255],
  ],

  wind: [

    // [109, 158, 173, 255],
    // [88, 152, 171,255],
    // [74, 148, 170, 255],
    // [74, 146, 148, 255],
    // [77, 142, 124, 255],
    // [76, 164, 76, 255],
    // [103, 164, 54, 255],
    // [162, 135, 64, 255],
    // [162, 109, 92, 255],
    // [141, 63, 92, 255],
    // [151, 75, 145, 255],
    // [95, 100, 160, 255],
    // [50, 59, 162, 255],


    [98, 113, 184, 255],
    [61, 110, 163, 255],
    [74, 148, 170, 255],
    [74, 146, 148, 255],
    [77, 142, 124, 255],
    [76, 164, 76, 255],
    [103, 164, 54, 255],
    [162, 135, 64, 255],
    [162, 109, 92, 255],
    [141, 63, 92, 255],
    [151, 75, 145, 255],
    [95, 100, 160, 255],
    [91, 136, 161, 255],
    // [98, 113, 183, 255],
    // [57, 97, 159, 255],
    // [74, 148, 169, 255],
    // [77, 141, 123, 255],
    // [83, 165, 83, 255],
    // [53, 159, 53, 255],
    // [167, 157, 81, 255],
    // [159, 127, 58, 255],
    // [161, 108, 92, 255],
    // [129, 58, 78, 255],
    // [175, 80, 136, 255],
    // [117, 74, 147, 255],
    // [109, 97, 163, 255],
    // [68, 105, 141, 255],
    // [92, 144, 152, 255],
    // [125, 68, 165, 255],
    // [231, 215, 215, 256],
    // [219, 212, 135, 256],
    // [205, 202, 112, 256],
    // [128, 128, 128, 255],
  ],

  rain: [
    [122, 122, 122,0],
    [7, 146, 221, 255],
    [9, 181, 241, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],

    // [111, 111, 111, 255],
    // [60, 116, 160, 255],
    // [59, 161, 161, 255],
    // [59, 161, 61, 255],
    // [130, 161, 59, 255],
    // [161, 161, 59, 255],
    // [161, 59, 59, 255],
    // [161, 59, 161, 255],
    // [168, 168, 168, 255],
  ],

  snow: [
    [122, 122, 122,0],
    [7, 146, 221, 255],
    [9, 181, 241, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [97, 97, 97, 255],
    // [69, 82, 152, 255],
    // [65, 165, 167, 255],
    // [65, 141, 65, 255],
    // [168, 168, 65, 255],
    // [170, 126, 63, 255],
    // [167, 65, 65, 255],
    // [168, 65, 168, 255],
  ],

  ice: [
    [122, 122, 122,0],
    [7, 146, 221, 255],
    [9, 181, 241, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [97, 97, 97, 255],
    // [69, 82, 152, 255],
    // [65, 165, 167, 255],
    // [65, 141, 65, 255],
    // [168, 168, 65, 255],
    // [170, 126, 63, 255],
    // [167, 65, 65, 255],
    // [168, 65, 168, 255],
  ],

  graupel: [
    [122, 122, 122,0],
    [7, 146, 221, 255],
    [9, 181, 241, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [97, 97, 97, 255],
    // [69, 82, 152, 255],
    // [65, 165, 167, 255],
    // [65, 141, 65, 255],
    // [168, 168, 65, 255],
    // [170, 126, 63, 255],
    // [167, 65, 65, 255],
    // [168, 65, 168, 255],
  ],

  prer: [
    [161, 241, 141, 0],
    [161, 241, 141, 255],
    [61, 186, 61, 255],
    [96, 184, 255, 255],
    [0, 0, 255, 255],
    [250, 0, 250, 255],
    [128, 0, 64, 255],
    [255, 170, 0, 255],
    [255, 102, 0, 255],
    [230, 0, 0, 255],
    [80, 45, 10, 255],
    // [111, 111, 111, 255],
    // [60, 116, 160, 255],
    // [59, 161, 161, 255],
    // [59, 161, 61, 255],
    // [130, 161, 59, 255],
    // [161, 161, 59, 255],
    // [161, 59, 59, 255],
    // [161, 59, 161, 255],
    // [168, 168, 168, 255],
  ],

  pre_total: [
    [122, 122, 122, 0],
    [161, 241, 141, 255],
    [61, 186, 61, 255],
    [96, 184, 255, 255],
    [0, 0, 255, 255],
    [250, 0, 250, 255],
    [128, 0, 64, 255],
    [255, 170, 0, 255],
    [255, 102, 0, 255],
    [230, 0, 0, 255],
    [80, 45, 10, 255],
    // [111, 111, 111, 255],
    // [60, 116, 160, 255],
    // [59, 161, 161, 255],
    // [59, 161, 61, 255],
    // [130, 161, 59, 255],
    // [161, 161, 59, 255],
    // [161, 59, 59, 255],
    // [161, 59, 161, 255],
    // [168, 168, 168, 255],
  ],
  pres_all: [
    [122, 122, 122, 0],
    [161, 241, 141, 255],
    [61, 186, 61, 255],
    [96, 184, 255, 255],
    [0, 0, 255, 255],
    [250, 0, 250, 255],
    [128, 0, 64, 255],
    [255, 170, 0, 255],
    [255, 102, 0, 255],
    [230, 0, 0, 255],
    [80, 45, 10, 255],
    // [111, 111, 111, 255],
    // [60, 116, 160, 255],
    // [59, 161, 161, 255],
    // [59, 161, 61, 255],
    // [130, 161, 59, 255],
    // [161, 161, 59, 255],
    // [161, 59, 59, 255],
    // [161, 59, 161, 255],
    // [168, 168, 168, 255],
  ],

  pres_alls: [
    [122, 122, 122, 0],
    [161, 241, 141, 255],
    [61, 186, 61, 255],
    [96, 184, 255, 255],
    [0, 0, 255, 255],
    [250, 0, 250, 255],
    [128, 0, 64, 255],
    [255, 170, 0, 255],
    [255, 102, 0, 255],
    [230, 0, 0, 255],
    [80, 45, 10, 255],
    // [111, 111, 111, 255],
    // [60, 116, 160, 255],
    // [59, 161, 161, 255],
    // [59, 161, 61, 255],
    // [130, 161, 59, 255],
    // [161, 161, 59, 255],
    // [161, 59, 59, 255],
    // [161, 59, 161, 255],
    // [168, 168, 168, 255],
  ],

  pres: [
    [122, 122, 122, 0],
    [0, 33, 155, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [97, 97, 97, 255],
    // [69, 82, 152, 255],
    // [65, 165, 167, 255],
    // [65, 141, 65, 255],
    // [168, 168, 65, 255],
    // [170, 126, 63, 255],
    // [167, 65, 65, 255],
    // [168, 65, 168, 255],
  ],

  prei: [
    [122, 122, 122, 0],
    [0, 33, 155, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [97, 97, 97, 255],
    // [69, 82, 152, 255],
    // [65, 165, 167, 255],
    // [65, 141, 65, 255],
    // [168, 168, 65, 255],
    // [170, 126, 63, 255],
    // [167, 65, 65, 255],
    // [168, 65, 168, 255],
  ],

  preg: [
    [122, 122, 122, 0],
    [0, 33, 155, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [97, 97, 97, 255],
    // [69, 82, 152, 255],
    // [65, 165, 167, 255],
    // [65, 141, 65, 255],
    // [168, 168, 65, 255],
    // [170, 126, 63, 255],
    // [167, 65, 65, 255],
    // [168, 65, 168, 255],
  ],

  slp: [
    [191, 255, 63, 255],
    [255, 255, 63, 255],
    [255, 223, 63, 255],
    [255, 160, 63, 255],
    [255, 96, 63, 255],
    [255, 31, 63, 255],
    [255, 96, 191, 255],
    [255, 160, 255, 255],
    [255, 223, 255, 255],
  ],

  ri_min: [
    [163, 0, 0, 255],
    [209, 0, 0, 255],
    [245, 27, 0, 255],
    [255, 88, 0, 255],
    [255, 175, 63, 255],
    [217, 222, 92, 255],
    [62, 191, 83, 255],
    [0, 165, 134, 255],
    [34, 152, 207, 255],
    [107, 199, 236, 255],
    [107, 199, 236, 0],
    // [111, 111, 111, 255],
  ],
  pratesfc1: [
    [255, 255, 255, 0],
    [32, 187, 200, 111],
    // [0, 156, 255, 130],
    // [16, 111, 231, 150],
    // [20, 87, 203, 200],
    // [26, 59, 169, 255],
    [90, 85, 180, 255],
    [104, 62, 187, 255],
    [120, 36, 195, 255],
    [215, 87, 149, 255],
    [247, 134, 141, 255],
    [249, 215, 77, 255],
  ],

  pratesfc: [
    [122, 122, 122,0],
    [7, 146, 221, 255],
    [9, 181, 241, 255],
    [0, 206, 255, 255],
    [0, 250, 156, 255],
    [63, 224, 0, 255],
    [141, 255, 19, 255],
    [241, 255, 11, 255],
    [255, 200, 7, 255],
    [255, 61, 0, 255],
    [255, 0, 194, 255],
    [188, 80, 247, 255],
    [245, 192, 246, 255],
    // [111, 111, 111, 255],
    // [60, 116, 160, 255],
    // [59, 161, 161, 255],
    // [59, 161, 61, 255],
    // [130, 161, 59, 255],
    // [161, 161, 59, 255],
    // [161, 59, 59, 255],
    // [161, 59, 161, 255],
    // [168, 168, 168, 255],
  ],

  max_reflectivity: [
    [122, 122, 122, 0],
    [1, 160, 246, 255],
    [0, 198, 241, 255],
    [0, 226, 118, 255],
    [0, 180, 0, 255],
    [128, 200, 0, 255],
    [243, 223, 0, 255],
    [243, 168, 0, 255],
    [128, 72, 0, 255],
    [135, 0, 0, 255],
    [203, 0, 0, 255],
    [224, 0, 120, 255],
    [203, 0, 210, 255],
    [162, 72, 210, 255],
    // [40, 16, 158, 0],
    // [40, 16, 158, 20],
    // [40, 16, 158, 100],
    // [0, 101, 154, 180],
    // [0, 144, 147, 220],
    // [0, 179, 125, 240],
    // [117, 208, 89, 255],
    // [220, 220, 30, 255],
    // [244, 202, 8, 255],
    // [245, 168, 24, 255],
    // [236, 130, 63, 255],
    // [205, 75, 75, 255],
    // [182, 45, 100, 255],
    // [156, 16, 109, 255],
    // [125, 0, 108, 255],
    // [92, 0, 100, 255],
    // [0, 0, 0, 255],
    // [0, 0, 0, 0],
    // [0, 0, 0, 0],
  ],

  base_reflectivity: [
    [122, 122, 122, 0],
    [1, 160, 246, 255],
    [0, 198, 241, 255],
    [0, 226, 118, 255],
    [0, 180, 0, 255],
    [128, 200, 0, 255],
    [243, 223, 0, 255],
    [243, 168, 0, 255],
    [128, 72, 0, 255],
    [135, 0, 0, 255],
    [203, 0, 0, 255],
    [224, 0, 120, 255],
    [203, 0, 210, 255],
    [162, 72, 210, 255],
    // [40, 16, 158, 0],
    // [40, 16, 158, 20],
    // [40, 16, 158, 100],
    // [0, 101, 154, 180],
    // [0, 144, 147, 220],
    // [0, 179, 125, 240],
    // [117, 208, 89, 255],
    // [220, 220, 30, 255],
    // [244, 202, 8, 255],
    // [245, 168, 24, 255],
    // [236, 130, 63, 255],
    // [205, 75, 75, 255],
    // [182, 45, 100, 255],
    // [156, 16, 109, 255],
    // [125, 0, 108, 255],
    // [92, 0, 100, 255],
    // [0, 0, 0, 255],
    // [0, 0, 0, 0],
    // [0, 0, 0, 0],
  ],

  rh2m: [
    [150, 50, 0, 255],
    [150, 50, 0, 255],
    [234, 113, 21, 255],
    [255, 154, 43, 255],
    [255, 195, 80, 255],
    [254, 228, 143, 255],
    [189, 222, 240, 255],
    [127, 186, 217, 255],
    [67, 148, 195, 255],
    [31, 98, 172, 255],
    [6, 48, 96, 255],
  ],

  dswrfsfc: [
    [122, 122, 122, 0],
    [254, 246, 206, 255],
    [254, 241, 183, 255],
    [252, 168, 53, 255],
    [247, 150, 47, 255],
    [248, 121, 43, 255],
    [244, 109, 40, 255],
    [229, 83, 35, 255],
    // [214, 69, 74, 255],
    // [215, 50, 45, 255],
    // [216, 43, 41, 255],
    // [190, 24, 33, 255],
    [232, 76, 41, 255],
    [159, 42, 21, 255],
    [90, 20, 5, 255],
    [13, 3, 1, 255]
  ],

};
parsedataconfig.values = {
  temp: [-30, -20, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40],
  // temp: [
  //   -70,
  //   -55,
  //   -40,
  //   -25,
  //   -15,
  //   -8,
  //   -4,
  //   0,
  //   1,
  //   10,
  //   20,
  //   30,
  //   50,
  // ],
  wind: [0, 1, 2, 5, 8, 10, 11, 12, 15, 17, 20, 25, 30],
  // wind: [
  //   0,
  //   1,
  //   3,
  //   5,
  //   7,
  //   9,
  //   11,
  //   13,
  //   15,
  //   17,
  //   19,
  //   21,
  //   24,
  //   27,
  //   29,
  //   36,
  //   46,
  //   51,
  //   77,
  //   104,
  // ],
  rain: [0, 0.1, 0.5,1, 2, 3, 4, 5, 6, 10, 15, 20, 30],
  // rain: [
  //   0,
  //   0.6,
  //   6,
  //   8,
  //   10,
  //   15,
  //   20,
  //   31,
  //   50,
  // ],
  snow: [0,0.05, 0.1, 0.2, 0.5, 0.7, 1, 1.5, 2, 3, 4, 5, 7.5],
  // snow: [
  //   0,
  //   2,
  //   10,
  //   20,
  //   50,
  //   80,
  //   120,
  //   500,
  // ],
  ice: [0,0.05,0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1, 1.5, 2],
  // ice: [
  //   0,
  //   2,
  //   10,
  //   20,
  //   50,
  //   80,
  //   120,
  //   500,
  // ],
  graupel: [0, 0.05,0.1, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.6, 2, 3, 4],
  // graupel: [
  //   0,
  //   2,
  //   10,
  //   20,
  //   50,
  //   80,
  //   120,
  //   500,
  // ],
  prer: [0, 0.25, 1, 10, 25, 50, 100, 250, 400, 600, 800],
  pre_total: [0, 0.25, 1, 10, 25, 50, 100, 250, 400, 600, 800],
  pres_alls: [0, 0.25, 1, 10, 25, 50, 100, 250, 400, 600, 800],
  pres_all: [0, 0.25, 1, 10, 25, 50, 100, 250, 400, 600, 800],


  // prer: [
  //   0,
  //   0.6,
  //   6,
  //   8,
  //   10,
  //   15,
  //   20,
  //   31,
  //   50,
  // ],
  pres: [0, 0.1, 0.2, 0.5, 0.7, 1, 2, 5, 10, 20, 30, 50],
  // pres: [
  //   0,
  //   2,
  //   10,
  //   20,
  //   50,
  //   80,
  //   120,
  //   500,
  // ],
  prei: [0, 0.1, 0.2, 0.3, 0.5, 1, 2, 5, 7.5, 10, 15, 20],

  // prei: [
  //   0,
  //   2,
  //   10,
  //   20,
  //   50,
  //   80,
  //   120,
  //   500,
  // ],
  preg: [0, 0.1, 0.2, 0.4, 0.6, 0.8, 1, 2, 4, 8, 12, 16],
  dust_conc: [0, 300, 500, 800, 1200, 1600, 2000, 2500, 3000, 3500, 4000, 10000, 14000],
  dod: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  dust_emis: [0, 5, 20, 30, 40, 50, 200, 300, 500, 2000, 5000, 10000, 20000],
  dust_ddep: [0, 5, 20, 30, 40, 50, 200, 300, 500, 2000, 5000, 10000, 20000],

  // preg: [
  //   0,
  //   2,
  //   10,
  //   20,
  //   50,
  //   80,
  //   120,
  //   500,
  // ],
  slp: [990, 995, 1000, 1005, 1010, 1015, 1020, 1025, 1030],
  ri_min: [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7.5],
  pratesfc: [0, 0.25, 0.65,1, 2, 3, 5, 6, 8, 10, 15, 20, 30],
  // pratesfc: [
  //   0,
  //   0.6,
  //   6,
  //   8,
  //   10,
  //   15,
  //   20,
  //   31,
  //   50,
  // ],
  // pratesfc: [0, 1, 5, 10, 30, 40, 80, 120, 500, 8000],
  max_reflectivity: [0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
  // max_reflectivity: [
  //   0,
  //   3,
  //   8,
  //   14,
  //   20,
  //   26,
  //   32,
  //   36,
  //   40,
  //   44,
  //   48,
  //   52,
  //   56,
  //   60,
  //   64,
  //   68,
  //   100,
  //   101,
  //   255,
  // ],
  base_reflectivity: [0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
  // base_reflectivity: [
  //   0,
  //   3,
  //   8,
  //   14,
  //   20,
  //   26,
  //   32,
  //   36,
  //   40,
  //   44,
  //   48,
  //   52,
  //   56,
  //   60,
  //   64,
  //   68,
  //   100,
  //   101,
  //   255,
  // ],
  rh2m: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  dswrfsfc: [0, 50, 100, 150, 300, 350, 500, 600, 700, 800, 900, 1000],
};

/**
 * 查询当前值对应的色值
 */
export function binarySearch(arr, findVal, leftIndex, rightIndex) {
  if (leftIndex > rightIndex) {
    return leftIndex - 1;
  }
  const midIndex = Math.floor((leftIndex + rightIndex) / 2);
  const midVal = arr[midIndex];
  if (midVal > findVal) {
    return binarySearch(arr, findVal, leftIndex, midIndex - 1);
  } if (midVal < findVal) {
    return binarySearch(arr, findVal, midIndex + 1, rightIndex);
  }
  return midIndex + 1;
}

export default parsedataconfig;
