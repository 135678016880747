import L from 'leaflet';
import _ from 'lodash';
import store from '@/store/index';
import parseDataConfig from '@/config/ColorBar';
import { factorMap } from '@/config/MapConfig';

function gradienta(sColor, eColor, svalue, evalue, value) {
  const step = ((evalue - svalue) / (value - svalue));
  const rStep = (eColor[0] - sColor[0]) / step;
  const gStep = (eColor[1] - sColor[1]) / step;
  const bStep = (eColor[2] - sColor[2]) / step;
  const aStep = (eColor[3] - sColor[3]) / step;
  return [Math.round(rStep + sColor[0]), Math.round(gStep + sColor[1]), Math.round(bStep + sColor[2]), Math.round(
    aStep + sColor[3],
  )];
}

function decodePixel(image_data, tile_width) {
  let r; let g; let b;
  const buffer = new ArrayBuffer(24);
  const src = new Uint8Array(buffer);
  const result = new Float32Array(buffer);
  let byte_offset = 4 * tile_width * 4 + 8;
  for (let index = 0; index < 24; index++) {
    r = image_data[byte_offset];
    g = image_data[byte_offset + 1];
    b = image_data[byte_offset + 2];
    r = Math.round(r / 64);
    g = Math.round(g / 16);
    b = Math.round(b / 64);
    src[index] = (r << 6) + (g << 2) + b;
    byte_offset += 16;
  }
  return result;
}

function getIndex(value, allvalue) {
  let low = 0;
  let high = allvalue.length - 1;
  let mid;
  while (low <= high) {
    mid = (low + high) >> 1;
    if (allvalue[mid] < value) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }
  return low;
}

function getGradientColor(name, value) {
  const colorarray = parseDataConfig.color_arrays[name];
  const colorlength = parseDataConfig.color_arrays[name].length;
  const allvalue = parseDataConfig.values[name];

  if (value <= allvalue[0]) {
    return colorarray[0];
  }
  if (value >= allvalue[allvalue.length - 1]) {
    return colorarray[colorlength - 1];
  }

  const ops = getIndex(value, allvalue);

  const svalue = allvalue[ops - 1];
  const evalue = allvalue[ops];
  const scolor = colorarray[ops - 1];
  const ecolor = colorarray[ops];

  return gradienta(scolor, ecolor, svalue, evalue, value);
}

function reverse(data, max, min) {
  const result = _.round(Math.exp(1) ** (min + data * (max - min) / 255) - 200, 2);
  if (factorMap[store.state.map.factor] === 'temp') {
    return result - 270.15;
  }
  if (factorMap[store.state.map.factor] === 'pratesfc') {
    return result < 0.1 ? 0 : result;
  }
  return result;
}

const canvas = document.createElement('canvas');
const array = [];

L.TileLayer.FormatLayer = L.TileLayer.extend({
  initialize(url, options) {
    options = options || {};
    options.crossOrigin = true;
    L.TileLayer.prototype.initialize.call(this, url, options);
  },

  _clampZoom(zoom) {
    const options = this.options;

    if (undefined !== options.minNativeZoom && zoom < options.minNativeZoom) {
      return options.minNativeZoom;
    }

    if (undefined !== options.maxNativeZoom && options.maxNativeZoom < zoom) {
      return options.maxNativeZoom;
    }

    return zoom - 1;
  },

  async createTile(coords, done, type) {
    const tile = document.createElement('img');

    L.DomEvent.on(tile, 'load', L.Util.bind(this._tileOnLoad, this, done, tile));
    L.DomEvent.on(tile, 'error', L.Util.bind(this._tileOnError, this, done, tile));

    if (this.options.crossOrigin || this.options.crossOrigin === '') {
      tile.crossOrigin = this.options.crossOrigin === true ? '' : this.options.crossOrigin;
    }

    /*
     Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
     http://www.w3.org/TR/WCAG20-TECHS/H67
    */
    tile.alt = '';

    /*
     Set role="presentation" to force screen readers to ignore this
     https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
    */
    tile.setAttribute('role', 'presentation');

    tile.src = this.getTileUrl(coords);

    if (type) {
      await new Promise((resolve) => {
        tile.addEventListener('load', () => {
          this._makeGrayscale(tile);
          resolve();
        });
      });
    } else {
      tile.addEventListener('load', () => {
        this._makeGrayscale(tile);
      });
    }

    return tile;
  },

  _makeGrayscale(img) {
    if (img.getAttribute('data-format')) return null;

    img.crossOrigin = '';
    canvas.width = 256;
    canvas.height = 264;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, 8);
    const result = decodePixel(imageData.data, 256);

    const [max, min] = result;

    const imageData1 = ctx.getImageData(0, 8, canvas.width, canvas.height - 8);
    const data = imageData1.data;

    for (let i = 0; i < data.length; i += 4) {
      const realdata = reverse(data[i], max, min);

      const color = getGradientColor(factorMap[store.state.map.factor], realdata);
      data[i] = color[0];
      data[i + 1] = color[1];
      data[i + 2] = color[2];

      if (color[0] === 255 && color[1] === 255 && color[2] === 255) {
        data[i + 3] = 0;
      }
    }

    canvas.height -= 8;

    ctx.putImageData(imageData1, 0, 0);
    ctx.imageSmoothingEnabled = false;
    img.setAttribute('data-format', true);
    img.src = canvas.toDataURL();

    return new Promise((resolve) => {
      img.addEventListener('load', resolve);
    });
  },

  async _addTile(coords, container, type) {
    const tilePos = this._getTilePos(coords);
    const key = this._tileCoordsToKey(coords);

    const tile = await this.createTile(this._wrapCoords(coords), L.Util.bind(this._tileReady, this, coords), type);

    this._initTile(tile);

    // if createTile is defined with a second argument ("done" callback),
    // we know that tile is async and will be ready later; otherwise
    if (this.createTile.length < 2) {
      // mark tile as ready, but delay one frame for opacity animation to happen
      L.Util.requestAnimFrame(L.Util.bind(this._tileReady, this, coords, null, tile));
    }

    L.DomUtil.setPosition(tile, tilePos);

    // save tile in cache
    this._tiles[key] = {
      el: tile,
      coords,
      current: true,
    };

    container.appendChild(tile);
    // @event tileloadstart: TileEvent
    // Fired when a tile is requested and starts loading.
    this.fire('tileloadstart', {
      tile,
      coords,
    });
  },

  _update(center, type, done) {
    const map = this._map;
    if (!map) {
      return;
    }
    const zoom = this._clampZoom(map.getZoom());

    if (center === undefined) {
      center = map.getCenter();
    }
    if (this._tileZoom === undefined) {
      return;
    } // if out of minzoom/maxzoom

    const pixelBounds = this._getTiledPixelBounds(center);
    const tileRange = this._pxBoundsToTileRange(pixelBounds);
    const tileCenter = tileRange.getCenter();
    const queue = [];
    const margin = this.options.keepBuffer;
    const noPruneRange = new L.Bounds(tileRange.getBottomLeft().subtract([margin, -margin]), tileRange.getTopRight().add([margin, -margin]));

    // Sanity check: panic if the tile range contains Infinity somewhere.
    if (
      !(Number.isFinite(tileRange.min.x)
        && Number.isFinite(tileRange.min.y)
        && Number.isFinite(tileRange.max.x)
        && Number.isFinite(tileRange.max.y))
    ) {
      throw new Error('Attempted to load an infinite number of tiles');
    }

    Object.keys(this._tiles).forEach((key) => {
      const c = this._tiles[key].coords;
      if (c.z !== this._tileZoom || !noPruneRange.contains(new L.Point(c.x, c.y))) {
        this._tiles[key].current = false;
      }
    });

    // _update just loads more tiles. If the tile zoom level differs too much
    // from the map's, let _setView reset levels and prune old tiles.
    if (Math.abs(zoom - this._tileZoom) > 1) {
      this._setView(center, zoom);
      return;
    }

    // create a queue of coordinates to load tiles from
    for (let j = tileRange.min.y; j <= tileRange.max.y; j++) {
      for (let i = tileRange.min.x; i <= tileRange.max.x; i++) {
        const coords = new L.Point(i, j);
        coords.z = this._tileZoom;

        if (!this._isValidTile(coords)) {
          continue;
        }

        const tile = this._tiles[this._tileCoordsToKey(coords)];
        if (tile) {
          tile.current = true;
        } else {
          queue.push(coords);
        }
      }
    }

    // sort tile queue to load tiles in order of their distance to center
    queue.sort((a, b) => a.distanceTo(tileCenter) - b.distanceTo(tileCenter));

    if (queue.length !== 0) {
      // if it's the first batch of tiles to load
      if (!this._loading) {
        this._loading = true;
        // @event loading: Event
        // Fired when the grid layer starts loading tiles.
        this.fire('loading');
      }

      // create DOM fragment to append tiles in one batch
      const fragment = document.createDocumentFragment();

      if (type) {
        Promise.all(queue.map((item) => this._addTile(item, fragment, type))).then(() => {
          requestAnimationFrame(() => {
            this._level.el.appendChild(fragment);
            requestAnimationFrame(() => {
              setTimeout(() => {
                while (array.length) {
                  L.DomUtil.remove(array.pop());
                }

                done && done();
              }, 200);
            });
          });
        });
      } else {
        for (let i = 0; i < queue.length; i++) {
          this._addTile(queue[i], fragment);
        }
        setTimeout(() => {
          this._level.el.appendChild(fragment);
        }, 0);
      }
    }
  },

  redraw(type, done) {
    if (this._map) {
      this._removeAllTiles();
      this._update(undefined, type, done);
    }
    return this;
  },

  _removeTile(key) {
    const tile = this._tiles[key];
    if (!tile) { return; }

    array.push(tile.el);

    // L.DomUtil.remove(tile.el);

    delete this._tiles[key];

    // @event tileunload: TileEvent
    // Fired when a tile is removed (e.g. when a tile goes off the screen).
    this.fire('tileunload', {
      tile: tile.el,
      coords: this._keyToTileCoords(key),
    });
  },

  setUrl(url, noRedraw, done) {
    if (this._url === url && noRedraw === undefined) {
      noRedraw = true;
    }

    this._url = url;

    if (!noRedraw) {
      this.redraw('playing', done);
    }
    return this;
  },

});
