<template>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { drag } from '@/utils/drag';

export default {
  filters: {
    formatValue(value, index, length, mobile) {
      const border = mobile ? length : 0;
      return index === border ? value + (value ? '+' : '') : value;
    },
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['viewHeight']),
    ...mapState('typhoon', ['modelLegend','typhoonObj', 'tableListData', 'ObserveTyphoonList',
      'observeTyphoonObj','typhoonLineObj','timeLineIndex','timeLineDate', 'timeLineLengthObj',
      'pointLengthObj','timeLineLength']),

    name() {
      return this.modelLegend;
    },
    legendName() {
      return [
        {icon: 't-01', name: '热带低压'},
        {icon: 't-02', name: '热带风暴'},
        {icon: 't-03', name: '强热带风暴'},
        {icon: 't-04', name: '台风'},
        {icon: 't-05', name: '强台风'},
        {icon: 't-06', name: '超强台风'},
      ]
    },
  },

  mounted() {
    window.modelLegendSelected = (e) => {
      const data = JSON.parse(JSON.stringify(e));
      const checked = data.checked.toString() === '1';
      console.log('checked=>',checked, 'key==>',data.key);
      this.switchChange(checked,data.key);
    }
    window.modelLegendWindSelected = (e) => {
      const data = JSON.parse(JSON.stringify(e));
      const checked = data.checked.toString() === '1';
      this.switchWindChange(checked,data.key);
    }
  },
  beforeDestroy() {
    // window.removeEventListener('resize', () => {
    //   this.setViewHeight(getViewPortHeight());
    // });
  },

  methods: {
    ...mapMutations('map', ['setViewHeight']),
    ...mapMutations('typhoon', ['setModelLegend', 'setModelLegendWind',
      'setTimeLineLength']),
    switchChange(value,key) {
      this.setModelLegend({key: key, checked: value});
      this.resetTimeLength();
      modelLegendChange.postMessage(JSON.stringify(this.modelLegend));
      let playObj = {};
      if (key === 'tcvital') {
        playObj = this.observeTyphoonObj;
      } else {
        playObj = this.typhoonLineObj;
      }
      if (!value) {
        // 隐藏所有的key的图
        if(playObj && Object.keys(playObj).length > 0) {
          Object.keys(playObj).forEach(v => {
            if (v.indexOf(key) > -1) {
              playObj[v].deleteTyphoon();
            }
          })
        }
      } else {
        // 重新展示
        if(playObj && Object.keys(playObj).length > 0) {
          Object.keys(playObj).forEach(v => {
            if (v.indexOf(key) > -1) {
              // 如果当前按钮展示风圈，则要绘制风圈，
              let currentPointIndex = -1;
              // 如果隐藏的是观测，就要找到当前时间点的所在索引
              let observeLineIndex = -1;
              const checkObj = this.checkLineChecked(v);
              if (checkObj.windChecked) {
                currentPointIndex = this.timeLineIndex;
                if (key === 'tcvital') {
                  observeLineIndex = this.findObserveLineIndex(v);
                }
              }

              playObj[v].replayForecastLine(observeLineIndex,currentPointIndex,false);
            }
          })
        }
      }
    },

    resetTimeLength() {
      let maxLength = 0;
      Object.keys(this.typhoonLineObj).forEach(v => {
        let checked = true;
        for (let key of Object.keys(this.modelLegend)) {
          if (v.indexOf(key) > -1) {
            checked = this.modelLegend[key].checked;
            break;
          }
        }
        if (checked) {
          const length = this.timeLineLengthObj[v];
          if (length > maxLength) {
            maxLength = length;
            this.setTimeLineLength(length);
          }
        }
      })
      console.log('maxLength==>',maxLength,'timeLineLength==>',this.timeLineLength);
    },

    // 展示风圈按钮
    switchWindChange(value, key) {
      this.setModelLegendWind({key: key, checked: value});
      modelLegendChange.postMessage(JSON.stringify(this.modelLegend));
      let lineObj = {};
      if (key === 'tcvital') {
        // 实况
        lineObj = this.observeTyphoonObj;
      } else {
        lineObj = this.typhoonLineObj;
      }
      if (!value) {
        // 不展示风圈
        if (lineObj && Object.keys(lineObj).length > 0) {
          Object.keys(lineObj).forEach(t => {
            if(t.indexOf(key) > -1) {
              lineObj[t].deleteWindCircle();
            }
          });
        }
      } else {
        // 显示风圈，如果当前线路不显示，仍然不展示风圈
        // 如果是观测的，点位不在时间轴区间上，
        const checkObj = this.checkLineChecked(key);
        if (checkObj.lineChecked) {
          if (lineObj && Object.keys(lineObj).length > 0) {
            Object.keys(lineObj).forEach(t => {
              if(t.indexOf(key) > -1) {
                let index = -1;
                if (key === 'tcvital') {
                  index = this.findObserveLineIndex(t);
                } else {
                  index = this.timeLineIndex;
                }
                console.log('index====>',index,'this.timeLineIndex==>',this.timeLineIndex,'key==>',key);
                lineObj[t].showWindCircle(index);
              }
            });
          }
        }
      }
    },

    checkLineChecked(t) {
      let windChecked = true;
      let lineChecked = true;
      for (let key of Object.keys(this.modelLegend)) {
        if (t.indexOf(key) > -1) {
          windChecked = this.modelLegend[key].windChecked;
          lineChecked = this.modelLegend[key].checked;
        }
      }
      return {lineChecked,windChecked};
    },

    findObserveLineIndex(id) {
      for(let obj of this.ObserveTyphoonList){
        if ('tcvital'+obj.id === id) {
          const data = obj.details;
          if (data && data.length > 0) {
            for (let i = 0; i <= data.length -1; i++) {
              const detail = data[i];
              if (detail.forecastTimeString === this.timeLineDate) {
                return i;
              }
            }
          }
        }
      }
      return -1;
    }
  },
};
</script>
<style lang="scss" scoped>
.legend_loop {
  position: absolute;
  right: 22px;
  bottom: 50px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  background-color: $bg-menu;
  border-radius: 6px;

  .legend {
    cursor: move;
    padding: 0 5px 0 5px;

    .color_wrap {
      display: flex;
      padding: 3px 4px;
      backdrop-filter: blur(1px);
      justify-content: space-between;

      .text {
        color: white;
        font-size: 14px;
        margin: auto;
      }
    }

    .legend_single {
      border-radius: 6px 6px 0 0;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      text-align: left;
      min-width: 24px;

      &.color {
        //text-indent: 5px;
        text-align: center;
        text-shadow: 0 0 2px #000;
      }

      &.black {
        color: #000;
      }

      &.unit {
        background: $bg-color;
        text-align: center;
        color: $text-operate;
      }
    }

    &.legend:first-child {
      padding-top: 5px;
      //border-radius: 6px 6px 0 0;
      //border: 1px solid rgba(0, 0, 0, 0.45);
    }

    &.legend:last-child {
      padding-bottom: 5px;
      //border-radius: 0 0 6px 6px;
      //border: 1px solid rgba(0, 0, 0, 0.45);
    }

    .checkbox {
      zoom: 130%;
    }
  }

  &.mobile {
    width: 100%;
    box-sizing: border-box;
    top: auto;
    bottom: 0;
    left: 0;

    .legend {
      display: flex;
      margin-left: 0;
      width: 100%;

      .color_wrap {
        justify-content: space-around;
        border-radius: 0;
      }

      .unit_wrap {
        width: 60px;
      }

      .legend_single {
        color: #fff;
        text-align: center;
        padding: 0;
        white-space: nowrap;
        min-width: 0;

        &.unit {
          background: #fff;
          color: #333;
          height: 10px;
          line-height: 10px;
          border: none;
          border-radius: 0;
        }

        &.unit:first-child {
          padding-top: 3px;
        }

        &.unit:last-child {
          padding-bottom: 3px;
        }
      }
    }

    .color_wrap {
      display: flex;
      width: calc(100% - 60px);
    }
  }
}

::v-deep{
  .color_wrap {
    /* 未选中时的样式 */
    .el-checkbox.el-checkbox__input.el-checkbox__inner {
      //border: 1px solid #47A87D;
      //background: #fff;
      //padding: 10px 10px;
      //line-height: 100%;
      //margin-right: 10px;
    }
    /* 选中状态时的样式 */
    .el-checkbox.is-checked {
      //background: #EDF6F2;
      //border: 1px solid #47A87D;
      //padding: 10px 10px;
      //border-radius: 4px;
    }
    /* 选中状态、同时设置了disabled为true的复选框的样式（HTML代码中第一个checkbox的disabled设置为了true */
    .el-checkbox.is-disabled.is-checked {
      background: #EDF6F2;
    }
    ///* 选中状态、且disabled设置为true的checkbox的文本的样式 */
    .el-checkbox__input.is-disabled+span.el-checkbox__label {
      color: #47a87d;
      cursor: not-allowed;
    }
    ///* 选中状态、disabled设置为true的复选框，设置其可勾选方框的样式 */
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: #808080;
      border-color: #808080;
    }
  }
}
</style>
