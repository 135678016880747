import Vue from 'vue';
import Vuex from 'vuex';
import map from './map/index';
import typhoon from './typhoon/index';

Vue.use(Vuex);

function isMobileFn() {
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return !!mobile;
}

const mobile = isMobileFn();

export default new Vuex.Store({
  state: {
    factor: 'pratesfc',
    currentFactorData: [],
    currentSlpData: [],
    currentWindData: [],
    currentData: {},
    status: 'pause',
    factorFirstData: [],
    baseTime: [],
    currentBaseTime: {},
    // windy: mobile ? 'none' : 'normal',
    windy: 'none',
    isMobile: mobile,
    layerType: 'graph',
    t1LayerType: 'graph',
    // 城市图层是否展示
    city: 'normal',
    currentTimestamp: '',
    cityColor: '#000000'
  },
  getters: {
    factorDataTimeMap(state) {
      return state.currentFactorData.reduce((target, item) => {
        target[+new Date(item.timestamp)] = item;
        return target;
      }, {});
    },
    firstDataMap(state) {
      return state.factorFirstData.reduce((target, item) => {
        target[item.factorCode] = item;
        item.index = 0;
        return target;
      }, {});
    },
    currentSlp(state) {
      const index = state.currentData.index;
      return state.currentSlpData[index] || null;
    },

    currentWind(state) {
      const index = state.currentData.index;
      return state.currentWindData[index] || null;
    },

    // 当前选择时间
    currentTime(state, getters) {
      return getters.current.forecastTime;
    },
  },
  mutations: {
    setFactor(state, value) {
      state.factor = value;
    },

    setCurrentFactorData(state, { factor, slp, wind }) {
      state.currentFactorData = factor;
      state.currentSlpData = slp;
      state.currentWindData = wind;
    },

    setCurrentData(state, value) {
      state.currentData = value;
    },

    setStatus(state, value) {
      state.status = value;
    },

    setFactorFirstData(state, value) {
      value.forEach((item) => {
        item.index = 0;
        item.getImage = getImage(item.thumbnailPath).then((res) => {
          item.image = res;
          return res;
        });
      });
      state.factorFirstData = value;
    },

    // 设置当前的地形图
    setT1LayerType(state, value) {
      state.layerType = value;
      state.t1LayerType = value;
    },

    // 设置城市图层是否展示值
    setT1City(state, value) {
      state.city = value;
    },

    setBaseTime(state, value) {
      state.baseTime = value.map((item) => {
        item.baseTimeModel = `${item.baseTime} ${item.mode === 'early' ? 'DA' : 'ND'}`;
        return item;
      });
    },

    setCurrentBaseTime(state, value) {
      state.currentBaseTime = value;
    },

    setT1Windy(state, value) {
      state.windy = value;
    },

    setCurrentTimestamp(state, value) {
      state.currentTimestamp = value;
    },

    setCityColor(state, value) {
      if (value === 'satellite') {
        state.cityColor = '#FFFFFF';
      } else {
        state.cityColor = '#000000';
      }
    }
  },
  actions: {
  },
  modules: {
    map,
    typhoon
  },
});
