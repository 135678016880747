export const setStore = (name, content) => {
  if (!name) return;
  localStorage.setItem(name, JSON.stringify(content));
}

// 获取localStorage
export const getStore = name => {
  if (!name) return;
  if (!localStorage.getItem(name)) return;
  return JSON.parse(localStorage.getItem(name));
}

export const removeSpace = value => {
  return value.replace(/\s+/g, '');
}

export function formValidate(val, type) {
  const phoneReg = /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/
  const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  if (val === '') {
    return false
  } else {
    // 非空验证
    if (type === 'require') {
      return !!removeSpace(val)
    }
    if (type === 'phone') {
      return phoneReg.test(val)
    }
    if (type === 'email') {
      return emailReg.test(val)
    }
  }
}


// 获取url参数
export function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

// 数组去重
export function dedupe(array) {
  return Array.from(new Set(array))
}

export function uniqueArray(arr, val) {
  const res = new Map()
  return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
}

export function isObjectEmpty(obj) {
  if (!obj) {
    return true
  }
  return JSON.stringify(obj) === '{}'
}

export const ModalHelper = ((bodyCls) => {
  let scrollTop
  return {
    afterOpen: function () {
      scrollTop = document.scrollingElement.scrollTop
      document.body.classList.add(bodyCls)
      document.body.style.top = -scrollTop + 'px'
    },
    beforeClose: function () {
      document.body.classList.remove(bodyCls)
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop
    }
  }
})('dialog-open')

export function computeWindDeg(u, v) {
  let fx = 0
  if (u > 0 & v > 0) {
    fx = 270 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u < 0 & v > 0) {
    fx = 90 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u < 0 & v < 0) {
    fx = 90 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u > 0 & v < 0) {
    fx = 270 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u == 0 & v > 0) {
    fx = 180;
  }
  else if (u == 0 & v < 0) {
    fx = 0;
  }
  else if (u > 0 & v == 0) {
    fx = 270;
  }
  else if (u < 0 & v == 0) {
    fx = 90;
  }
  else if (u == 0 & v == 0) {
    fx = 999.9;
  }
  return fx
}

export function getURLParams(url) {
  const param = url.split('#')[0];           //获取#/之前的字符串
  const paramContent = param.split('?')[1];  //获取?之后的参数字符串
  const paramsArray = paramContent.split('&');    //参数字符串分割为数组
  const paramResult = {};
  //遍历数组，拿到json对象
  paramsArray.forEach((item, index, paramsArray) => {
    paramResult[paramsArray[index].split('=')[0]] = paramsArray[index].split('=')[1];
  })
  return paramResult;
}
