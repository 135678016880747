export const metrics = [
  // {
  //   id: 'wgrd10m',
  //   factor: 'wind',
  //   icon: 'wind-field',
  //   text: '10米风场',
  // },

  {
    id: 'pratesfc',
    // factor: 'wind',
    icon: 'pratesfc',
    text: '总降水率',
  },

  {
    id: 'max_reflectivity',
    // factor: 'wind',
    icon: 'radar',
    text: '明日雷达',
    private: 't2',
  },

  {
    id: 'base_reflectivity',
    // factor: 'wind',
    icon: 'base_ref',
    text: '基本反射率',
    private: 't2',
  },

  {
    id: 'wgrd10m',
    icon: 'wgrd10m',
    text: '10米风场',
  },

  {
    id: 'w100m',
    icon: 'wind-wheel',
    text: '100米风场',
  },
  // {
  //   id: 'pratesfc',
  //   icon: 'pratesfc',
  //   text: '总降水率',
  // },
  {
    id: 'tmp2m',
    factor: 'temp',
    icon: 'temperature',
    text: '气温',
    private: 't1',
  },

  {
    id: 't2mz',
    factor: 'temp',
    icon: 'temperature',
    text: '气温',
    private: 't2',
  },
  // {
  //   id: 'w200m',
  //   icon: '200pa',
  //   text: '200米风场',
  // },
  {
    id: 'ri_min',
    icon: 'airport',
    text: '晴空颠簸指数',
  },

  // {
  //   id: 'slp',
  //   icon: 'slp',
  //   text: '海平面气压',
  // },

  {
    id: 'prerrt',
    icon: 'prerrt',
    text: '降雨',
  },
  {
    id: 'pregrt',
    icon: 'pregrt',
    text: '霰降水',
  },
  {
    id: 'preirt',
    icon: 'preirt',
    text: '降冰',
  },
  {
    id: 'presrt',
    icon: 'presrt',
    text: '降雪',
  },
  // {
  //   id: 'prert',
  //   icon: 'prert',
  //   text: '实时降水',
  // },
  {
    id: 'prer',
    icon: 'rain',
    text: '累计降雨',
  },
  {
    id: 'prei',
    icon: 'ice',
    text: '累计冰降水',
  },

  {
    id: 'pres',
    icon: 'snow',
    text: '累计降雪',
  },

  {
    id: 'preg',
    icon: 'graupel',
    text: '累计霰降水',
  },
  // {
  //   id: 'preall',
  //   icon: 'preall',
  //   text: '累计降水',
  // },
];

export const t3Metrics = [
  {
    id: 'wind',
    icon: 'wind',
    text: '气压面风速',
  },

  {
    id: 'tmp',
    icon: 'temp',
    text: '气压面温度',
  },
  {
    id: 'pratesfc',
    // factor: 'wind',
    icon: 'pratesfc',
    text: '总降水率',
  },

  {
    id: 'max_reflectivity',
    // factor: 'wind',
    icon: 'radar',
    text: '明日雷达',
    private: 't2',
  },

  {
    id: 'base_reflectivity',
    // factor: 'wind',
    icon: 'base_ref',
    text: '基本反射率',
    private: 't2',
  },

  {
    id: 'wgrd10m',
    icon: 'wgrd10m',
    text: '10米风场',
  },

  {
    id: 'w100m',
    icon: 'wind-wheel',
    text: '100米风场',
  },

  {
    id: 't2mz',
    icon: 'temperature',
    text: '2米温度',
  },

  {
    id: 'tmpsfc',
    icon: 'tmpsfc',
    text: '地表温度',
  },

  {
    id: 'prerrt',
    icon: 'prerrt',
    text: '降雨',
  },
  {
    id: 'pregrt',
    icon: 'pregrt',
    text: '霰降水',
  },
  {
    id: 'preirt',
    icon: 'preirt',
    text: '降冰',
  },
  {
    id: 'presrt',
    icon: 'presrt',
    text: '降雪',
  },

  {
    id: 'prer',
    icon: 'rain',
    text: '累计降雨',
  },
  {
    id: 'prei',
    icon: 'ice',
    text: '累计冰降水',
  },

  {
    id: 'pres',
    icon: 'snow',
    text: '累计降雪',
  },

  {
    id: 'preg',
    icon: 'graupel',
    text: '累计霰降水',
  },
];

export const factorMap = {
  tmp: 'temp',
  wind: 'wind',
  t2mz: 'temp',
  tmp2m: 'temp',
  tmpsfc: 'temp',
  max_reflectivity: 'max_reflectivity',
  base_reflectivity: 'base_reflectivity',
  wgrd10m: 'wind',
  w100m: 'wind',
  w200m: 'wind',
  prer: 'prer',
  pres: 'pres',
  prei: 'prei',
  preg: 'preg',
  slp: 'slp',
  ri_min: 'ri_min',
  pratesfc: 'pratesfc',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: 'snow',
  rh2m: 'rh2m'
};

export const factorNameMap = {
  tmp: '温度',
  wind: '风速',
  t2mz: '温度',
  tmp2m: '温度',
  tmpsfc: '温度',
  max_reflectivity: '雷达',
  base_reflectivity: '雷达',
  wgrd10m: '10米风速',
  w100m: '100米风速',
  w200m: '200米wind',
  prer: '降雨',
  pres: '降雪',
  prei: '降冰',
  preg: '霰降水',
  slp: '气压',
  ri_min: '指数',
  pratesfc: '总降水率',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: '降雪'
}

export const factorFour = {
  preall: 'preall',
  prert: 'prert',
};

export const factorPressure = {
  // prer: 'rain',
  // pres: 'snow',
  // prei: 'ice',
  // preg: 'graupel',
  // preall: 'preall',
  // prert: 'prert',
  // prerrt: 'rain',
  // pregrt: 'graupel',
  // preirt: 'ice',
  // presrt: 'snow',
  // pratesfc: 'pratesfc',
};

export const factorWind = {
  pratesfc: 'wgrd10m',
};

export const HDMap = {
  // wgrd10m: 'wind',
  prer: 'prer',
  pres: 'pres',
  prei: 'prei',
  preg: 'preg',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: 'snow',
  pratesfc: 'pratesfc',
};

export const unitMap = {
  temp(value, plus = 0) {
    return `温度：<br/>${(value + plus).toFixed(2)} °C`;
  },
  wind(value) {
    return `风速：<br/>${value.toFixed(2)} m/s`;
  },
  rain(value) {
    return `降雨：<br/>${value.toFixed(2)} mm/hr`;
  },
  snow(value) {
    return `降雪：<br/>${value.toFixed(2)} mm/hr`;
  },
  ice(value) {
    return `降冰：<br/>${value.toFixed(2)} mm/hr`;
  },
  graupel(value) {
    return `霰降水：<br/>${value.toFixed(2)} mm/hr`;
  },

  prer(value) {
    return `降雨：<br/>${value.toFixed(2)} mm`;
  },
  pres(value) {
    return `降雪：<br/>${value.toFixed(2)} mm`;
  },
  prei(value) {
    return `降冰：<br/>${value.toFixed(2)} mm`;
  },
  preg(value) {
    return `霰降水：<br/>${value.toFixed(2)} mm`;
  },
  ri_min(value) {
    return `指数：<br/>${value.toFixed(2)} n/a`;
  },
  slp(value) {
    return `气压：<br/>${value.toFixed(2)} mb`;
  },
  pratesfc(value) {
    return `总降水率：<br/>${value.toFixed(2)} mm/hr`;
  },
  preall(value) {
    return `
      降雨：${value.rain.toFixed(2)} mm/hr<br />
      降雪：${value.snow.toFixed(2)} mm/hr<br />
      降冰：${value.ice.toFixed(2)} mm/hr<br />
      霰降水：${value.graupel.toFixed(2)} mm/hr<br />
    `;
  },
  prert(value) {
    return `
      降雨：${value.rain.toFixed(2)} mm/hr<br />
      降雪：${value.snow.toFixed(2)} mm/hr<br />
      降冰：${value.ice.toFixed(2)} mm/hr<br />
      霰降水：${value.graupel.toFixed(2)} mm/hr<br />
    `;
  },

  max_reflectivity(value) {
    return `雷达: ${(value < 0 ? 0 : value).toFixed(2)}dBz`;
  },

  base_reflectivity(value) {
    return `雷达: ${(value < 0 ? 0 : value).toFixed(2)}dBz`;
  },
};

export const unit = {
  temp: '℃',
  wind: 'm/s',
  rain: 'mm/hr',
  snow: 'mm/hr',
  ice: 'mm/hr',
  graupel: 'mm/hr',
  prer: 'mm',
  pres: 'mm',
  prei: 'mm',
  preg: 'mm',
  slp: 'mb',
  ri_min: 'n/a',
  pratesfc: 'mm/hr',
  max_reflectivity: 'dBz',
  base_reflectivity: 'dBz',
};

export const modeMap = {
  wgrd10m: 'nextgen',
  w100m: 'nextgen',
  tmp2m: 'nextgen',
  pratesfc: 'nextgen',
  max_reflectivity: 'nextgen',
  base_reflectivity: 'nextgen',
  prerrt: 'nextgen',
  pregrt: 'nextgen',
  preirt: 'nextgen',
  presrt: 'nextgen',
  prer: 'nextgen',
  prei: 'nextgen',
  pres: 'nextgen',
  preg: 'nextgen',
  // rh2m: 'nextgen',
};

export const productionMap = {
  wgrd10m: 'c10km',
  w100m: 'c10km',
  tmp2m: 'c1km',
  // t2mz: 'c1km',
  pratesfc: 'c2_5km',
  max_reflectivity: 'c2_5km',
  base_reflectivity: 'c2_5km',
  // prerrt: 'c10km',
  prerrt: 'c2_5km',
  pregrt: 'c2_5km',
  preirt: 'c2_5km',
  presrt: 'c2_5km',
  prer: 'c2_5km',
  prei: 'c2_5km',
  pres: 'c2_5km',
  preg: 'c2_5km',
  // rh2m: 'c2_km',
};

export default metrics;
