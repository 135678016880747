<template>
  <div class="home">
    <v-map ref="map" />
    <v-base-t-ime-select />
    <v-model-legend />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import VMap from './Map.vue';
import VBaseTImeSelect from './components/BaseTImeSelect.vue';
import VModelLegend from './components/ModelLegend.vue';

export default {
  name: 'SatelliteCloud',
  components: {
    VMap,
    VBaseTImeSelect,
    VModelLegend
  },
  computed: {
    ...mapState('typhoon', ['qbTime','typhoonObj', 'tableListData', 'beginDateTime',
      'timeLineData']),
  },
  created() {
    this.init();
  },
  mounted() {
    // this.setScreenSize({
    //   width: document.documentElement.clientWidth,
    //   height: document.documentElement.clientHeight,
    // });
    // window.onresize = throttle(() => {
    //   this.setScreenSize({
    //     width: document.documentElement.clientWidth,
    //     height: document.documentElement.clientHeight,
    //   });
    // }, 200);
  },
  methods: {
    ...mapMutations('typhoon', ['setTyphoonListData','setQbTime', 'setTyphoonObj','setCurrentDateTime',
      'setStatus', 'setIsPause', 'setIndex', 'setIsMorePause', 'setIsMorePlay', 'setTimeLineDataInfo',
      'setObserveTyphoonList','setIfsTyphoonList', 'setEpsTyphoonList', 'setGfsTyphoonList',
      'setForecastTyphoonListData', 'setInitForecastTyphoonObj']),
    ...mapMutations('map', ['setLayerType']),
    async init() {
      // this.setLayerType('shadow');
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
