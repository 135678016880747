import axios from 'axios';

export const httpIso = axios.create({
  baseURL: '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const getIso = (url) => httpIso({
  url,
});
