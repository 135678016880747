import axios from 'axios';

const http = axios.create({
  baseURL: '/',
  responseType: 'blob',
});

/**
 * @description 根据path获取图片的二进制流
 * @example image:wgrd10m:2021031812:2021031813.webp
 * @returns {Promise}
 * @author yujie
 * @param windId
 * @param callback
 */
export const getImage = (url, callback = () => {}) => http.get(url, {
  cancelToken: new axios.CancelToken(callback),
}).then(({ status, data }) => {
  if (status !== 200) return Promise.reject('fail');
  return window.URL.createObjectURL(data);
});

const http1 = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_SATELLITE,
  responseType: 'blob',
});

/**
 * @description 根据path获取卫星图片的二进制流
 * @param {String} imagePath 图片路径
 * @param {Number} quality 图片质量
 * @param callback
 * @example image:sat:forecast:2021060712:2021060713.webp
 * @returns {Promise}
 * @author caomf
 */
export const getSatelliteImage = (imagePath, quality, callback = () => {}) => http1.get('/arxiv/file/satellite', {
  params: { imagePath, quality },
  cancelToken: new axios.CancelToken(callback),
}).then(({ status, data }) => {
  if (status !== 200) return Promise.reject('fail');
  return window.URL.createObjectURL(data);
});
