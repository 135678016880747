<template>
  <div
      id="map"
      ref="map"
  />
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import L from 'leaflet';
import _ from 'lodash';
import Bus from '@/bus/index';
import '@/plugins/GrayScale/GrayScale';
import { TIANDITU_KEY, TENCENT_KEY } from '@/config';
import { productionMap, modeMap } from '@/config/MapConfig';
import {
  factorMap, factorWind, geoserverUrl, factorUnitMap, factorAppNameMap, factorAppMap
} from '@/config/MapConfigT4';
// import GLOperations from '@/plugins/GLOperations';
import { getImage } from '@/api/image_hd';
import { getPointFactorValue } from '@/api';
import { getIso } from '@/api/iso';
import { Isoline } from '@/plugins/canvas.isoline';
import { getImage as getImageT3 } from '@/api/image_t3';
import ParseWind from '@/views/map/ParseWind';
import 'leaflet.tilelayer.colorfilter';
import locateIcon from '@/assets/images/dot.png';
import '@/plugins/Leaflet.VectorGrid';
import 'leaflet-velocity/dist/leaflet-velocity.css';
import { cityLayerFeature } from '@/plugins/CityLayer'
import '@/plugins/LeafletVelocity/L.VelocityLayer';
import { getURLParams,setStore } from '@/utils/util';

export default {
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 地图实例
      map: null,
      // 弹出框实例
      popup: null,
      // 当前可视区域的范围
      bounds: null,
      // 辅助播放，记录当前时间
      time: Date.now(),
      // 延时器id
      timer: 0,
      // 当需要重新渲染风场时，是否更新的标志
      update: true,
      // 当需要重新渲染海平面气压图层时，是否更新的标志
      slpUpdate: true,

      show: true,
      loadMapLayerFactors: ['wgrd10m', 'w100m', 't2mz', 'rh2m', 'tmp2m'],

      // 当前位置的维度
      currentLatitude: 35,

      // 当前位置的经度
      currentLogitude: 118,

      // 要素值
      factorValue: 0,

      // 记录当前位置
      locationMarker: null,

      // 记录搜索位置的经纬度
      searchPosition: null,

      wind : '',
      isoline: '0',
      overlay: '',
      product: '',
      lon: '',
      lat: '',
      isCity: '0',
      baseLayer: '',
      lat_: '',
      lng_: ''
    };
  },
  computed: {
    ...mapState('map', ['factor', 'index', 'status', 'currentBaseTime', 'windy', 'city', 'isobar', 'region', 'elevation',
      'worldLayer', 'shadowLayer', 'layerType', 'hd', 'drag', 'init_Map', 'timeLineReStart', 'heatmapLoaded','isCurrent']),
    ...mapState(['cityColor']),
    ...mapGetters('map', ['currentData', 'current', 'timeList', 'currentTime', 'windMeta',
      'currentWindLayerDetails', 'currentLayerDetails', 'currentWindyId']),
    // 当前要素对应的key
    factorKey() {
      return factorMap[this.factor];
    },
    // SLP瓦片参数所需要的图层名称
    slpLayerName() {
      const majorFactorName = this.current?.storeName ?? '';
      if (majorFactorName !== '') {
        const layerNameSuffix = majorFactorName.substr(majorFactorName.indexOf('_20'));
        return `hpc:slp${layerNameSuffix}`;
      }
      return 'hpc:';
    },
    // 当前要素加载的瓦片图层地址
    layerUrl() {
      const {
        production, baseTimeString, mode, region, factorCode, factorType,
      } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      // main/tiles/c2_5km/nextgen/global/2024071208/2024071218/pratesfc
      // const url =  `/${factorType}/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factorCode}/{z}/{x}/{-y}.png`;
      // // const url = `/${factorType}/tiles/${production}/${mode}/${region}/${baseTimeString}/2024052419/${factorCode}/{z}/{x}/{-y}.png`;
      // console.log( `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factorCode}/{z}/{x}/{-y}.png`);
      // return url;
      // return `/main/tiles/c2_5km/nextgen/global/2024071208/2024071218/pratesfc/{z}/{x}/{-y}.png`;
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factorCode}/{z}/{x}/{-y}.png`;
    },
    nextLayerUrl() {
      const {
        production, baseTimeString, mode, region, factorCode, factorType,
      } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      const nextForecastTimeString = moment(forecastTimeString, 'YYYYMMDDHH').add(1, 'hour').format('YYYYMMDDHH');
      // return `/${factorType}/tiles/${production}/${mode}/${region}/${baseTimeString}/${nextForecastTimeString}/${factorCode}/{z}/{x}/{-y}.png`;
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${nextForecastTimeString}/${factorCode}/{z}/{x}/{-y}.png`;
    },
    windyUrl() {
      const {
        production, baseTimeString, mode, region,
      } = this.currentLayerDetails;
      if (production) {
        const { forecastTimeString } = this.current;
        console.log('production',production,'mode',mode, 'factor',this.factor);
        // return `/gwind/${production}/${mode}/${region}/${baseTimeString}/wgrd10m/${forecastTimeString}.webp`;
        return `/gwind/c10km/nextgen/global/${baseTimeString}/wgrd10m/${forecastTimeString}.webp`;
      } else {
        return null;
      }
    },
    // 渲染 地面要素等压线
    slpUrl() {
      const { baseTimeString } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      return `/arxiv/json/t2/early/global/${baseTimeString}/slp/${forecastTimeString}/${forecastTimeString}_d.json`;
    },

    startDate() {
      if (this.product === 't2_da') {
        return new Date('2022-10-13').getTime() / 1000;
      } if (this.product === 't1_da') {
        return moment(this.currentData.timestamp).format('YYYY-MM-DD');
      }
      return '';
    },
    timeMap() {
      return this.timeList.reduce((target, item, index) => {
        target[item.forecastTime] = index;
        return target;
      }, {});
    },
  },

  watch: {
    // 监听起报时间，起报时间变化，清除图层
    currentBaseTime() {
      // 先弄到起报时间，然后定位到当前
      this.clearLayer();
    },
    // 监听时间 或者 起报时间 或者 要素变化，重新渲染图层
    current() {
      console.log('this.current==>',this.current,'this.isCurrent==>',this.isCurrent);
      if (this.isCurrent) {
        this.render();
        if (this.isoline === '1' && this.drag === 'normal') {
          this.renderIsobar();
        }
      }
    },
    // 监听城市图层控制标识变化
    city(value) {
      if (value === 'none') {
        this.clearLayer('city');
      } else {
        this.map.addLayer(this.cityLayer);
      }
    },
    drag(value) {
      if (value === 'normal') {
        this.renderIsobar();
        this.renderWindAction();
      } else {
        this.clearLayer('wind');
        this.clearLayer('isobar');
      }
    },
    // 监听动态风控制变量变化
    windy(value) {
      if (value === 'none') {
        this.clearLayer('wind');
      } else if (this.image) {
        this.generateWindLayer(this.image);
      } else {
        this.renderWind();
      }
    },
    // 监听等压线控制变量变化
    isobar(value) {
      if (value === 'none') {
        this.clearLayer('isobar');
      } else {
        this.renderIsobar();
      }
    },
    timeList() {
      this.loadTimeLine();
    },
    cityColor() {
      this.changeCityLayer();
    },
    // timeLineReStart(value) {
    //   if (!value) {
    //     this.clearLayer('map');
    //   }
    // }
  },

  created() {
    const { wind,isoline,overlay,product,lon,lat,isCity,baseLayer} = getURLParams(window.location.href);
    this.wind = wind;
    this.isoline = isoline;
    this.overlay = overlay;
    this.product = product;
    this.lon = lon;
    this.lat = lat;
    this.isCity = isCity;
    this.baseLayer = baseLayer;
    setStore('baseLayer',baseLayer);
    this.setFactor(factorAppMap[overlay]);

    Bus.$on('playNextTime', this.playNextTime);

    // Bus.$on('zoomIn', this.zoomIn);
    // Bus.$on('zoomOut', this.zoomOut);
    // Bus.$on('clearTimeout', this.clearTimeout);
    // Bus.$on('clearLayer', this.clearLayer);
    // Bus.$on('removePopup', this.removePopup);
    // Bus.$on('render', this.render);
    // Bus.$on('renderElevation', this.render);
  },

  mounted() {
    this.initMap();
    // this.changeBaseLayer(this.baseLayer)

    // 定位到用户当前位置
    // this.map.locate();
    // this.toLocationMarker();

    // this.map.setView([39.90, 116.41],5);

    window.toLocation = (e) => {
      this.toLocationPoint(e);
    }
    window.changeAppBaseLayer = (e) => {
      this.changeBaseLayer(e);
    }
    window.changeIsoline = (e) => {
      this.setIsobar(e.toString() === '1' ? 'normal' : 'none');
    }
    window.zoomIn = () => {
      this.zoomIn();
    }
    window.zoomOut = () => {
      this.zoomOut();
    }
    const debounce = _.debounce(this.goToFrame, 200);
    window.motionPlay = (e) => {
      this.changeIsCurrent();
      debounce(e);
    }
    //
    window.setCollectStatus = (e) => {
      this.setCollectStatus(e);
    }
    window.playerPlay = () => {
      // 点击播放
      this.removePopup();
      this.setDragStatue('none');
    }
    window.playerPause = () => {
      // 播放停止，如果存在风粒子和压力线，要展示出来停止时刻的
      this.setDragStatue('normal');
    }
    window.playerDragStart = () => {
      this.removePopup();
      this.setDragStatue('none');
    }

    window.playerDragEnd = () => {
      this.setDragStatue('normal');
    }
  },

  beforeUnmount() {
    Bus.$off('playNextTime', this.playNextTime);
    // console.log('*******');
    // Bus.$off('zoomIn', this.zoomIn);
    // Bus.$off('zoomOut', this.zoomOut);
    // Bus.$off('clearTimeout', this.clearTimeout);
    // Bus.$off('clearLayer', this.clearLayer);
    // Bus.$off('removePopup', this.removePopup);
    // Bus.$off('render', this.render);go-detail
    // Bus.$on('renderElevation', this.render);
    // this.$refs.map.removeEventListener('click', this.handlerOmap);
    // this.removeEvent();
  },

  methods: {
    ...mapMutations('map', ['setBounds', 'setIndex','setStatus', 'setFactor', 'setMapLayer', 'setShadowLayer',
      'setWindy', 'setFactorName','setLayerType','setIsobar', 'setDrag','setHeatMapLoaded','setIsCurrent']),
    ...mapMutations(['setCityColor']),
    /**
     * @description 初始化地图
     * @returns {void}
     * @author yujie
     */
    initMap() {
      this.map = L.map('map', {
        tapHold: true,
        tapTolerance: 3,
        crs: L.CRS.EPSG4326, // 坐标系--大地坐标系
        center: [this.lat, this.lon], // 坐标中心
        zoomAnimationThreshold: 3,
        // zoomSnap: 0,
        fadeAnimation: !1,
        zoom: 2, // 初始缩放倍数
        minZoom: 2,
        maxZoom: 9,
        maxBounds: [
          [-90, -Infinity],
          [90, Infinity]
          // [-90, -7200],
          // [90, 7200]
        ],
        bounceAtZoomLimits: false,
        detectRetina: true,
        worldCopyJump: !1,
        keyboard: !1,
        // maxBounds: this.t3 ? [[this.region.latMin, this.region.lonMin], [this.region.latMax, this.region.lonMax]] : [[-90, -Infinity], [90, Infinity]], // 区域限制范围
        maxBoundsViscosity: 1,
        attributionControl: false, // 是否去除右下角标志
        zoomControl: false, // 是否显示地图缩放图例
      });

      // 创建不同的窗口(由于zIndex无效)
      this.map.createPane('borderLayerPane');
      // this.map.createPane('provinceLayerPane');
      this.map.createPane('isobarLayerPane');
      this.map.createPane('cityLayerPane');
      this.map.createPane('windLayerPane');
      this.map.createPane('heatmapLayerPane');
      this.map.createPane('mapLayerPane');

      // 设置窗口对应层级
      this.map.getPane('cityLayerPane').style.zIndex = 100;
      // this.map.getPane('provinceLayerPane').style.zIndex = 99;
      this.map.getPane('isobarLayerPane').style.zIndex = 99;
      this.map.getPane('borderLayerPane').style.zIndex = 98;
      this.map.getPane('windLayerPane').style.zIndex = 60;
      this.map.getPane('heatmapLayerPane').style.zIndex = 50;
      this.map.getPane('mapLayerPane').style.zIndex = 30;
      // this.setCityColor(this.baseLayer);
      this.setCurrentBounds();
      this.addLayer();
      this.addEvent();
      this.convertMapByZoom();
    },
    layerChange(e, name) {
      if (name === 'windy') {
        if (this.product === 't1') {
          this.setT1Windy(e ? 'normal' : 'none');
        } else {
          this.setWindy(e ? 'normal' : 'none');
        }
      } else if (name === 'city') {
        if (this.product === 't1') {
          this.setT1City(e ? 'normal' : 'none');
        } else {
          this.setCity(e ? 'normal' : 'none');
        }
      } else if (name === 'compress') {
        this.setHD(e ? 'normal' : 'none');
      }
    },
    convertMapByZoom() {
      this.map.on('zoomend', () => {
        const zoomlevel = this.map.getZoom();
        if (zoomlevel === 9) {
          // 国界线图层 ，城市图层
          // this.cityLayer  this.borderLayer
          if (this.cityLayer) {
            this.map.removeLayer(this.cityLayer);
            this.cityLayer = null;
          }
          // // 国界图层 borderLayerPane
          if (this.borderLayer) {
            this.map.removeLayer(this.borderLayer);
            this.borderLayer = null;
            this.borderLayer1 = L.tileLayer(`https://{s}.tianditu.gov.cn/DataServer?T=cta_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`, {
              zoomOffset: 1,
              subdomains: ['t1', 't2', 't3', 't4', 't5', 't6'],
              pane: 'borderLayerPane',
              opacity: 0.7,
            });
            this.map.addLayer(this.borderLayer1);
          }
        } else {
          if (this.borderLayer1) {
            this.map.removeLayer(this.borderLayer1);
          }
          if (!this.borderLayer && !this.cityLayer) {
            this.addCityAndBorderLayer();
          }
        }
      });
    },

    setMarker(position) {
      this.removeMarker();
      this.locationMarker = L.marker(position, {
        icon: L.icon({
          iconUrl: locationIcon,
          className: 'locatedIcon',
          iconSize: [14, 14],
          iconAnchor: [7, 14],
        }),
        noWrap: true,
      });
      this.locationMarker.addTo(this.map);
      this.map.panTo(position);
    },

    setLocationMarker(position) {
      this.removeMarker();
      const dotIcon = L.icon({
        iconUrl: locateIcon,
        iconSize: [14, 14],
      });
      this.locationMarker = L.marker(position, { icon: dotIcon });
      this.locationMarker.addTo(this.map);
      this.map.panTo(position);
    },

    async setLocation(position,callback) {
      const { lat, lon } = position;
      position = L.latLng([lat, lon]);
      // const pixelValue = this.heatmapLayer.getPixelValue(position);
      // 获取当前点位的预报值
      const pixelValue = await this.getFactorPointValue(lon,lat);
      // this.setMarker(position);
      if (!pixelValue) {
        this.searchPosition = position;
      }
      setTimeout(() => {
        this.setPopUp(position, pixelValue);
        // 修改状态
        this.getCollectStatus(lon, lat);
        if (callback){
          callback();
        }
      }, 100);

    },

    async getFactorPointValue (lon,lat) {
      const {
        production, baseTimeString, mode, region, factorCode,
      } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      // 计算当前时间和起报时间差多少个小时
      const diff = moment(forecastTimeString,'YYYYMMDDHH')
          .diff(moment(baseTimeString,'YYYYMMDDHH'),'minutes');
      const params = {
        'lon': parseFloat(lon).toFixed(2),
        'lat': parseFloat(lat).toFixed(2),
        'mode': mode,
        'production': production,
        'region': region,
        'baseTime': baseTimeString,
        'factorCode': factorCode,
        'duration': '0',
        'timeunit': 'h',
        'beginning': diff/60 - 1
      }
      const factorValue = await getPointFactorValue(params);
      const { forecast } = factorValue;
      if (forecast && forecast.length > 0) {
        const { forecastDetails } = forecast[0];
        if (forecastDetails && forecastDetails.length > 0){
          const value = forecastDetails[0].value;
          if (value && value.length > 0) {
            if (value.length > 1) {
              return value[2].toFixed(2);
            }
            return value[0].toFixed(2);
          }
        }
      }
      return undefined;
    },

    // x y 为当前坐标，min,max 为当前区域
    getClassNameFromPoint(x,y) {
      // 获取当前地图可视区域的范围
      const { min, max } = this.map.getPixelBounds();
      let className = 'custom-popup ';
      console.log('x,y', x,y);
      console.log('min x y', min.x,min.y);
      console.log('max',max.x,max.y);
      if(y - min.y < 140) {
        if(x < min.x + 120) {
          className += 'custom-popup-left-top';
          return className;
        } else if (x > max.x - 120) {
          className += 'custom-popup-right-top';
          return className;
        }
        className += 'custom-popup-top';
        return className;
      }
      // if(x < min.x + 120) {
      //   className += 'custom-popup-left';
      //   return className;
      // } else if (x > max.x - 120) {
      //   className += 'custom-popup-right';
      //   return className;
      // }
      return className;
    },

    setPopUp(position, pixelValue) {
      // 防止地图没有加载完点击
      if (!position.wrap) return;
      const { lat, lng } = position.wrap();
      if (this.status === 'pause' && pixelValue !== undefined) {
        if (this.popup) {
          this.removePopup();
        }
        // 获取当前地图可视区域的范围
        const { min, max } = this.map.getPixelBounds();
        // 获取当前位置的坐标
        const { x, y } = this.map.project(position, this.map.getZoom()).floor();
        // 移动坐标
        this.popup = L.popup({
          className: this.getClassNameFromPoint(x,y),
          closeButton: false,
        });
        const c_Time = moment(this.currentTime).format('YYYY-MM-DD HH:00');

        // 查询当前坐标在Flutter里收藏状态
        const lat_ = lat.toFixed(2);
        const lng_ = lng.toFixed(2);
        const latS = parseFloat(lat_) > 0.0 ? 'N' : 'S';
        const lngS = parseFloat(lng_) > 0.0 ? 'E' : 'W';
        // <span id="popup-collect" class="collect" data-lat="${lat_}"
        //       data-lng="${lng_}"></span></p>

        this.popup.setLatLng(position)
            .setContent(`
          <div class="c-popup-content">
              <div class="popup-content-main">
                 <p id="popup-lat-lng" class="lat-lng"><span>${Math.abs(lng_).toFixed(2)}${lngS}, ${Math.abs(lat_).toFixed(2)}${latS}</span>

                 <p class="title">${factorAppNameMap[this.factorKey]}</p>
                 <p class="txt">${pixelValue} ${factorUnitMap[this.factorKey]}</p>
                 <p class="time">${c_Time}</p>
              </div>
              <div class="popup-content-util">
                  <div id="popup-detail-go" class="go-detail">
                    <span class="goto-detail" data-lat="${lat_}" data-lng="${lng_}"
                        data-key="${this.factor}" data-name="${factorAppNameMap[this.factorKey]}"
                        data-unit="${factorUnitMap[this.factorKey]}"
                        data-value="${pixelValue}">详情</span>
                    <span class="icon-chevron-right go-right goto-detail" data-lat="${lat_}"
                        data-lng="${lng_}"
                        data-key="${this.factor}" data-name="${factorAppNameMap[this.factorKey]}"
                        data-unit="${factorUnitMap[this.factorKey]}"
                        data-value="${pixelValue}"></span>
                  </div>
                  <div id="popup-close" class="custom-popup-close"></div>
              </div>
          </div>
          `)
            .openOn(this.map);

        const popHalfWidth = 117
        const center = this.map.project(this.map.getCenter(), this.map.getZoom());
        if (x - min.x < popHalfWidth) {
          // const latlng = L.CRS.pointToLatLng(L.point(center.x, center.y), this.map.getZoom());
          const latlng = this.map.unproject(L.point(center.x - (popHalfWidth - (x - min.x)), center.y));
          this.map.panTo(latlng);
          // return;
        } else if( max.x - x < popHalfWidth) {
          // const latlng = L.CRS.pointToLatLng(L.point(center.x, center.y), this.map.getZoom());
          const latlng = this.map.unproject(L.point(center.x + (130 - (max.x - x)), center.y));
          this.map.panTo(latlng);
        }
      }
    },

    /**
     * 国界图层
     * @returns {*}
     */
    setCountryBorder() {
      const url = `${geoserverUrl}/line-map/{z}/{x}/{y}.pbf`;
      const borderLayer = L.vectorGrid.protobuf(url, {
        rendererFactory: L.canvas.tile,
        pane: 'borderLayerPane',
        vectorTileLayerStyles: {
          interactive: true,
          world_line(properties) {
            if (properties.contry_sea === 1) {
              return {
                opacity: 0.9,
                fillColor: '#333',
                fillOpacity: 1,
                fill: true,
                color: '#333',
                weight: 1.2,
              };
            }

            return {
              opacity: 0.9,
              fillColor: '#333',
              fillOpacity: 1,
              fill: true,
              color: '#333',
              weight: 0.3,
            };
          },
          world_line_prov(properties) {
            if (properties.country_level === 1) {
              return {
                color: '#1d1d1d',
                weight: 1,
              };
            }
            return {
              color: '#333',
              dashArray: '2, 4',
              weight: 1,
            };
          },
          world_line_city(properties) {
            // 地形图配色
            if (properties.country_level === 3) {
              return {
                color: '#999',
                dashArray: '2, 6',
                weight: 1,
              };
            } if (properties.country_level === 4) {
              return {
                color: '#ccc',
                dashArray: '2, 8',
                weight: 1,
              };
            } if (properties.country_level === 2) {
              return {
                color: '#333',
                dashArray: '2, 4',
                weight: 1.2,
              };
            }
            return {
              color: '#1d1d1d',
              weight: 1,
            };
          },
        },
      });
      return borderLayer;
    },

    /**
     * 构建城市图层
     */
    setCityLayer(type) {
      type = this.layerType === 'shadow' ? true : type;
      const cityLayer = cityLayerFeature('/cities/{z}/{x}/{y}.json', {
        zoomOffset: 1,
        updateInterval: 5000,
        selectColor: type,
      });

      return cityLayer;
    },

    changeCityLayer() {
      this.clearLayer('city');
      this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
      this.map.addLayer(this.cityLayer);
    },

    /**
     * @description 添加图层
     * @returns {void}
     * @author yujie
     */
    addLayer() {
      this.clearLayer('city');
      this.borderLayer = this.setCountryBorder();
      this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
      // 将图层添加到地图上
      this.map
          .addLayer(this.borderLayer)
          .addLayer(this.cityLayer);
      // 从城市维护的温度地图跳过来isCity=1
      // if (this.isCity === '0') {
      //   console.log('this.isCurrent==>',this.isCurrent)
      //   this.changeBaseLayer(this.baseLayer);
      // }
    },

    addCityAndBorderLayer() {
      this.clearLayer('city');
      this.borderLayer = this.setCountryBorder();
      this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
      this.map
          .addLayer(this.borderLayer)
          .addLayer(this.cityLayer);
    },

    /**
     * @description 添加地图事件
     * @returns {void}
     * @author yujie
     */
    addEvent() {
      this.map.on('move', _.debounce(this.moveEvent.bind(this), 50));
      // L.control.scale({
      //   imperial: false,
      //   maxWidth: 70,
      //   position: 'topright'
      // }).addTo(this.map);
      // 添加popup关闭的事件
      this.map.on('popupclose', () => {
        this.searchPosition = null;
      });
      // if (!this.t3) {
      // 定位到用户当前位置
      // const dotIcon = L.icon({
      //   iconUrl: locateIcon,
      //   iconSize: [48, 48],
      // });
      //
      // this.map.on('locationfound', (position) => {
      //   const locationMarker = L.marker([position.latitude, position.longitude], { icon: dotIcon });
      //   locationMarker.addTo(this.map);
      //   this.currentLatitude = position.latitude;
      //   this.currentLogitude = position.longitude;
      // });
      // this.map.on('locationerror', () => {
      //   // 使用腾讯地图定位
      //   this.tencentMapLocation(dotIcon);
      // });
      // }

      this.map.on('click', async ({ latlng, originalEvent }) => {
        if (this.heatmapLayer && !this.heatmapLayer.isLoading()) {
          // const value = this.heatmapLayer.getPixelValue(latlng);
          const { lat, lng } = latlng.wrap();
          const value = await this.getFactorPointValue(lng,lat);
          this.setPopUp(latlng, value, originalEvent);
          this.changePointLocation(latlng, value);
        }
      });

      this.$refs.map.addEventListener('click', this.handlerOmap);

    },

    /**
     * @description 使用腾讯地图进行定位
     * @returns {void}
     * @author yunpengliu
     */
    tencentMapLocation() {
      this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
        key: TENCENT_KEY,
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          this.setLocationMarker([res.result.location.lat, res.result.location.lng]);
        }
      });
    },

    toLocationMarker() {
      this.setLocationMarker([this.lat, this.lon]);
    },

    changePointLocation(latlng, value) {
      // 切换点击点
      const {
        baseTimeString
      } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      const { lat, lng } = latlng.wrap();
      const params = {
        lon: parseFloat(lng).toFixed(2),
        lat: parseFloat(lat).toFixed(2),
        height: 'surface',
        layer: this.factor,
        product: productionMap[this.factor] ?? this.product,
        time: baseTimeString,
        label: factorAppNameMap[this.factorKey],
        unit: factorUnitMap[this.factorKey],
        value: value.toString(),
        current: forecastTimeString
      }
      changeChartOfLocation.postMessage(JSON.stringify(params));
    },
    // 处理地图popup的更多点击事件
    handlerOmap(e) {
      const { dataset, className } = e.target;
      const { lat, lng } = dataset;
      // console.log('className==>',className);
      // e.stopPropagation();
      // if (className.includes('more-factor-data')) {
      //   this.proxy.$eventBus.$emit('pointClick', [lng, lat, name])
      // }
      if (className && className.indexOf('custom-popup-close') > -1) {
        this.removePopup();
        // this.proxy.$eventBus.$emit('hiddenBottom');
      }
      if (className.includes('goto-detail')) {
        const {key, name, unit, value} = dataset
        const {
          production, baseTimeString
        } = this.currentLayerDetails;
        const { forecastTimeString } = this.current;
        console.log('this.forecastTimeString===>',forecastTimeString,'value===',value)
        const params = {
          lat: lat,
          lon: lng,
          height: 'surface',
          layer: key,
          product: productionMap[this.factor] ?? this.product,
          time: baseTimeString,
          label: name,
          unit: unit,
          value: value.toString(),
          current: forecastTimeString
        }
        showChartOfLocation.postMessage(JSON.stringify(params));
      }

      if (className.includes('collect')) {
        // 如果点击是取消
        const params = {
          lat: lat,
          lon: lng,
          collect: true
        }
        if (className.includes('collected')) {
          params.collect = false;
        }
        collectLocation.postMessage(JSON.stringify(params));
      }
    },

    playNextTime() {
      playNext.postMessage(1);
    },

    toLocationPoint(params) {
      const data = JSON.parse(JSON.stringify(params));
      const { lat, lng, zoom,current } = data;
      if (current && current === 'true') {
        if (!zoom) {
          // 点击定位按钮
          this.removePopup();
          this.map.setView([lat, lng], 6);
        }
        this.setLocationMarker([lat, lng]);

      } else {
        // 点击搜索按钮或者成为维护页面点击温度地图跳转过来

        // this.setLocationMarker([lat, lng]);
        // 必选先试图过去，渲染瓦片，然后才location
        const w = this.map.getBounds();
        this.map.panTo([lat, lng]);
        if (w.getWest() <= lng && lng <= w.getEast() && w.getSouth() <= lat && lat <= w.getNorth()) {
          this.setLocation({lat: lat, lon: lng})
        }

        this.heatmapLayer.on('load',() => {
          setTimeout(() => {
            this.setLocation({lat: lat, lon: lng})
            this.heatmapLayer.off('load');
          },100)
        });




        // if (!this.moving) {
        //   this.setLocation({lat: lat, lon: lng});
        // }

        // this.heatmapLayer.on('load', () => {
        //   this.heatmapLayer.off('load')
        //   this.setHeatMapLoaded(true);
        // });
      }

      /*if (name && name !== '') {
        // 搜索名称跳转到当前点，弹出单点框
        this.map.setView([lat, lng],5);
        // this.setLocationMarker([lat, lng]);
        this.setLocation({lat: lat, lon: lng});

      } else {
        // 点击定位的按钮
        this.currentLatitude = lat;
        this.currentLogitude = lng;
        if (!zoom || zoom === 'null' || zoom === null) {
          // 点击定位按钮
          this.removePopup();
          this.map.setView([lat, lng], 5);
        }
        this.setLocationMarker([lat, lng]);
      }*/

    },

    setCollectStatus(params) {
      const data = JSON.parse(JSON.stringify(params));
      const { lat, lng, collect } = data;
      if (collect === true) {
        // 收藏成功
        document.getElementById('popup-collect').classList.add('collected');
      }else {
        // 取消收藏
        document.getElementById('popup-collect').classList.remove('collected');
      }
    },

    // 获取当前点位的收藏状态，如果是收藏，则需要修改图标为已收藏
    getCollectStatus(lon,lat) {
      const params = {
        lon: lon.toString(),
        lat: lat.toString()
      }
      getCollectStatus.postMessage(JSON.stringify(params));
    },

    /**
     * @description 移除地图事件
     * @returns {void}
     * @author yujie
     */
    removeEvent() {
      this.map.off('move');
    },
    /**
     * @description 地图移动事件 缩放 resize事件
     * @returns {void}
     * @author yujie
     */
    moveEvent() {
      this.setCurrentBounds();
      // this.proxy.$eventBus.$emit('pause');
    },
    /**
     * @description 设置当前可视区域的范围
     * @returns {void}
     * @author yujie
     */
    setCurrentBounds() {
      this.bounds = this.map.getBounds();
      this.setBounds(this.timeList);
    },

    /**
     * @description 渲染地图图层
     * @returns {void}
     * @author yujie
     */
    render() {
      this.time = Date.now();
      this.update = true;
      this.slpUpdate = true;

      // const modeMap = {
      //   wgrd10m: 3,
      //   w100m: 3,
      //   tmp2m: 6,
      // };
      const modeMap = {
        // wgrd10m: 3,
        // w100m: 3,
        tmp2m: 6,
        ri_min: 5,
      };
      const maxNativeZoom = modeMap[this.factor] ?? 4

      if (this.heatmapLayer) {
        this.heatmapLayer.options.maxNativeZoom = maxNativeZoom;
        this.heatmapLayer.updateUrl(this.layerUrl,
            () => {});
      } else {
        this.heatmapLayer = new L.TileLayer.WebGL({
          url: this.layerUrl,
          zoomOffset: 1,
          pane: "heatmapLayerPane",
          config: "map",
          maxNativeZoom: 9,
          detectRetina: true,
          updateWhenIdle: !1,
          updateWhenZooming: !1,
          keepBuffer: 3
        });

        // this.heatmapLayer = new L.TileLayer.FrameWebGL({
        //   url: this.layerUrl,
        //   zoomOffset: 1,
        //   pane: "heatmapLayerPane",
        //   config: "map",
        //   maxNativeZoom: 9,
        // });

        // this.heatmapLayer = new L.HeatmapLayer(this.layerUrl, {
        //   zoomOffset: 1,
        //   pane: 'heatmapLayerPane',
        //   // maxNativeZoom: 5,
        //   maxNativeZoom: maxNativeZoom,
        //   detectRetina: true,
        //   updateWhenIdle: !1,
        //   updateWhenZooming: !1,
        //   keepBuffer: 3
        // });

        // this.heatmapLayer = L.tileLayer.frameGL({
        //   fragmentShader: '',
        //   zoomOffset: 1,
        //   pane: 'heatmapLayerPane',
        //   maxNativeZoom: 5,
        //   tileUrls: [this.layerUrl],
        //   detectRetina: true,
        //   updateWhenIdle: !1,
        //   updateWhenZooming: !1,
        //   keepBuffer: 3
        // });

        this.map.addLayer(this.heatmapLayer);
      }
    },

    renderWindAction() {
      if (this.update && this.windy === 'normal') {
        this.renderWind();
      }
      // FIXME 解决每次拖拽都重新请求风场数据的问题
      this.update = false;
    },
    renderGeoLayer() {
      // 地形图
      if (!this.mapLayer) {
        // 创建GeoServer图层
        // this.mapLayer = this.setHillShadeLayer();
        this.renderWindyMapLayer();
        // this.map.addLayer(this.mapLayer);
      }
    },

    setWindyLayer() {
      return L.tileLayer(`https://www.tjweather.com/clear/{z}/{x}/{-y}.png`, {
        zoomOffset: 1,
        maxNativeZoom: 7,
        pane: "mapLayerPane",
      });
    },

    renderWindyMapLayer() {

      if (this.borderLayer){
        this.map.removeLayer(this.borderLayer);
        this.borderLayer = null;
      }

      if (this.mapLayer) {
        this.map.removeLayer(this.mapLayer)
      }
      this.mapLayer = this.setWindyLayer();
      this.map.addLayer(this.mapLayer);
    },

    setHillShadeLayer() {
      const hillshade = L.tileLayer(`${geoserverUrl}/hillshade/{z}/{x}/{y}.png`, {
        zoomOffset: 1,
        maxNativeZoom: 9,
        pane: 'mapLayerPane',
        className: 'hillshade-class-img-map',
      });
      return hillshade;
    },
    renderTianDiMapLayer() {
      // 影像图
      if (!this.mapLayer) {
        // 卫星影像图层
        this.mapLayer = this.setSatelliteLayer();
        this.map.addLayer(this.mapLayer);
      }
    },

    setSatelliteLayer() {
      const satellite = L.tileLayer(`${geoserverUrl}/satellite/{z}/{x}/{y}.png`, { // 链接要改对应的
        zoomOffset: 1,
        maxNativeZoom: 9,
        pane: 'mapLayerPane',
        className: 'satellite-class-img-map',
      });
      return satellite;
    },
    /**
     * @description 获取风场数据，渲染风场
     * @returns {Promise}
     * @author yujie
     */
    async renderWind() {
      if (this.cancel && typeof this.cancel === 'function') this.cancel();
      if (this.windyUrl) {
        const image = !this.t3 ? await getImage(this.windyUrl, (cancel) => {
          this.cancel = cancel;
        }) : await getImageT3(this.windyUrl, (cancel) => {
          this.cancel = cancel;
        });
        this.image = new Image();
        this.image.src = image;
        this.image.addEventListener('load', () => {
          this.generateWindLayer(this.image);
        });
      }
    },
    /**
     * @description 渲染风场
     * @param {Image} image 双通道图片
     * @returns {void}
     * @author yujie
     */
    generateWindLayer(image) {
      const surfaceData = this.windMeta ?? this.current;
      const region = this.region;
      const width = 512;
      const height = 256;
      let solutionX;
      let solutionY;

      if (this.t3) {
        solutionX = (region?.lonMax - region?.lonMin) / width;
        solutionY = (region?.latMax - region?.latMin) / height;
      } else {
        solutionX = 360 / width;
        solutionY = 180 / height;
      }
      const data = {
        width,
        height,
        umin: surfaceData.umin,
        umax: surfaceData.umax,
        vmin: surfaceData.vmin,
        vmax: surfaceData.vmax,
        lonmax: region?.lonMax ?? 180,
        lonmin: region?.lonMin ?? -180,
        latmin: region?.latMax ?? 90,
        latmax: region?.latMin ?? -90,
        origionxv: 0,
        origionyu: 0,
        origionxu: 0,
        origionyv: 0,
        levelu: 0,
        levelv: 1,
        solutionX,
        solutionY,
        image,
      };

      const windOptions = {
        colorScale: [
          "rgb(255,255, 255)"
          // 'rgba(255, 255, 255, 0.2)',
          // 'rgba(255, 255, 255, 0.3)',
          // 'rgba(255, 255, 255, 0.4)',
          // 'rgba(255, 255, 255, 0.5)',
          // 'rgba(255, 255, 255, 0.6)',
          // 'rgba(255, 255, 255, 0.7)',
        ],
      };
      const windData = new ParseWind(data, !this.t3).getData();
      if (this.windLayer) {
        this.windLayer.setData(windData);
      } else {
        this.windLayer = L.velocityLayer(
            Object.assign(windOptions, {
              lineWidth: 1.5,
              data: windData,
              velocityScale: 0.01,
              maxVelocity: 10,
              minVelocity: 1,
              particleMultiplier: 1 / 400,
              frameRate: 20,
              paneName: 'windLayerPane',
            }),
        );
        this.map.addLayer(this.windLayer);
        //   // 由于以下四个事件需要windLayer定义以后才可以注册，所以进行延迟注册
        this.addEventDelay();
      }
    },

    /**
     * @description 延迟注册地图拖动及缩放事件，保证风场图层已加载完毕
     * @returns {void}
     * @author yunpengliu
     */
    addEventDelay() {
      this.map.on('zoomstart', this.clearWind);
      this.map.on('zoom', this.clearWind);
      this.map.on('zoomend', this.clearAndRestartWind);
      this.map.on('movestart', this.clearWind);
      this.map.on('move', this.clearWind);
      this.map.on('moveend', this.clearAndRestartWind);
    },
    /**
     * @description 清除风粒子
     * @returns {void}
     * @author yunpengliu
     */
    clearWind() {
      this.windLayer._clearWind();
    },
    /**
     * @description 清除风粒子并重新渲染
     * @returns {void}
     * @author yunpengliu
     */
    clearAndRestartWind() {
      this.windLayer._clearAndRestart();
    },
    /**
     * @description 移除popup
     * @returns {void}
     * @author yujie
     */
    removePopup() {
      this.popup?.remove();
      // if (this.searchPosition) {
      //   this.searchPosition = null;
      // }
    },
    // 移除地图标记
    removeMarker() {
      this.locationMarker?.remove();
    },

    removeSlp() {
      if (this.slpMapLayerGround) {
        this.map.removeLayer(this.slpMapLayerGround);
      }
      this.slpMapLayerGround = null;
    },

    /**
     * @description 地图缩放等级放大一级
     * @returns {void}
     * @author yujie
     */
    zoomIn() {
      this.map.zoomIn();
    },

    /**
     * @description 地图缩放等级缩小一级
     * @returns {void}
     * @author yujie
     */
    zoomOut() {
      this.map.zoomOut();
    },

    /**
     * @description 手动定位到用户当前所在位置
     * @returns {void}
     * @author yunpengliu
     */
    manualLocation() {
      this.map.flyTo([this.currentLatitude, this.currentLogitude], 7);
    },

    /**
     * @description 暂停时移除定时器
     * @returns {void}
     * @author yujie
     */
    clearTimeout() {
      clearTimeout(this.timer);
    },

    /**
     * @description 跳转到指定帧
     * @returns {void}
     * @author yujie
     */
    goToFrame(e) {
      const {step} = JSON.parse(JSON.stringify(e));
      this.removePopup();
      // this.clearLayer('wind');
      // this.clearLayer('isobar');
      this.setIndex(step);

      // if (this.wind === '1' || this.isoline === '1') {
      //   this.renderIsobar();
      //   this.renderWindAction();
      // }
    },

    setDragStatue(value) {
      this.setDrag(value);
    },

    loadTimeLine() {
      // if(this.isCity === '0') {
      //
      // }
      if (this.timeList && this.timeList.length > 0) {
        const startTime = new Date(this.timeList[0].forecastTime).getTime() / 1000;
        const { baseTimeString, mode } = this.currentLayerDetails;
        const abstract = {
          start: startTime,
          steps: this.timeList.length,
          timeStep: 3600,
          frameTime: 1,
          restart: this.timeLineReStart,
          baseTime: baseTimeString,
          mode: mode
        }
        layerAbstract.postMessage(JSON.stringify(abstract));
      }
    },
    changeBaseLayer(layerName) {
      this.clearLayer('toggleMap');
      if (layerName === 'satellite') {
        this.renderTianDiMapLayer();
      } else {
        this.renderGeoLayer();
      }
      // this.setCityColor(layerName);
      this.baseLayer = layerName;
      this.changeCityLayerColor();
    },

    changeCityLayerColor() {
      if (this.city !== 'none') {
        // 判断字体
        // this.cityLayer = this.setCityLayer();
        // this.map.addLayer(this.cityLayer);
        this.changeCityLayer();
      }
    },



    initReaderIsobar() {
      if (this.isoline){
        return null;
      }
    },

    // 渲染等压线
    renderIsobar() {
      if (this.isoline === '1' || this.isobar === 'normal') {
        if (this.slpUrl) {
          getIso(this.slpUrl).then((res) => {
            const { lineData: data, hlData  } = this.handleSlpData(res.data.features);
            if (!this.slpMapLayerGround) {
              this.slpMapLayerGround = new Isoline({}, { data, hlData}).addTo(this.map);
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      }
    },

    // 延迟注册等压线拖拽事件
    addSlpEventDelay() {
      // this.map.on('zoomstart', this.removeSlp);
      // this.map.on('zoom', this.removeSlp);
      // this.map.on('zoomend', this.renderIsobar);
    },

    handleSlpData(data = []) {
      const slpData = {
        lineData:[],
        hlData:[]
      }
      data.forEach((item) => {
        let temp = []
        const value = item.properties.value;
        const arrLan = item.geometry.coordinates;
        const type = item.geometry.type
        if (type === 'LineString'){
          arrLan.forEach(l=>{
            temp.push([l[1],l[0],value])
          });
          slpData['lineData'].push(temp)
        }else {
          slpData['hlData'].push([arrLan[0][1],arrLan[0][0],item.properties.HL,value]);
        }
      });
      return slpData;
    },

    changeIsCurrent() {
      if (!this.isCurrent) {
        this.setIsCurrent(true);
      }
    },

    clearALlLayer() {
      this.removePopup();
      this.clearLayer();
    },

    /**
     * @description 移除地图图层
     * @returns {void}
     * @author yujie
     */
    clearLayer(type) {
      if (!type || type === "map") {
        if (this.heatmapLayer) this.map.removeLayer(this.heatmapLayer);
        this.heatmapLayer = null;
      }

      if (!type || type === "wind") {
        this.map.off("zoomstart", this.clearWind);
        this.map.off("zoom", this.clearWind);
        this.map.off("zoomend", this.clearAndRestartWind);
        this.map.off("movestart", this.clearWind);
        this.map.off("move", this.clearWind);
        this.map.off("moveend", this.clearAndRestartWind);

        if (this.windLayer) this.map.removeLayer(this.windLayer);
        this.windLayer = null;
        // FIX 修改有时不展示风场的问题
        this.image = null;
      }

      if (type === "city") {
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
      }

      // change 地形图 还是天地图
      if (type === "toggleMap") {
        if (this.borderLayer) this.map.removeLayer(this.borderLayer);
        this.borderLayer = null;
      }

      if (!type || type === "isobar") {
        if (this.isobarLayer) this.map.removeLayer(this.isobarLayer);
        this.isobarLayer = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

#factor-tooltip {
  display: none;
  background: #c8c8c8;
  color: #333;
  opacity: 0.7;
  border-radius: 5px;
  font-family: "微软雅黑", sans-serif;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 20px;
}

::v-deep {
  .leaflet-left {
    bottom: 45px;

    .leaflet-control-scale {
      margin-left: 5px;
    }
  }

  .leaflet-right {
    .leaflet-control-scale {
      margin-right: 22px;
    }
  }

  .leaflet-popup-content-wrapper {
    position: absolute;
    left: -110px;
    top: -114px;
    width: 230px;
    height: 116px;
    background-color: #262626;
    border-radius: 15px;
    padding: 15px;
    cursor: pointer;
    color: #333;
    box-shadow: 0 3px 14px rgb(0 0 0 / 40%);

    .leaflet-popup-content {
      width: 210px;
      height: 100%;
      margin: 0;
      padding: 0;
      color: #fff;

      .c-popup-content {
        height: 100%;
        display: flex;
        justify-content: space-between;

        .popup-content-main {
          height: 100%;
          flex: 1 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;


          >p {
            font-family: 'PingFang SC';
            display: flex;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            align-items: center;
            color: #FAFAFA;
            margin: 0;

            .collect {
              width: 16px;
              height: 16px;
              margin: 0 0 0 10px;
              background-image: url(../../assets/images/collect.png);
              background-size: 100% 99%;
              background-repeat: no-repeat;
              background-position: center center;
              cursor: pointer;
            }

            .collected {
              background-image: url(../../assets/images/collected.png);
            }

            &.title {
              color: #EEEEEE;
            }

            &.time {
              color: #EEEEEE;
            }

            &.txt {
              font-weight: 800;
              font-size: 16px;
              color: #FFFFFF;
            }

            > span {
              display: inline-block;
              vertical-align: middle;

              :first-child {
                padding-top: 3px;
              }
            }
          }
        }

        .popup-content-util {
          height: 100%;
          flex: 0 0 38px;
          margin-left: 5px;
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-end;
          justify-content: space-between;

          .go-detail {
            white-space: nowrap;
            cursor: pointer;
            line-height: 1;

            >span {
              font-style: normal;
              font-weight: 800;
              font-size: 0.8rem;
              line-height: 1;
              color: #0D7FEF;

              &.go-right {
                font-weight: 700;
              }

              &.goto-right {
                cursor: pointer;
              }

              &.icon-chevron-right {
                &::before {
                  font-family: "iconfont";
                  content: "\e658";
                  color: #0d7fef;
                }
              }
            }
          }

          #popup-close {
            display: inline-block;
            width: 22px;
            height: 22px;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: left top;
            background-size: 16px 16px;
            background-image: url(../../assets/images/close.png);
          }
        }
      }


    }
  }



  .leaflet-popup-tip-container {
    top: 2px;

    .leaflet-popup-tip {
      width: 0;
      height: 0;
      background-color: transparent;
      box-shadow: none;
      transform: rotate(0);
      border-width: 20px;
      border-style: solid;
      border-color: #262626 transparent transparent transparent;
    }
  }

  .custom-popup-top {

    .leaflet-popup-content-wrapper {
      top: 20px;
    }

    .leaflet-popup-tip-container {
      top: 2px;
    }

    .leaflet-popup-tip {
      border-color: transparent transparent #262626 transparent;
    }
  }
  .custom-popup-left {

    .leaflet-popup-content-wrapper {
      left: 10px;
      top: -38px;
    }

    .leaflet-popup-tip-container {
      left: 0;
    }

    .leaflet-popup-tip {
      border-color: transparent #262626 transparent transparent;
    }
  }
  .custom-popup-left-top {

    .leaflet-popup-content-wrapper {
      left: 10px;
      top: -10px;
      border-radius: 8px;
    }

    .leaflet-popup-tip-container {
      left: 0;
      top: 4px;
    }

    .leaflet-popup-tip {
      border-color: transparent #262626 transparent transparent;
    }
  }
  .custom-popup-right {

    .leaflet-popup-content-wrapper {
      left: auto;
      top: -38px;
      right: 10px;
    }

    .leaflet-popup-tip-container {
      left: 0;
    }

    .leaflet-popup-tip {
      border-color: transparent transparent transparent #262626;
    }
  }
  .custom-popup-right-top {

    .leaflet-popup-content-wrapper {
      left: auto;
      top: 0;
      right: 8px;
      border-radius: 8px;
    }

    .leaflet-popup-tip-container {
      top: 4px;
      left: 0;
    }

    .leaflet-popup-tip {
      border-color: transparent transparent transparent #262626;
    }
  }

  .more-factor-data {
    color: #0078a8;
    cursor: pointer;
  }
}

.locatedIcon {
  margin-top: -10px !important;
}

</style>

<style lang="scss">
.leaflet-city-lable {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
  position: absolute !important;
  font-size: 0.8rem;
  color: rgb(255,255,255);
  text-align: left;
  pointer-events: auto;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  z-index: 999;
  font-weight: 500;
}

.leaflet-city-lable_white {
  @extend .leaflet-city-lable;
  color: rgb(255,255,255) !important;
}

.leaflet-city-lable:hover{
  text-decoration: underline;
  position: absolute;
  left: 50%;
  bottom: -2px;
  width: 0;
  height: 2px;
}
</style>
