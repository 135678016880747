// import { createRouter, createWebHistory } from 'vue-router'
import Vue from 'vue';
import VueRouter from 'vue-router';
import VHome from '@/views/index'
import Typhoon from '@/views/typhoon/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: VHome
  },
  {
    path: '/typhoon',
    component: Typhoon,
  },
]

export default new VueRouter({
  mode: 'history',
  // base: '/app_v1/',
  base: '/app/',
  routes,
});
