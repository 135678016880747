import { TIANDITU_KEY } from '@/config';
import { httpHD as http } from './http';

/**
 * @description 获取风场距离当前时间最近的数据
 * @returns {Promise}
 * @author yujie
 */
export const getCurrentWind = () => http.get('/factor/3d/wind');

/**
 * @description 根据关键字获取天地图位置
 * @returns {Promise}
 * @author guoqb
 */
export const getLocation = (keyWord) => http.get('https://api.tianditu.gov.cn/geocoder', {
  params: {
    ds: { keyWord },
    tk: TIANDITU_KEY,
  },
});

/**
 * @description 根据坐标获取天地图位置
 * @returns {Promise}
 * @author guoqb
 */
export const getLocationName = (posObj) => http.get('https://api.tianditu.gov.cn/geocoder', {
  params: {
    postStr: posObj,
    type: 'geocode',
    tk: TIANDITU_KEY,
  },
});

/**
 * @description 瓦片通过factor获取数据
 * @param {String} factorCode 要素code
 * @returns {Promise}
 * @author yujie
 */
export const getDataByFactor = (factorCode, production = 't2') =>
  http.get('/main/factor/forecast-available', { params: { factorCode, production, page: 0, size: 1, clientType: 'app'} });


/**
 * @description 通过坐标点获取点的要素值
 * @param {} params 查询参数
 * @returns {Promise}
 * @author yujie
 */
export const getPointFactorValue = (params) => http.get('/spas/single-point/query', { params: params });

