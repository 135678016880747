import axios from 'axios';
import store from '../store/index.js'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const httpHD = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_HD,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});


/**
 * @description 接口调用成功的拦截器
 * @param {Object} response axios封装的接口返回值
 * @returns {Object} 处理后的返回值
 * @author yujie
 */
const success = (response) => {
  const { data } = response;
  // TODO 登录校验
  if (data.code === 200) {
    return data.data;
  }

  if (data.status === '0' || JSON.stringify(data) === '{}' || data.status === '404') {
    return data;
  }

  return Promise.reject(data);
};

/**
 * @description 接口调用失败的拦截器
 * @param {Object} error 错误信息
 * @returns {Object}
 * @author yujie
 */
const failed = (error) => {
  if (error.message.includes('timeout')) {
    return error;
  }
  // Message({
  //   type: 'error',
  //   message: '服务器出错，请联系管理员',
  // });
  // TODO 不同错误码的验证
  return error;
};

http.interceptors.response.use(success, failed);
httpHD.interceptors.response.use(success, failed);


export { http,httpHD };
