<template>
  <div class="home">
    <v-map ref="map" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { getDataByFactor } from '@/api/index';
import { factorMap, metrics, factorAppMap } from '@/config/MapConfigT4';
import { productionMap } from '@/config/MapConfig';
import { getURLParams } from '@/utils/util';
import VMap from './Map.vue';


export default {
  name: 'Home',
  components: {
    VMap
  },
  data() {
    return {
      showSetting: false,
      metrics,
      wind : '',
      isoline: '0',
      overlay: '',
      isCity: '0',
      isWind: false
    };
  },
  computed: {
    ...mapState('map', ['factor', 'windy', 'isobar', 'currentBaseTime','windData']),
    ...mapState(['isMobile']),
    ...mapGetters('map', ['baseTime', 'currentTime', 'elevationList']),
    // 当前要素对应的key
    factorKey() {
      return factorMap[this.factor];
    },
  },
  created() {
    const { wind,isoline,overlay,isCity } = getURLParams(window.location.href)
    this.wind = wind;
    this.isoline = isoline;
    this.overlay = overlay;
    this.isCity = isCity;
  },
  mounted() {
    this.init(this.overlay);
    // this.changeWindy(this.wind);
    window.changeOverlay = (e) => {
      this.changeFactor(e);
    }
    window.changeParticleAnimator = (e) => {
      this.changeWindy(e);
    }
    window.changeCurrentProduct = (e) => {
      this.init(this.overlay,e);
    }

  },
  methods: {
    ...mapMutations('map', ['setFactorData', 'setCurrentBaseTime', 'setStatus', 'setFactor', 'setWindData', 'setIndex',
      'setWindy', 'setIsobar', 'setTimeLineRestart']),
    /**
     * @description 根据要素获取预测数据
     * @param {Boolean} flag  默认为false 控制是否为首次渲染
     * @returns {Promise}
     * @author yujie
     */
    async init(overlay,flag = false) {
      console.log('this.factor====>',this.factor)
      const data = await getDataByFactor(this.factor, productionMap[this.factor] ?? 't2');
      console.log('data',data);
      if (!data || data.length == 0) {
        // this.$message('当前要素无预测数据');
        console.log('当前要素无预测数据');
        return;
      }

      // if (this.wind === '1') {
      //   const windData = await getDataByFactor('wgrd10m');
      //   // console.log('windData==>',windData)
      //   console.log('---------')
      //   this.setWindData(windData);
      //   this.changeWindy(this.wind);
      // }
      // this.changeWindy(this.wind);

      this.setFactorData(data);
      if (!flag && data.length > 0) {
        this.setCurrentBaseTime(data[0].baseTimeModel);
        // this.setIndex(0);
      }
    },
    async changeFactor(overlay) {
      const factorAppName = factorAppMap[overlay];
      // this.setTimeLineRestart(false);
      //   // 删除上一个要素图层
      //   this.init(overlay, false);
      if (this.factor !== factorAppName) {
        // 触发城市名称颜色更改
        this.setFactor(factorAppName);
        // this.$refs.map.changeCityLayerColor();
        this.$refs.map.clearALlLayer();
        this.setTimeLineRestart(false);
        // 删除上一个要素图层
        this.init(overlay, false);
      }
    },
    async changeWindy(wind) {
      if (wind) {
        const isWindy = wind.toString() === '1';
        if (isWindy) {
          if (!this.windData || this.windData.length === 0){
            const windData = await getDataByFactor('wgrd10m');
            this.setWindData(windData);
          }
        }
        this.setWindy(isWindy ? 'normal' : 'none');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
