import L, { Util } from 'leaflet';
import { getJsonData } from '@/api/cityJson';

const ContourLayer = L.GridLayer.extend({
  initialize(url, options) {
    L.Util.setOptions(this, options);
    this._url = url;
    this._selectColor = options.selectColor || false;
  },
  _animateZoom() {
    this._removeAllTiles();
  },
  createTile(coords, done) {
    const tile = L.DomUtil.create('div', 'tile-hoverable');
    tile.style.pointerEvents = 'initial';
    let childStr = '';
    const className = this._selectColor ? 'leaflet-city-lable-white' : 'leaflet-city-lable-dark';
    console.log('className==',className)
    this.fetchData(coords).then((res) => {
      const features = res.data;
      features.forEach((item) => {
        const top = item[7] - parseInt(item[5] / 2);
        const left = item[8] - parseInt(item[6] / 2);
        const child = `<div class="${className}" style="transform: translate(${top}px,${left}px)">${item[1]}
        </div>`;
        childStr += child;
      });
      tile.innerHTML = childStr;
      done(null, tile);
    }).catch(() => {
      done(null, tile);
    });
    return tile;
  },
  getTileUrl(coords) {
    const size = this.getTileSize();
    const nwPoint = coords.scaleBy(size);
    const latLng = this._map.unproject(nwPoint, coords.z);
    // // 定位瓦片坐标
    const resolution_factor = 360 / 256;
    const res = resolution_factor / 2 ** (coords.z + 1);
    const px = (180 + latLng.lng) / res;
    const py = (90 + latLng.lat) / res;
    // 反推json 切片坐标
    const tx = parseInt(Math.ceil(px / 256));
    const ty = parseInt(Math.ceil(py / 256) - 1);
    // 根据经纬度定位瓦片
    const data = {
      r: '',
      s: '',
      x: tx,
      y: ty,
      z: coords.z + this.options.zoomOffset,
    };
    return Util.template(this._url, Util.extend(data, this.options));
  },
  fetchData(coords) {
    const url = this.getTileUrl(this._wrapCoords(coords));
    return new Promise((resolve, reject) => {
      getJsonData(url).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  },
});

export const cityLayerFeature = (url, options) => new ContourLayer(url, options);
