/**
 * @description 天地图key
 * @type {String}
 */
// 1d109683f4d84198e37a38c442d68311 超图
// f00f9a8baec73f6c9550984464574522 星图
// a43290983c30ca0a258d8d3186f94470 刘云鹏
// 5aef953c1064698d5af6264c362aa8ac 于杰
export const TIANDITU_KEY = '1d109683f4d84198e37a38c442d68311';

// 腾讯地图获取位置的key
export const TENCENT_KEY = 'UMKBZ-SSVK2-TSMUO-CIJ3D-FE4F6-2KFSI';
