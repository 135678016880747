<template>
  <div class="app-wrapper">
    <v-home />
  </div>
</template>

<script>
import VHome from './home/index'

export default {
  name: "index",
  components: {
    VHome
  },
}
</script>

<style scoped lang="scss">
.app-wrapper{
  width: 100%;
  height: 100%;
}
</style>
