import L from 'leaflet';
import t01 from '@/assets/images/t-01.png';
import t02 from '@/assets/images/t-02.png';
import t03 from '@/assets/images/t-03.png';
import t04 from '@/assets/images/t-04.png';
import t05 from '@/assets/images/t-05.png';
import t06 from '@/assets/images/t-06.png';
import typhoonpng from '@/assets/images/typhoon_icon.png';
import '@/styles/typhoon.scss';
import Bus from '@/bus/index';
import { replaceData } from './components/Popup/util';

const TyphoonConstants = {
  TD: '热带低压',
  TS: '热带风暴',
  STS: '强热带风暴',
  TY: '台风',
  STY: '强台风',
  SuperTY: '超强台风',
}

// const typhoonNoAnimationIcon = L.icon({
//   iconUrl: typhoonpng,
//   iconSize: [20, 20],
// });

const typhoonIcon = L.divIcon({
  // className: "typhoonIcon",
  html: `<img src="${typhoonpng}" style="height: 20px;width: 20px" class="typhoonIcon" />`,
  iconSize: [0, 0],
  iconAnchor: [11, 11],
});

// 添加台风编号
const typhoonIconfirst = L.icon({
  iconUrl: typhoonpng,
  iconSize: [1, 1]
});
/* 台风路径绘制函数 */
class TyphoonDisplay {
  constructor(map, id,key, html, play,lineInfo, forecast,name,animation = true) {
    this.map = map;
    this.typhoon = {};
    this.typhoonInfoHtml = html;
    this.play = play;
    this.lineInfo = lineInfo;
    this.animation = animation;
    this.forecast = forecast;
    this.name = name;
    this.showCircle = true;

    // 全部扇形对象
    this.tyPrimitives = [];

    this.myLayerGroupLine = new L.LayerGroup(); // 主点线清除方法
    this.myLayerGroupPoint = new L.LayerGroup(); // 主点清除方法
    this.myLayerGroupMode = new L.LayerGroup(); // 模型标签
    this.myForecastLine = new L.LayerGroup(); // 预测线段
    this.myForecastPoint = new L.LayerGroup(); // 预测线点

    this.timer = null;
    this.self = this;
    this.id = id;
    this.key = key;
    this.currentPointIndex = 0;
  }

  _pathDataHandler = function (typhoonPathData, callback) {
    // 数据转换
    const polylinePoints = []; // 主点坐标
    const radius = {}; // 主点风圈、其它信息
    const pointlayerImages = []; // 主点图片信息

    // 台风信息
    const typhoonName = typhoonPathData.nameCn;
    const typhoonNumber = typhoonPathData.id;
    // 预测信息
    const forecastList = [];
    const forecastInfo = {};

    for (let i = 0; i < typhoonPathData.details.length; i++) {
      const item = typhoonPathData.details[i];

      polylinePoints.push([Number(item.lat), Number(item.lon)]);
      const typhoonStrong = item.grade;
      const typhoonImage = this.typhoonImageRank(typhoonStrong);
      radius[i] = {
        radius7: [item.r34.ne, item.r34.nw, item.r34.se, item.r34.sw],
        radius10: [item.r50.ne, item.r50.nw, item.r50.se, item.r50.sw],
        radius12: [item.r64.ne, item.r64.nw, item.r64.se, item.r64.sw],
        lat: item.lat.toString().split('.').pop().length > 4 ? item.lat.toFixed(4) : item.lat,
        lng: item.lon.toString().split('.').pop().length > 4 ? item.lon.toFixed(4) : item.lon,
        movedirection: item.dir,
        movespeed: item.speed,
        strong: item.grade,
        pressure: parseInt(item.mslp),
        speed: (!item.speed || parseInt(item.speed)) === -1 ? '未发布': parseInt(item.speed) + ' 米/秒',
        vmax: item.vmax ? item.vmax.toFixed(2): 0,
        time: formatTime(item.forecastTimeString),
        forecastTimeString: item.forecastTimeString,
        name: typhoonName+' ['+this.lineInfo.name+']',
        number: typhoonNumber,
      };
      pointlayerImages[i] = {
        icon: L.icon({
          iconUrl: typhoonImage,
          iconSize: [8, 8],
        }),
      };

      forecastInfo[i] = {
        lat: Number(item.lat),
        lng: Number(item.lon),
        time: formatTime(item.forecastTimeString),
        strong: item.grade,
        speed: item.vmax,
        name: typhoonName,
        pressure: item.mslp,
        number: typhoonNumber,
      };
    }

    // forecastList = [polylinePoints[polylinePoints.length - 1]].concat(forecastList);
    // forecastInfo[0] = radius[polylinePoints.length - 1];

    const info = {
      polylinePoints,
      radius,
      pointlayerImages,
      typhoonName,
      typhoonNumber,
      forecastList: polylinePoints,
      forecastInfo,
    };
    callback(info);
  };

  typhoonImageRank = function (typhoonStrong) {
    let typhoon_point_image = '';
    switch (typhoonStrong) {
      case 'TD':
        typhoon_point_image = t01;
        break;
      case 'TS':
        typhoon_point_image = t02;
        break;
      case 'STS':
        typhoon_point_image = t03;
        break;
      case 'TY':
        typhoon_point_image = t04;
        break;
      case 'STY':
        typhoon_point_image = t05;
        break;
      case 'SuperTY':
        typhoon_point_image = t06;
        break;
      default:
        typhoon_point_image = t01;
        break;
    }
    return typhoon_point_image;
  };

  getTyphoonTipHtml = function (info, color) {
    // return `<div style="background: ${color}">${info} &nbsp;&nbsp;<i class="el-icon-close"></i></div>`;
    return `<div style="font-size: 20px">${info}</div>`;
  };

  getTyphoonInfoHtml = function (info) {
    let html = '';
    const radius7 = info.radius7;
    const radius10 = info.radius10;
    const radius12 = info.radius12;
    const StrongDesc = TyphoonConstants[info.strong];
    return replaceData(this.typhoonInfoHtml, { ...info, StrongDesc, radius7, radius10, radius12 });
  };

  // 清除单个风圈
  removeCircle = function () {
    for (let j = 0; j < this.tyPrimitives.length; j++) {
      if (this.tyPrimitives[j]) {
        this.map.removeLayer(this.tyPrimitives[j]);
      }
    }
    this.tyPrimitives = [];
  };



  // 绘制预测点和线
  drawForecastPointLine = function (forecastL, forecastI) {
    const forecastline = L.polyline(forecastL, {
      weight: 2,
      dashArray: '5, 5',
      color: 'white',
      // className: 'forecast',
    });
    this.myForecastLine.addLayer(forecastline);
    this.map.addLayer(this.myForecastLine);
    forecastL.forEach((item, index) => {
      if (index === 0) { return; }
      const strong = forecastI[index].strong;
      const image = this.typhoonImageRank(strong);
      const forecastPointImage = L.icon({
        iconUrl: image,
        iconSize: [10, 10],
      });
      const forecastPoint = L.marker(item, { icon: forecastPointImage })
        .bindPopup(this.getTyphoonInfoHtml(forecastI[index]), {
          offset: [0, -30],
          className: 'forecastInfo',
        }).closePopup();
      this.myForecastPoint.addLayer(forecastPoint);
      this.map.addLayer(this.myForecastPoint);
    });
  };

  animateDrawLine = async function (data) {
    const typhoonAllInfo = data;

    this.allpoints = await typhoonAllInfo.polylinePoints;
    this.polyline = L.polyline(this.allpoints, { color: this.lineInfo.color,
      weight: this.lineInfo.lineWeight,className: this.lineInfo.dasharray ? 'dashLines': '' }).addTo(this.map);
    // this.map.fitBounds(this.polyline.getBounds());
    this.map.removeLayer(this.polyline);
    this.pointlayerImages = typhoonAllInfo.pointlayerImages;
    this.radius = typhoonAllInfo.radius;
    this.typhoonName = typhoonAllInfo.typhoonName;
    this.typhoonNumber = typhoonAllInfo.typhoonNumber;
    this.forecastList = typhoonAllInfo.forecastList;
    this.forecastInfo = typhoonAllInfo.forecastInfo;

    this.myLayerGroupLine.clearLayers();
    this.myLayerGroupPoint.clearLayers();
    this.myLayerGroupMode.clearLayers();

    this.myForecastLine.clearLayers();
    this.myForecastPoint.clearLayers();

    const self = this;
    if (!this.forecast) {
      let labelLayer = L.marker([this.radius[0].lat, this.radius[0].lng], {
        icon: typhoonIconfirst,
        title: '我是谁',
        riseOnHover: true,
        keyboard: true,
      }).bindTooltip(this.getTyphoonTipHtml(this.typhoonName,this.lineInfo.color), {
        direction: 'right',
        offset: [10, 0],
        permanent: true,
        opacity: '1',
        className: 'leaflet-label',
        interactive: true,
      }).openTooltip().on('click', function (e) {
        // if (e.originalEvent.target.className === 'el-icon-close') {
        //   self.deleteTyphoon();
        // }
      });
      this.myLayerGroupPoint.addLayer(labelLayer);
      this.map.addLayer(this.myLayerGroupPoint);
    }

    this.count = 0;
    this.drawPoints = [];
    if (this.animation) {
      this.createTimer(self);
    } else {
      this.createWithNoAnimation(self);
    }

  };

  createWithNoAnimation = function (self) {
    const length = self.allpoints.length;
    for (let index = 0; index <= length - 1; index++) {
      this.drawPoints.push(self.allpoints[index]);
      this.drawLineAndPointWithNoAnimate(self, index);
    }
    if (this.forecast) {
      // 取已绘制点数组中最后一个点，放置台风标志
      const markermode = L.marker(this.drawPoints[length - 1], {
        icon: typhoonIcon,
      });
      markermode.setZIndexOffset(-1);
      self.myLayerGroupMode.addLayer(markermode);
      this.map.addLayer(self.myLayerGroupMode);
    }
    // 最新数据点drawPoints绘制折线
    const lineLayer = L.polyline(this.drawPoints, { color: this.lineInfo.color ,
      weight: this.lineInfo.lineWeight, className: this.lineInfo.dasharray ? 'dashLines': '' });
    self.myLayerGroupLine.addLayer(lineLayer);
    this.map.addLayer(self.myLayerGroupLine);
  };



  createTimer = function (self) {
    let markermode;
    let lineLayer;
    let pointLayer;
    const length = this.allpoints.length;

    // 定时器100ms，动态的塞入坐标数据
    this.timer = setInterval(
      async () => {
        // 循环台风路径中的每个点，设置定时器依次描绘
        if (this.count < length) {
          this.drawPoints.push(self.allpoints[this.count]);
          this.count++;
          // 清除之前绘制的折线图层
          if (lineLayer && this.count !== length) {
            this.map.removeLayer(lineLayer);
            lineLayer = null;
          }
          // 清除之前的marker图层
          if (markermode && this.count !== length) {
            this.map.removeLayer(markermode);
            markermode = null;
          }
          if (self.tyPrimitives.length !== 0) {
            self.removeCircle();
          }
          // 最新数据点drawPoints绘制折线
          lineLayer = L.polyline(this.drawPoints, { color: this.lineInfo.color,
            weight: this.lineInfo.lineWeight,className: this.lineInfo.dasharray ? 'dashLines': ''  });
          self.myLayerGroupLine.addLayer(lineLayer);
          this.map.addLayer(self.myLayerGroupLine);
          // 根据最新的数据组最后一个绘制marker

          if (this.count === length) {
            if (markermode) {
              this.map.removeLayer(markermode);
            }

            // 取已绘制点数组中最后一个点，放置台风标志
            markermode = L.marker(this.drawPoints[this.count - 1], {
              icon: typhoonIcon,
            });
            markermode.setZIndexOffset(-1);
            self.myLayerGroupMode.addLayer(markermode);
            this.map.addLayer(self.myLayerGroupMode);

            this.drawLineAndPoint(pointLayer, self ,true);

          } else {
            this.drawLineAndPoint(pointLayer, self, false);

            // 取已绘制点数组中最后一个点，放置台风标志
            markermode = L.marker(this.drawPoints[this.count - 1], {
              icon: typhoonIcon,
            });
            markermode.setZIndexOffset(-1);
            self.myLayerGroupMode.addLayer(markermode);
            this.map.addLayer(self.myLayerGroupMode);
          }

          // 时间轴同步
          if (self.play) {
            Bus.$emit('pointClick',{time:
              this.radius[this.count -1].forecastTimeString, ispop: false});
          }
        } else {
          // 取完数据后清除定时器
          // this.tyPrimitives = [];
          clearInterval(self.timer);
          // self.clearTempData();
          if (self.play) {
            Bus.$emit('overTyphoon',length);
          }
        }
      }, 600,
    )
  }

  clearTempData = function (){
    this.allpoints = [];
    this.pointlayerImages = [];
    // this.radius = [];
    this.forecastList = [];
    this.forecastInfo = [];
  }

  drawLineAndPointWithNoAnimate(self , pointIndex) {
    const c_radius = self.radius[pointIndex];
    const c_points = self.allpoints[pointIndex];
    const pointLayer = L.marker(c_points, {
      icon: self.pointlayerImages[pointIndex].icon,
      title: '',
      alt: pointIndex,
      riseOnHover: true,
      keyboard: true,
    }).bindPopup(
      self.getTyphoonInfoHtml(c_radius), {
        offset: [0, -10],
        opacity: 0.9,
        className: 'typhoonInfo',

        // autoClose:false
      }
    ).on('click', (e) => {
      self.myLayerGroupPoint.eachLayer((layer) => {
        if (layer.options.alt === e.sourceTarget.options.alt) {
          this.myLayerGroupMode.clearLayers();
          const markermode = L.marker(c_points, {
            icon: typhoonIcon,
          });
          markermode.setZIndexOffset(-1);
          self.myLayerGroupMode.addLayer(markermode);
          this.map.addLayer(self.myLayerGroupMode);
        }
      });
      // if (self.key === 'tcvital' && this.showCircle) {
      //   self.removeAndRowCircle(pointIndex, self);
      // }
      // self.removeAndRowCircle(pointIndex, self);
      Bus.$emit('pointClick',{index: pointIndex, id: self.id, key: self.key,
        time: c_radius.forecastTimeString,ispop: true });
    });
    self.myLayerGroupPoint.addLayer(pointLayer);
    this.map.addLayer(self.myLayerGroupPoint);
  }

  drawLineAndPoint = function (pointLayer, self , isLast) {
    let pointIndex = this.count -1;
    let radiusCount = this.count;
    if (isLast) {
      radiusCount = pointIndex;
    }
    if (this.showCircle) {
      self.drawSingleCircle(this.drawPoints[pointIndex], radiusCount, self.radius, this.map);
    }

    pointLayer = L.marker(this.drawPoints[pointIndex], {
      icon: self.pointlayerImages[pointIndex].icon,
      title: '',
      alt: pointIndex,
      riseOnHover: true,
      keyboard: true,
    }).bindPopup(
      self.getTyphoonInfoHtml(self.radius[pointIndex]), {
        offset: [0, -10],
        opacity: 0.9,
        className: 'typhoonInfo',
        // autoClose:false
      }
    ).on('click', (e) => {
      self.myLayerGroupPoint.eachLayer((layer) => {
        if (layer.options.alt === e.sourceTarget.options.alt) {
          // layer.setIcon(L.icon({
          //   iconUrl: e.sourceTarget.options.icon.options.iconUrl,
          //   iconSize: [12, 12],
          // }));
          // 绘制上风圈
          // 先删除其他的风圈
          this.myLayerGroupMode.clearLayers();
          const markermode = L.marker(this.drawPoints[pointIndex], {
            icon: typhoonIcon,
          });
          markermode.setZIndexOffset(-1);
          self.myLayerGroupMode.addLayer(markermode);
          this.map.addLayer(self.myLayerGroupMode);
        }
        // else {
        //   layer.setIcon(L.icon({
        //     iconUrl: layer.options.icon.options.iconUrl,
        //     iconSize: [8, 8],
        //   }));
        // }
      });
      // if (self.key === 'tcvital' && this.showCircle) {
      //   self.removeAndRowCircle(pointIndex, self);
      // }
      Bus.$emit('pointClick',{index: pointIndex, id: self.id, key: self.key, time:
        this.radius[pointIndex].forecastTimeString,ispop: true });

    });
    self.myLayerGroupPoint.addLayer(pointLayer);
    this.map.addLayer(self.myLayerGroupPoint);
  }

  continueDrawLine = async function (showCircle) {
    this.myLayerGroupMode.clearLayers();
    const self = this;
    this.showCircle = showCircle;
    this.createTimer(self);
  };

  drawSingleCircle = function (latlng, count, r, map) {
    const radius7 = r[count].radius7;
    const radius10 = r[count].radius10;
    const radius12 = r[count].radius12;
    let primitiveFill;
    // 绘制七级风圈
    if (radius7.length > 1) {
      const e = {
        //item.r34.ne, item.r34.nw, item.r34.se, item.r34.sw
        ne: radius7[0],
        nw: radius7[1],
        se: radius7[2],
        sw: radius7[3],
      }
      primitiveFill = this.drawCircle(latlng, e, 'green', map);
      this.tyPrimitives.push(primitiveFill);

      // const radiusNorthEast7 = radius7[0] / 100;
      // const radiusSouthEast7 = radius7[2] / 100;
      // const radiusNorthWast7 = radius7[1] / 100;
      // const radiusSouthWest7 = radius7[3] / 100;
      // primitiveFill = new this.setvisible(latlng, radiusNorthEast7, 'NorthEast', 'green', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusSouthEast7, 'SouthEast', 'green', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusNorthWast7, 'NorthWest', 'green', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusSouthWest7, 'SouthWest', 'green', map);
      // this.tyPrimitives.push(primitiveFill);
    }
    // 绘制十级风圈
    if (radius10.length > 1) {
      const e = {
        //item.r34.ne, item.r34.nw, item.r34.se, item.r34.sw
        ne: radius10[0],
        nw: radius10[1],
        se: radius10[2],
        sw: radius10[3],
      }
      primitiveFill = this.drawCircle(latlng, e, 'pink', map);
      this.tyPrimitives.push(primitiveFill);

      // const radiusNorthEast10 = radius10[0] / 100;
      // const radiusSouthEast10 = radius10[2] / 100;
      // const radiusNorthWast10 = radius10[1] / 100;
      // const radiusSouthWest10 = radius10[3] / 100;
      // primitiveFill = new this.setvisible(latlng, radiusNorthEast10, 'NorthEast', 'pink', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusSouthEast10, 'SouthEast', 'pink', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusNorthWast10, 'NorthWest', 'pink', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusSouthWest10, 'SouthWest', 'pink', map);
      // this.tyPrimitives.push(primitiveFill);
    }
    if (radius12.length > 1) {
      // 绘制十二级风圈
      const e = {
        //item.r34.ne, item.r34.nw, item.r34.se, item.r34.sw
        ne: radius12[0],
        nw: radius12[1],
        se: radius12[2],
        sw: radius12[3],
      }
      primitiveFill = this.drawCircle(latlng, e, 'red', map);
      this.tyPrimitives.push(primitiveFill);


      // const radiusNorthEast12 = radius12[0] / 100;
      // const radiusSouthEast12 = radius12[2] / 100;
      // const radiusNorthWast12 = radius12[1] / 100;
      // const radiusSouthWest12 = radius12[3] / 100;
      // primitiveFill = new this.setvisible(latlng, radiusNorthEast12, 'NorthEast', 'red', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusSouthEast12, 'SouthEast', 'red', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusNorthWast12, 'NorthWest', 'red', map);
      // this.tyPrimitives.push(primitiveFill);
      // primitiveFill = new this.setvisible(latlng, radiusSouthWest12, 'SouthWest', 'red', map);
      // this.tyPrimitives.push(primitiveFill);
    }
  };

  drawCircle = function(latlng, e, color, map) {
    return new L.Typhoon(latlng, e, {
      color,
      fillColor: color,
      fillOpacity: 0.4,
      // opacity: 0.4,
      weight: 2,
      smoothFactor: 0,
    }).addTo(map);
  }

  // 绘制台风风圈方法
  setvisible = function (latlng, semiMinorAxis, anglex, color, map) {
    const anglexdirection = {
      NorthEast: [0, 90],
      SouthEast: [90, 180],
      SouthWest: [180, 270],
      NorthWest: [270, 360],
    };
    const points3 = getPoints(latlng, semiMinorAxis, anglexdirection[anglex][0], anglexdirection[anglex][1], 1000);
    const primitiveFill = new L.polygon(points3, {
      color,
      fillColor: color,
      fillOpacity: 0.4,
      opacity: 0.4,
      weight: 1,
      smoothFactor: 0,
      stroke: false,
    }).addTo(map);
    // const primitiveFill = new L.Typhoon(latlng, points3, {
    //   color,
    //   fillColor: color,
    //   // fillOpacity: 0.4,
    //   // opacity: 0.4,
    //   // weight: 1,
    //   // smoothFactor: 0,
    //   // stroke: false,
    // }).addTo(map);
    return primitiveFill;

    function getPoints(center, radius, startAngle, endAngle, pointNum) {
      let sin;
      let cos;
      let x;
      let y;
      let angle;
      const points = [];
      points.push(center);
      for (let i = 0; i <= pointNum; i++) {
        angle = startAngle + (endAngle - startAngle) * i / pointNum;
        sin = Math.sin(angle * Math.PI / 180);
        cos = Math.cos(angle * Math.PI / 180);
        y = center[0] + radius * cos;
        x = center[1] + radius * sin;
        points[i] = [y, x];
      }
      const point = points;
      point.push(center);
      return point;
    }
  };

  // 外部绘制台风弹窗接口
  drawPopup = async function (latlng, data) {
    const contents = this.self.getTyphoonInfoHtml(data);
    const pop = L.popup({ offset: [0, -10] })
      .setLatLng(latlng)
      .setContent(contents)
      .openOn(this.map);
    return new Promise(((resolve) => {
      resolve(pop);
    }));
  };

  // 外部绘制风圈接口
  drawSingleCircleOut = function (latlng, data) {
    // console.log(r)
    const radius7 = data.radius7;
    const radius10 = data.radius10;
    const radius12 = data.radius12;
    const tyPrimitives = [];
    // 绘制七级风圈
    if (radius7.length > 1) {
      const radiusNorthEast7 = radius7[0] / 100;
      const radiusSouthEast7 = radius7[1] / 100;
      const radiusNorthWast7 = radius7[2] / 100;
      const radiusSouthWest7 = radius7[3] / 100;
      let primitiveFill = new this.setvisible(latlng, radiusNorthEast7, 'NorthEast', 'green');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusSouthEast7, 'SouthEast', 'green');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusNorthWast7, 'SouthWest', 'green');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusSouthWest7, 'NorthWest', 'green');
      tyPrimitives.push(primitiveFill);
    }
    // 绘制十级风圈
    if (radius10.length > 1) {
      const radiusNorthEast10 = radius10[0] / 100;
      const radiusSouthEast10 = radius10[1] / 100;
      const radiusNorthWast10 = radius10[2] / 100;
      const radiusSouthWest10 = radius10[3] / 100;
      primitiveFill = new this.setvisible(latlng, radiusNorthEast10, 'NorthEast', 'pink');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusSouthEast10, 'SouthEast', 'pink');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusNorthWast10, 'SouthWest', 'pink');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusSouthWest10, 'NorthWest', 'pink');
      tyPrimitives.push(primitiveFill);
    }
    if (radius12.length > 1) {
      // 绘制十二级风圈
      const radiusNorthEast12 = radius12[0] / 100;
      const radiusSouthEast12 = radius12[1] / 100;
      const radiusNorthWast12 = radius12[2] / 100;
      const radiusSouthWest12 = radius12[3] / 100;
      primitiveFill = new this.setvisible(latlng, radiusNorthEast12, 'NorthEast', 'red');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusSouthEast12, 'SouthEast', 'red');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusNorthWast12, 'SouthWest', 'red');
      tyPrimitives.push(primitiveFill);
      primitiveFill = new this.setvisible(latlng, radiusSouthWest12, 'NorthWest', 'red');
      tyPrimitives.push(primitiveFill);
    }
    return tyPrimitives;
  };

  // 外部删除风圈接口
  removeCircleOut = function (tyPrimitives) {
    for (let j = 0; j < tyPrimitives.length; j++) {
      this.map.removeLayer(tyPrimitives[j]);
    }
  };

  removeAndRowCircle = function (pointIndex) {
    this.removeCurrentCircle();
    if (pointIndex !== this.drawPoints.length - 1) {
      // 最后一个点风圈要在
      this.tyPrimitives = [];
    }
    // 绘制风圈
    const point = this.drawPoints[pointIndex];
    if (point) {
      if (this.showCircle)
      this.drawSingleCircle(point, pointIndex, this.radius, this.map);
      // 绘制台风图片
      this.myLayerGroupMode.clearLayers();
      const markermode = L.marker(this.drawPoints[pointIndex], {
        icon: typhoonIcon,
      });
      markermode.setZIndexOffset(-1);
      this.myLayerGroupMode.addLayer(markermode);
      this.map.addLayer(this.myLayerGroupMode);
    }
  };

  pointClickCircle = function (pointIndex, showCircle) {
    this.removeCurrentCircle();
    this.tyPrimitives = [];
    // 绘制风圈
    if (!this.drawPoints) {
      return;
    }
    const point = this.drawPoints[pointIndex];
    if (point) {
      // 删除图片，当前没有点位风圈不展示图片
      this.myLayerGroupMode.clearLayers();
      // 绘制台风图片
      const markermode = L.marker(this.drawPoints[pointIndex], {
        icon: typhoonIcon,
      });
      markermode.setZIndexOffset(-1);
      this.myLayerGroupMode.addLayer(markermode);
      this.map.addLayer(this.myLayerGroupMode);

      if (showCircle) {
        this.drawSingleCircle(point, pointIndex, this.radius, this.map);
      }
    }
  };

  removeCurrentCircle = function () {
    for (let j = 0; j < this.tyPrimitives.length; j++) {
       if(this.tyPrimitives[j]) {
         this.map.removeLayer(this.tyPrimitives[j]);
       }
    }
  };

  // 删除所有已绘制的图形
  deleteTyphoon = () => {
    clearInterval(this.timer);
    this.removeCircle();
    if (this.popup) { this.popup.remove(); }
    this.myLayerGroupLine.clearLayers();
    this.myLayerGroupPoint.clearLayers();
    this.myLayerGroupMode.clearLayers();
    this.myForecastLine.clearLayers();
    this.myForecastPoint.clearLayers();
  };

  deleteWindCircle() {
    for (let j = 0; j < this.tyPrimitives.length; j++) {
      if (this.tyPrimitives[j]) {
        this.map.removeLayer(this.tyPrimitives[j]);
      }
    }
    // this.map.removeLayer(this.myLayerGroupMode);
    this.showCircle = false;
  }

  deleteTyphoonIcon() {
    this.myLayerGroupMode.clearLayers();
  }

  forecastDeleteWindCircle() {
    for (let j = 0; j < this.tyPrimitives.length; j++) {
      if (this.tyPrimitives[j]) {
        this.map.removeLayer(this.tyPrimitives[j]);
      }
    }
    this.map.removeLayer(this.myLayerGroupMode);
  }

  showWindCircle(currentPointIndex) {
    this.showCircle = true;
    if (this.tyPrimitives.length > 0) {
      for (let j = 0; j < this.tyPrimitives.length; j++) {
        if (this.tyPrimitives[j]) {
          this.map.addLayer(this.tyPrimitives[j]);
        }
      }
      this.map.addLayer(this.myLayerGroupMode);
    } else {
      // 重新绘制当前风圈点
      this.removeAndRowCircle(currentPointIndex);
    }
  }

  pointClick = (index,showCircle) => {
    this.showCircle = showCircle;
    this.removeAndRowCircle(index);
  };

  // 是否显示预测路径
  forecastIsShow = function (isShow) {
    if (isShow === true) {
      this.self.drawForecastPointLine(this.self.forecastList, this.self.forecastInfo);
    } else {
      this.self.myForecastLine.clearLayers();
      this.self.myForecastPoint.clearLayers();
    }
  };

  // 是否重播
  replay = function (typhoonPathInfo) {
    this.deleteTyphoon();
    this.show(typhoonPathInfo);
  };

  replayForecastLine = function(observeLineIndex,currentPointIndex = -1,animate = true) {
    this.animate = animate;
    this.deleteTyphoon();
    if (!this.forecast) {
      let labelLayer = L.marker([this.radius[0].lat, this.radius[0].lng], {
        icon: typhoonIconfirst,
        title: '我是谁',
        riseOnHover: true,
        keyboard: true,
      }).bindTooltip(this.getTyphoonTipHtml(this.typhoonName,this.lineInfo.color), {
        direction: 'right',
        offset: [10, 0],
        permanent: true,
        opacity: '1',
        className: 'leaflet-label',
        interactive: true,
      }).openTooltip().on('click', function (e) {
        if (e.originalEvent.target.className === 'el-icon-close') {
          self.deleteTyphoon();
        }
      });
      this.myLayerGroupPoint.addLayer(labelLayer);
      this.map.addLayer(this.myLayerGroupPoint);
    }
    this.count = 0;
    this.drawPoints = [];
    const self = this;
    if (animate) {
      this.play = true;
      this.createTimer(self);
    } else {
      this.play = false;
      this.createWithNoAnimation(self);
    }

    if (observeLineIndex !== -1) {
      this.showWindCircle(observeLineIndex);
    } else {
      if (currentPointIndex !== -1) {
        // 设置风圈
        this.showWindCircle(currentPointIndex);
      }
    }
  }

  // 继续播放
  continuePlay = function() {
    this.continueDrawLine();
  };

  pause = function () {
    clearInterval(this.self.timer);
  }

  show = async function (typhoonPathInfo) {
    this.self._pathDataHandler(typhoonPathInfo, async (data) => {
      await this.self.animateDrawLine(data);
    });
  };
}

function formatTime(t) {
  const year = t.slice(0, 4) || '0000';
  const month = t.slice(4, 6) || '00';
  const day = t.slice(6, 8) || '00';
  const hour = t.slice(8, 10) || '00';

  return `${year}年${month}月${day}日 ${hour}时`;
}


(function () {
  L.Typhoon = L.Polygon.extend({
    initialize: function (t, e, i) {
      L.Polygon.prototype.initialize.call(this, e), this._latlng = L.latLng(t), this._circle = e,this._style = i;
    },
    options: {fill: !0},
    projectLatlngs: function () {
      try {
        var e = this._latlng;
        this._point = this._map.latLngToLayerPoint(e);
        var t_northeast = this._getLngRadius(this._getLatRadius(this._circle.ne * 1000)),
          i_northeast = this._map.latLngToLayerPoint([e.lat, e.lng - t_northeast]);
        this._radius_northeast = Math.max(this._point.x - i_northeast.x, 1);
        var t_southeast = this._getLngRadius(this._getLatRadius(this._circle.se * 1000)),
          i_southeast = this._map.latLngToLayerPoint([e.lat, e.lng - t_southeast]);
        this._radius_southeast = Math.max(this._point.x - i_southeast.x, 1);
        var t_southwest = this._getLngRadius(this._getLatRadius(this._circle.sw * 1000)),
          i_southwest = this._map.latLngToLayerPoint([e.lat, e.lng - t_southwest]);
        this._radius_southwest = Math.max(this._point.x - i_southwest.x, 1);
        var t_northwest = this._getLngRadius(this._getLatRadius(this._circle.nw * 1000)),
          i_northwest = this._map.latLngToLayerPoint([e.lat, e.lng - t_northwest]);
        this._radius_northwest = Math.max(this._point.x - i_northwest.x, 1)
      } catch (e) {
        this._radius_northeast = null;
        this._radius_southeast = null;
        this._radius_southwest = null;
        this._radius_northwest = null
      }
    },
    getTyphoonPath: function () {
      if (this._radius_northeast && this._radius_southeast && this._radius_southwest && this._radius_northwest) {
        var t = this._point;
        var e_northeast = this._radius_northeast;
        var path_svg = "M" + t.x + "," + (t.y - e_northeast);
        var path_vml = "M" + t.x + "," + (t.y - e_northeast);
        path_svg += "A" + e_northeast + "," + e_northeast + ",0,0,1," + (t.x + e_northeast) + "," + t.y;
        path_vml += " ae " + t.x + "," + t.y + " " + e_northeast + "," + e_northeast + " " + 65535 * 450 + "," + -5898150;
        var e_southeast = this._radius_southeast;
        path_svg += "L" + (t.x + e_southeast) + "," + t.y;
        path_svg += "A" + e_southeast + "," + e_southeast + ",0,0,1," + t.x + "," + (t.y + e_southeast);
        path_vml += " ae " + t.x + "," + t.y + " " + e_southeast + "," + e_southeast + " " + 65535 * 360 + "," + -5898150;
        var e_southwest = this._radius_southwest;
        path_svg += "L" + t.x + "," + (t.y + e_southwest);
        path_svg += "A" + e_southwest + "," + e_southwest + ",0,0,1," + (t.x - e_southwest) + "," + t.y;
        path_vml += " ae " + t.x + "," + t.y + " " + e_southwest + "," + e_southwest + " " + 65535 * 270 + "," + -5898150;
        var e_northwest = this._radius_northwest;
        path_svg += "L" + (t.x - e_northwest) + "," + t.y;
        path_svg += "A" + e_northwest + "," + e_northwest + ",0,0,1," + t.x + "," + (t.y - e_northwest) + "z";
        path_vml += " ae " + t.x + "," + t.y + " " + e_northwest + "," + e_northwest + " " + 65535 * 180 + "," + -5898150 + "X";
        this.svgPath = L.Browser.svg ? path_svg : path_vml
        return L.Browser.svg ? path_svg : path_vml
      }
      return ""
    },
    beforeAdd: function (map) {
      this._renderer = map.getRenderer(this);
    },
    onAdd: function (map) {
      this.projectLatlngs();
      this.getTyphoonPath();
      this._renderer._initPath(this);
      this._reset();
      this._path.setAttribute('d',this.svgPath);
      this._renderer._addPath(this);
      this._setStyle(this._style);

      map.on({
        moveend:()=>{
          this.projectLatlngs()
          this.getTyphoonPath();
          this._path.setAttribute('d',this.svgPath);
        },
        zoomstart:()=>{
          this._path.setAttribute('d',this.svgPath);
        }
      })
    },

    _setStyle: function (style) {
      L.setOptions(this, style);
      if (this._renderer) {
        this._renderer._updateStyle(this);
      }
      return this;
    },
    _getLatRadius: function (r) {
      return r / 40075017 * 360
    },
    _getLngRadius: function (lr) {
      return lr / Math.cos(Math.PI / 180 * this._latlng.lat)
    }
  });

  L.typhoon = function (t, e, i) {
    return new L.Typhoon(t, e, i)
  }

})();

export default TyphoonDisplay;
